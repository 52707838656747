import React from 'react'
import { IIconProps } from '../../app/types/i-props'

const SubTaskIcon = ({ stroke = '#828282',fill = '#828282' }: IIconProps) => {
    return (
        <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.86682 4.83307L4.86683 4.08307L3.36683 4.08307L3.36682 4.83307L4.86682 4.83307ZM7 11.2774C7.41421 11.2774 7.75 10.9416 7.75 10.5274C7.75 10.1132 7.41421 9.77742 7 9.77742L7 11.2774ZM3.36682 4.83307L3.3668 8.5274L4.8668 8.52741L4.86682 4.83307L3.36682 4.83307ZM6.1168 11.2774L7 11.2774L7 9.77742L6.1168 9.77742L6.1168 11.2774ZM3.3668 8.5274C3.36679 10.0462 4.59801 11.2774 6.1168 11.2774L6.1168 9.77742C5.42644 9.77742 4.8668 9.21777 4.8668 8.52741L3.3668 8.5274Z"
                fill={fill}
            />
            <path
                d="M1.61679 3.33989L7 3.33989"
                stroke={stroke}
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                d="M12.25 4.39958V2.85042C12.25 2.24375 12.0063 2 11.3942 2H9.85042C9.24375 2 9 2.24375 9 2.85042V4.39417C9 5.00625 9.24375 5.25 9.85042 5.25H11.3942C12.0063 5.25 12.25 5.00625 12.25 4.39958Z"
                stroke={stroke}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.25 11.3996V9.85042C12.25 9.24375 12.0063 9 11.3942 9H9.85042C9.24375 9 9 9.24375 9 9.85042V11.3942C9 12.0062 9.24375 12.25 9.85042 12.25H11.3942C12.0063 12.25 12.25 12.0062 12.25 11.3996Z"
                stroke={stroke}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default SubTaskIcon
