import { asyncActions, slice } from './tasks-slice'
import * as tasksSelectors from './selectors'
import * as actions from './actions'
import { asyncTasksCommentsActions } from './tasks-comment-slice'
import { asyncTasksFilesActions } from './tasks-file-slice'
import { asyncSubtasksActions } from './subtasks-slice'

const tasksActions = {
    ...asyncActions,
    ...asyncSubtasksActions,
    ...asyncTasksCommentsActions,
    ...asyncTasksFilesActions,
    ...slice.actions,
    ...actions,
}

const tasksReducer = slice.reducer

export { tasksReducer, tasksSelectors, tasksActions }
