import { Skeleton } from 'antd'
import React from 'react'
import Clipboard from 'react-clipboard.js'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import TasksBoard from '../../features/tasks/components/tasksBoard/TasksBoard'
import { CopyDataIcon } from '../../image_files/icons/CopyDataIcon'
import { selectStatus } from '../../store/app/selectors'
import { getValidText } from '../../utils/helpers/getValidText'
import { showNotify } from '../../utils/helpers/showNotice'
import { EditableSpan } from '../EditableSpan/EditableSpan'
import UiPopover from '../ui/popover/UiPopover'
import UiPopoverContent from '../ui/popover/UiPopoverContent'
import './TaskNameEditBlock.css'

const TaskNameEditBlock = ({
    value,
    className,
    slug,
    projectId,
    onChange,
}: {
    value: string
    slug:string
    projectId:number
    className?: string
    onChange?: (value: string) => void
}) => {
    const { t } = useTranslation()
    const copyLink = window.location.href
    const status = useSelector(selectStatus)

    const handleChangeName = async (name: string) => {
        onChange && onChange(name)
    }

    if(status==='loading'){
        return (
            <Skeleton.Input className='task-name-edit__skeleton' active/>
        )
    }

    return (
        <div className={`task-name-edit-block ${getValidText(className)}`}>
            <div className="task-name-edit-top">
                <UiPopover
                    className="task-name-edit-popover"
                    color="black"
                    content={
                        <UiPopoverContent title={t('copy-link').toString()} />
                    }
                >
                    <Clipboard
                        data-clipboard-text={copyLink}
                        className="taskEditSpace__clipboard"
                        onSuccess={() => {
                            showNotify(t('link-copied-successfully'), { type: 'success' })
                        }}
                    >
                        <CopyDataIcon />
                    </Clipboard>
                </UiPopover>
                {slug && (
                    <TasksBoard slug={slug} projectId={projectId} />
                )}
            </div>
            {value && (
                <EditableSpan
                    handleChange={handleChangeName}
                    value={value}
                />
            )}
        </div>
    )
}

export default TaskNameEditBlock
