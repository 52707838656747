import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ISubTask, ITask } from '../../../../app/types/models/tasks'
import { useAppDispatch } from '../../../../hooks/redux-hooks'
import { AddTextButton } from '../../../../shared/AddTextButton/AddTextButton'
import { TaskDeleteModal } from '../../../../shared/taskDeleteModal/TaskDeleteModal'
import { selectCurrentProject } from '../../../../store/currentProject/selectors'
import { tasksActions } from '../../../../store/tasks'
import { showNotify } from '../../../../utils/helpers/showNotice'
import SubTask from './SubTask'
import './subTasks.css'

export const SubTasks = ({
    className,
    task,
    is_template,
    onChange,
}: {
    className?: string
    is_template?: boolean
    task: ITask
    onChange?: (data: {
        value?: string,
        slug?:string
        type: 'add-subtask' | 'change-subtask-title' | 'delete-subtask'
    }) => void
}) => {
    const dispatch = useAppDispatch()
    const currentProject = useSelector(selectCurrentProject)
    const { t } = useTranslation()

    const addSubTask = async (value: string): Promise<void> => {
        onChange
            ? onChange({ type: 'add-subtask', value })
            : dispatch(
                  tasksActions.addNewSubtask({
                      projectId: currentProject?.id,
                      taskId: task?.id,
                      value,
                      is_template,
                      onSuccess: () => {
                          showNotify(t('task-added-successfully'))
                      },
                  }),
              )
    }

    const changeTitle = (value: string, slug: string) => {
        onChange ? onChange({type:'change-subtask-title',value,slug}) :
        dispatch(
            tasksActions.changeSubtaskTitle({
                parentTaskId: task?.id,
                projectId: currentProject?.id,
                slug,
                value,
            }),
        )
    }

    const handleRemoveSubTask = (slug:string)=>{
        onChange && onChange({type:'delete-subtask',slug}) 
    }

    return (
        <div className={className ? `subTask ${className}` : 'subTask'}>
            <AddTextButton
                addText={addSubTask}
                placeholder={t('add-subtask-name')}
                title={is_template ? t('subtemplate') : t('subtask')}
            />
            <ul className="mt-8 subtasks-list">
                {task?.subtasks && task?.subtasks?.length > 0
                    ? task?.subtasks.map((subtask) => (
                          <SubTask
                              key={subtask?.id}
                              parentTask={task}
                              subtask={subtask as ITask}
                              changeTitle={changeTitle}
                              removeTask={handleRemoveSubTask}
                          />
                      ))
                    : ''}
            </ul>
        </div>
    )
}
