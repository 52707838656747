import React from 'react'

export const StatusIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="2.5"
                y="2.5"
                width="19"
                height="19"
                rx="4.5"
                stroke="#2B506B"
            />
            <path
                d="M7 13L9.6891 16.4574C10.0961 16.9807 10.8903 16.9703 11.2835 16.4367L17.5 8"
                stroke="#2B506B"
                stroke-linecap="round"
            />
        </svg>
    )
}
