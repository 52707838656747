import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import 'simplebar-react/dist/simplebar.min.css'
import { PriorityTypes } from '../../app/types/enums'
import TasksList from '../../entities/tasksList/TasksList'
import { useAppDispatch } from '../../hooks/redux-hooks'
import { CreatePanel } from '../../shared/CreatePanel/CreatePanel'
import { tasksActions } from '../../store/tasks'
import { TaskEditSpace } from './components/taskEditSpace/TaskEditSpace'

import { PlusIcon } from '../../image_files/icons/PlusIcon'
import { statusesServices } from '../../services/statuses-services'
import UiTabs from '../../shared/tabs/Tabs'
import { selectCurrentProject } from '../../store/currentProject/selectors'
import { selectCurrentOrganization } from '../../store/projects/selectors'
import './tasks.css'
import { TasksTabTypes } from './types'
import { getUrlChunks } from '../../utils/urlHelpers'

const Tasks = () => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [currentTab, setCurrentTab] = useState<TasksTabTypes>(
        TasksTabTypes.TASKS,
    )
    const currentProject = useSelector(selectCurrentProject)
    const currentOrganization = useSelector(selectCurrentOrganization)
    const location = useLocation()

    const { setStatuses } = tasksActions

    //хэндлеры
    const handleAddTask = async (
        value: string,
        priorityStatus: PriorityTypes,
    ) => {
        const newTask = {
            name: value.trim(),
            priority: priorityStatus,
            is_template:currentTab===TasksTabTypes.TEMPLATES
        }

        dispatch(
            tasksActions.createTask({
                projectId: currentProject?.id,
                task: newTask,
                onSuccess: () => {
                    // handleChangePagination({ limit: 20, offset: 0 })
                },
            }),
        )
    }

    const tabsItems = [
        {
            label: t('tasks'),
            key: '0',
            children: (
                <TasksList
                    type={currentTab}
                    key={'0'}
                />
            ),
        },
        {
            label: t('templates'),
            key: '1',
            children: (
                <TasksList
                    type={currentTab}
                    key={'1'}
                />
            ),
        },
    ]

    const handleChangeCurrentTab = (tab: string) => {
        setCurrentTab(tab as TasksTabTypes)
        if (tab === TasksTabTypes.TEMPLATES) {
            navigate(
                `/scheduler/${currentOrganization}/${currentProject?.id}/tasks/templates`,
            )
        } else {
            navigate(
                `/scheduler/${currentOrganization}/${currentProject?.id}/tasks/items`,
            )
        }
    }

    useEffect(() => {
        if (currentProject?.id !== undefined) {
            statusesServices
                .getStatuses(currentProject?.id)
                .then((res) => {
                    dispatch(setStatuses({ statuses: res }))
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [currentProject?.id])

    const showEditSpace = () => {
        if (location.state?.slug) {
            return <TaskEditSpace type={currentTab} />
        }
    }

    useLayoutEffect(()=>{
        const urlChunks = getUrlChunks()

        if(urlChunks[4]){
            setCurrentTab( urlChunks[4]==='items' ? TasksTabTypes.TASKS : TasksTabTypes.TEMPLATES)

            if(urlChunks[5]){
                navigate('/'+urlChunks.join('/'),{state:{slug:decodeURI(urlChunks[5])}})
            }
        }
    },[])

    return (
        <div className="scheduler__tasks">
            <div className="pt-10">
                <div className="pos-relative">
                    <CreatePanel
                        addTask={handleAddTask}
                        prioritySelect={true}
                        className="scheduler__create-panel"
                        panelIcon={
                            <div className="font-14-normal row-flex-5">
                                <div className="add-entity-icon-wrap">
                                    <PlusIcon circle={false} />
                                </div>
                            </div>
                        }
                        placeholder={
                            currentTab === TasksTabTypes.TASKS
                                ? t('input-task-name')
                                : t('input-template-name')
                        }
                    />
                    <UiTabs
                        items={tabsItems}
                        activeKey={currentTab}
                        handleChange={handleChangeCurrentTab}
                    />
                </div>
            </div>
            {showEditSpace()}
        </div>
    )
}

export default Tasks
