import React from 'react'
import {
    IDoer,
    IMember,
    ISupervisor,
    IUser,
} from '../../../../app/types/models/users'
import { MembersItem } from '../../../../shared/MembersItem/MembersItem'
import './CreatorWithAssignees.css'
import PopoverInLog from '../../../../shared/popoverInlog/PopoverInLog'
import UiPopover from '../../../../shared/ui/popover/UiPopover'
import UiPopoverContent from '../../../../shared/ui/popover/UiPopoverContent'
interface IProps {
    supervisor: ISupervisor
    assignees: IDoer[]
}

const SupervisorWithAssignees = ({ supervisor, assignees }: IProps) => {
    const makeBlocksLeftShift = (i: number) => {
        if (i > 0) {
            return { transform: `translateX(-${3.5 * i}px)` }
        } else return undefined
    }

    const showItem = (el: IUser) => {
        return (
            <UiPopover
                placement="top"
                trigger="hover"
                content={<UiPopoverContent title={el?.email} />}
                children={
                    <div>
                        <MembersItem
                            member={{ user: el } as IMember}
                            isHideText={true}
                        />
                    </div>
                }
            />
        )
    }

    const showAssignees = () => {
        if (assignees.length <= 3) {
            return assignees.map((el, i) => (
                <div key={el?.id} style={makeBlocksLeftShift(i + 1)}>
                    {showItem(el?.user)}
                </div>
            ))
        } else {
            return assignees.slice(0, 2).map((el, i) => (
                <div key={el?.id} style={makeBlocksLeftShift(i + 1)}>
                    {showItem(el?.user)}
                </div>
            ))
        }
    }

    const showRestAssigneesCount = () => {
        if (assignees?.length > 3) {
            const restItems = assignees.slice(2, assignees.length)
            return (
                <UiPopover
                    placement="top"
                    trigger="hover"
                    content={
                        <UiPopoverContent
                            title={
                                <>
                                    {restItems.map((el, i) => (
                                        <div key={i}>{el?.user?.email}</div>
                                    ))}
                                </>
                            }
                        />
                    }
                    children={
                        <div
                            className="creator-with-assignees-rest-indicator"
                            style={makeBlocksLeftShift(3)}
                        >
                            <span>{`+${restItems.length}`}</span>
                        </div>
                    }
                />
            )
        } else return undefined
    }

    return (
        <div className="creator-with-assignees-wrapper">
            {supervisor && showItem(supervisor?.user)}
            {assignees && assignees?.length > 0 && (
                <div className="creator-with-assignees-list">
                    {showAssignees()}
                    {showRestAssigneesCount()}
                </div>
            )}
        </div>
    )
}

export default SupervisorWithAssignees
