import { getWeeksList } from "./services";

const monthList = [
    'jan',
    'feb',
    'march',
    'apr',
    'may',
    'june',
    'jule',
    'aug',
    'sept',
    'oct',
    'nov',
    'dec',
]


export function getFullMonths(startDate, endDate) {

    const start = new Date(startDate);
    const startDay = start.getDate()   
    const end = new Date(endDate);

    // Проверяем, что начальная дата меньше или равна конечной
    if (start > end) {
        return [];
    }

    const result = [];
    let currentYear = start.getFullYear();
    let currentMonth = start.getMonth();

    const currentMonthWeeks = getWeeksList(currentYear, currentMonth)
    if(currentMonthWeeks[0].start>startDay){
       
    
        currentMonth =currentMonth>0 ? currentMonth-1 : 0
    }

    console.log('currentMonthWeeks', currentMonthWeeks)
    while (
        currentYear < end.getFullYear() || // Пока год меньше конечного
        (currentYear === end.getFullYear() && currentMonth <= end.getMonth()) // Либо год совпадает, но месяц ещё в пределах диапазона
    ) {
        const monthName = monthList[currentMonth];

        result.push({
            label: monthName,
            year: currentYear,
            month: currentMonth
        });

        currentMonth++;
        if (currentMonth > 11) { // Если месяц больше 11 (декабрь)
            currentMonth = 0; // Переходим к январю
            currentYear++; // Увеличиваем год
        }
    }

    return result;
}
