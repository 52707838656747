import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { ResetDataIcon } from '../../../../image_files/icons/ResetDataIcon'
import { tasksSelectors } from '../../../../store/tasks'
import { initialFilterData } from '../../data'
import { RoadmapContext } from '../../providers'
import RoadMapFilter from '../roadMapFilter/RoadMapFilter'
import RoadmapDetalizationFilter from '../roadmapDetalizationFilter/RoadmapDetalizationFIlter'

const RoadMapTop = () => {
    const tasksData = useSelector(tasksSelectors.selectTaskData)
    const { filterParams, setFilterParams } = useContext(RoadmapContext)

    const getIsDisabledFilterFieldsStatus = () => {
        if (
            Object.keys(filterParams).length === 1 &&
            filterParams.ordering !== undefined
        ) {
            return tasksData.results.length === 0
        } else {
            return false
        }
    }

    return (
        <div className="roadMap-top">
            <div className="roadMap-top-item">
                <div className="roadMap-top-icons">
                    <ResetDataIcon
                        className="cursor-pointer"
                        onClick={() => setFilterParams(initialFilterData)}
                    />
                </div>
                <RoadMapFilter isDisabled={getIsDisabledFilterFieldsStatus()} />
            </div>
            <RoadmapDetalizationFilter />
        </div>
    )
}

export default RoadMapTop
