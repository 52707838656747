import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RoadMapDetalizationTypes } from '../../app/types/enums'
import {
    CalendarGroupEntityTypes,
    CalendarItemEntityTypes,
    ICalendarGroup,
    ICalendarItem,
} from '../../app/types/i-roadmap-calendar'
import { getValidText } from '../../utils/helpers/getValidText'
import './CalendarDetalizationRow.css'
import {
    getMonthDetalization,
    getQuarterDetalization,
    getYearDetalization,
} from './services/services'

interface IInlineStyle {
    [key: string]: string | number
}

export const CalendarDetalizationRow = ({
    type,
    data,
    height,
    position=0,
    className,
    detalizationData,
}: {
    height?: number
    data?: ICalendarGroup[]
    className?: string
    type: 'header' | 'item'
    position?:number;
    detalizationData: {
        detalization: RoadMapDetalizationTypes
        startPeriod: Date | string
        endPeriod: Date | string
    }
}) => {
    const { t } = useTranslation()
    const [items, setItems] = useState<ICalendarGroup[]>([])

    const getPeriodPercentageStyles = (item: ICalendarItem) => {
        const data: IInlineStyle = {}

        if (type === 'item' && item?.value?.interval) {
            const startValue =
                item?.value.interval[0] < 4 ? 0 : item?.value.interval[0]
            data.position = 'absolute'
            data.left = `${startValue}%`
            data.top = '30%'
            data.bottom = '30%'
            data.right = `${100 - item?.value.interval[1]}%`
            data.backgroundColor = item?.value?.priorityColor
        }
        return data
    }

    const showGroupLabel = (group: ICalendarGroup, index: number) => {
        if (group.type === CalendarGroupEntityTypes.MONTH) {
            return `${t(group.label)} ${group?.year !== 0 ? group?.year : ''}`
        } else if (group.type === CalendarGroupEntityTypes.QUARTER) {
            return `${group.index} ${t(group.label)} ${group?.year}`
        } else return group.label
    }

    const showItemLabel = (item: ICalendarItem) => {
        if (type === 'header') {
            return item.type === CalendarItemEntityTypes.MONTH
                ? t(item.label)
                : item.label
        } else return ''
    }

    useEffect(() => {
        let list: ICalendarGroup[] = []
        if (data && data.length > 0) {
            list = data
        } else {
            switch (detalizationData.detalization) {
                case RoadMapDetalizationTypes.MONTH:
                    list = getMonthDetalization({
                        startPeriod: detalizationData.startPeriod,
                        endPeriod: detalizationData.endPeriod,
                    })
                    break
                case RoadMapDetalizationTypes.QUARTER:
                    list = getQuarterDetalization({
                        startPeriod: detalizationData.startPeriod,
                        endPeriod: detalizationData.endPeriod,
                    })
                    break
                case RoadMapDetalizationTypes.YEAR:
                    list = getYearDetalization({
                        startPeriod: detalizationData.startPeriod,
                        endPeriod: detalizationData.endPeriod,
                    })
                    break
                default:
                    break
            }
        }
        setItems(list)
    }, [data, detalizationData])

    // console.log('items', items)

    const renderGrid = () => {
        return items.map((group, i) => (
            <div
                key={i}
                className="roadmap-calendar-group"
                style={{ position: 'relative' }}
            >
                {type === 'header' && (
                    <div className="font-12-normal roadmap-calendar-group-label">
                        {showGroupLabel(group, i)}
                    </div>
                )}
                {group?.nowDate && type === 'header' && (
                    <div
                        className="roadmap-calendar-item-nowDate-line-header"
                        style={{ left: `${group?.nowDate}%` }} 
                    />
                )}
                {group?.nowDate && type !== 'header' && (
                    <div
                        className="roadmap-calendar-item-nowDate-line"
                        style={{ left: `${group?.nowDate}%` }}
                    />
                )}

                <div className="roadmap-calendar-group-items">
                    {group.list && group?.list?.length > 0
                        ? group.list.map((item, itemI) => {
                              return (
                                  <div
                                      key={itemI}
                                      className={`roadmap-calendar-item ${
                                          type === 'item'
                                              ? 'roadmap-calendar-hovering-item'
                                              : ''
                                      }`}
                                  >
                                      <div
                                          className="font-12-normal roadmap-calendar-item-label"
                                          style={getPeriodPercentageStyles(
                                              item,
                                          )}
                                      >
                                          {showItemLabel(item)}
                                      </div>
                                      {item?.value?.nowDate && (
                                          <div
                                              className={`roadmap-calendar-item-nowday ${position===0 ? 'roadmap-calendar-item-nowday-first' : ''}`}
                                              style={{
                                                  left: `${item?.value?.nowDate}%`,
                                              }}
                                          />
                                      )}
                                  </div>
                              )
                          })
                        : []}
                </div>
            </div>
        ))
    }

    return (
        <div
            className={`roadmap-calendar-groups ${getValidText(className)}`}
            style={{ height }}
        >
            {renderGrid()}
        </div>
    )
}
