import React, { FC, useState } from 'react'
import './loggingPanelItem.css'
import { PlusIcon } from '../../../../../../../../image_files/icons/PlusIcon'
import { ILoggingPanelProps, LoggingPanelTypes } from './types'
import { UploadIcon } from '../../../../../../../../image_files/icons/UploadIcon'
import UploadButton from '../../../../../../../../shared/uploadButton/UploadButton'
import { IUploadFile } from '../../../../../../../../shared/uploadButton/types'
import { useTranslation } from 'react-i18next'
import PopoverInLog from '../../../../../../../../shared/popoverInlog/PopoverInLog'
import { useSelector } from 'react-redux'
import { currentProjectSelectors } from '../../../../../../../../store/currentProject'
import UiPopover from '../../../../../../../../shared/ui/popover/UiPopover'
import UiPopoverContent from '../../../../../../../../shared/ui/popover/UiPopoverContent'

const LoggingPanelItem: FC<ILoggingPanelProps> = ({
    title,
    plusIcon,
    openValue,
    tabActiveKey,
    tabKey,
    type,
    toOpenInput,
    setTabActiveKey,
    onChange,
}) => {
    const [uploadResetTrigger, setUploadResetTrigger] = useState(1)
    const { t } = useTranslation()
    const currentPad = useSelector(currentProjectSelectors.selectCurrentPad)
    const currentWell = useSelector(currentProjectSelectors.selectCurrentWell)
    const currentWellBore = useSelector(
        currentProjectSelectors.selectCurrentWellBore,
    )
    const elementHandler = () => {
        setTabActiveKey && setTabActiveKey(tabKey)
    }

    const openInputHandler = () =>
        toOpenInput ? toOpenInput(!openValue) : () => {}

    const onChangeUploadFile = (data: IUploadFile) => {
        setUploadResetTrigger((prev) => prev + 1)
        onChange && onChange(data, type)
    }

    const showPopoverTextByType = () => {
        switch (type) {
            case LoggingPanelTypes.PAD:
                return t(`upload-pads-names-in-correct-format`).toString()
            case LoggingPanelTypes.WELL:
                return t(
                    `upload-wells-or-cores-names-in-correct-format`,
                ).toString()
            case LoggingPanelTypes.CORE:
                return t(`upload-cores-names-in-correct-format`).toString()
            default:
                return ''
        }
    }

    const showUploadButton = () => {
        if (
            [
                LoggingPanelTypes.PAD,
                LoggingPanelTypes.CORE,
                LoggingPanelTypes.WELL,
            ].includes(type)
        ) {
            if (type === LoggingPanelTypes.WELL) {
                return !!currentPad
            }
            if (type === LoggingPanelTypes.CORE) {
                return !!(currentWell || currentWellBore)
            }
            return true
        } else return false
    }
    return (
        <div
            onClick={elementHandler}
            className={
                tabKey && tabActiveKey && tabKey === tabActiveKey
                    ? 'projects__elements-topItem activeTabNav'
                    : 'projects__elements-topItem'
            }
            style={tabKey ? { cursor: 'pointer' } : {}}
        >
            <h4>{title}</h4>

            {plusIcon && (
                <PlusIcon
                    onClick={openInputHandler}
                    className={'cursorPointer'}
                />
            )}
            {showUploadButton() && (
                <UiPopover
                    color="black"
                    trigger={'hover'}
                    content={
                        <UiPopoverContent title={showPopoverTextByType()} />
                    }
                >
                    <div>
                        <UploadButton
                            className={'projects__elements-topItem--upload-btn'}
                            accept={['xlsx']}
                            onChange={onChangeUploadFile}
                            resetTrigger={uploadResetTrigger}
                        >
                            <UploadIcon />
                        </UploadButton>
                    </div>
                </UiPopover>
            )}
        </div>
    )
}

export { LoggingPanelItem }
