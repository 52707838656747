import React from 'react'

export const PriorityIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M4 4H12.5H21" stroke="#2B506B" stroke-linecap="round" />
            <path d="M4 9.5H10.5H17" stroke="#2B506B" stroke-linecap="round" />
            <path d="M4 14H8H12" stroke="#2B506B" stroke-linecap="round" />
            <path d="M4 19H6.5H9" stroke="#2B506B" stroke-linecap="round" />
        </svg>
    )
}
