import React, { useEffect } from 'react'
import {Route, Routes} from 'react-router-dom'
import {routes} from '../../../utils/constants'
import FirstProject from '../../dataLogging/components/firstProject/FirstProject'
import SettingsPage from "../../settings";
import {DataLogging} from "../../dataLogging/DataLogging";
import SchedulerPage from "../../scheduler-page";
import {WellMode} from "../../../entities/wellMode/WellMode";
import { useAppDispatch } from '../../../hooks/redux-hooks';
import { organizationActions } from '../../../store/organization';

const HomeRoutes = () => {
    return (
        <Routes>
            <Route path={routes.GRAPHICS} element={<WellMode />} />
            <Route path={routes.LOGGING} element={<DataLogging />} />
            <Route path={routes.FIRST_PROJECT} element={<FirstProject />} />
            <Route
                path={routes.SCHEDULER}
                element={
                    <SchedulerPage />
                }
            />
            <Route path={routes.SETTINGS} element={<SettingsPage />} />

        </Routes>
    )
}

export default HomeRoutes
