import { asyncActions, slice } from './organization-slice'
import * as organizationSelectors from './selectors'

const organizationActions = {

    ...asyncActions,
    ...slice.actions,
    // ...actions,
}

const organizationReducer = slice.reducer

export { organizationReducer, organizationActions,organizationSelectors }
