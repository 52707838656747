import React, { FC, ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PriorityTypes } from '../../app/types/enums'
import { priorityOptions } from '../../utils/priority-options'
import './prioritySelect.css'
import UISelect from '../ui/select/UlSelect'

interface ISelectOptionProps {
    label: string
    value: PriorityTypes
    isHideOptionText?: boolean
}

interface IPrioritySelectProps {
    style?: object
    label?:string | ReactNode
    disabled?:boolean
    allowClear?: boolean
    isHideOptionText?: boolean
    currentPriority?: PriorityTypes

    onChange: (value: string) => void
}

export const PrioritySelect: FC<IPrioritySelectProps> = React.forwardRef(
    (props) => {
        const { allowClear, style,disabled,label, currentPriority, isHideOptionText,onChange } = props
        const { t } = useTranslation()
        const [currentValue, setCurrentValue] =
            useState<PriorityTypes>(currentPriority)

        const showOptions = () => {
            return priorityOptions.map((value, i) => ({
                value,
                label: (
                    <SelectOption
                        key={i}
                        label={t(
                            `${value}`,
                        )}
                        isHideOptionText={isHideOptionText}
                        value={value}
                    />
                ),
                title:''
            }))
        }

        const handleChangePriority = (value: PriorityTypes) => {
            setCurrentValue(value)
            onChange(value)
        }

        useEffect(() => {
            setCurrentValue(currentPriority)
        }, [currentPriority])

        return (
            <div className='priority-select__wrapper'>
                <UISelect
                    label={label}
                    value={currentValue}
                    allowClear={allowClear}
                    options={showOptions()}
                    disabled={disabled}
                    onChange={handleChangePriority}
                />
            </div>
        )
    },
)

const SelectOption: FC<ISelectOptionProps> = ({
    label,
    value,
    isHideOptionText,
}) => {
    return (
        <div className="prioritySelect__option">
            <div
                className={`prioritySelect__option-priority prioritySelect__option-priority-${value}`}
            />
            {!isHideOptionText && (
                <span className="prioritySelect__option-span">{label}</span>
            )}
        </div>
    )
}
