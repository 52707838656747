import {ACCESS_TOKEN, HTTP_BASE_URL_API} from "../utils/constants";
import axios from "axios";

export const instance = axios.create({
    withCredentials: true, /// чтобы при каждом запросе кука цеплялась автоматически
    baseURL: HTTP_BASE_URL_API,
    headers: {
        Authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN)
                ? JSON.parse(localStorage.getItem(ACCESS_TOKEN))
                : null
        }`,
    },
})

instance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem(ACCESS_TOKEN)
            ? JSON.parse(localStorage.getItem(ACCESS_TOKEN) as string)
            : null;

        if (token) {
            // Если токен существует, добавляем его в заголовок Authorization
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
