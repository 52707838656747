import { AxiosResponse } from 'axios'
import { instance } from '../app/api'
import { ITask, ITasksFilterParams } from '../app/types/models/tasks'
import { PriorityTypes } from '../app/types/enums'
import {
    IAddTemplateDTO,
    IUpdateTemplateDTO,
} from '../app/types/dto/templates.dto'

export const templatesService = {
    getTemplates: async (id: number, params?: ITasksFilterParams) => {
        return await instance
            .get<
                any,
                AxiosResponse<{
                    count: number
                    next: string
                    previous: string
                    results: any[]
                }>
            >(`projects/${id}/tasks/template/`, {
                params,
            })
            .then((res) => res.data)
    },
    getTemplate: async (projectId: number, taskId:number) => {
        return await instance
            .get<
                undefined,
                AxiosResponse<ITask>
            >(`projects/${projectId}/tasks/template/${taskId}/`)
            .then((res) => res.data)
    },
    createTemplate: async (body: IAddTemplateDTO, id: number) => {
        return await instance
            .post<any, AxiosResponse<ITask>>(
                `projects/${id}/tasks/template/`,
                body,
            )
            .then((res) => res.data)
    },
    updateTemplate: async (
        body: IUpdateTemplateDTO,
        projectId: number,
        taskId: number,
    ) => {
        return await instance
            .patch<any, AxiosResponse<ITask>>(
                `projects/${projectId}/tasks/template/${taskId}/`,
                body,
            )
            .then((res) => res.data)
    },
    deleteTemplate: async (projectId: number, taskId: number) => {
        return await instance
            .delete<any, AxiosResponse<ITask>>(
                `projects/${projectId}/tasks/template/${taskId}/`,
            )
    },
}
