import React from "react";

const ReplaceIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.5 9C11.5 9.27614 11.7239 9.5 12 9.5C12.2761 9.5 12.5 9.27614 12.5 9L11.5 9ZM12.3536 1.64645C12.1583 1.45118 11.8417 1.45118 11.6464 1.64645L8.46447 4.82843C8.2692 5.02369 8.2692 5.34027 8.46447 5.53553C8.65973 5.7308 8.97631 5.7308 9.17157 5.53553L12 2.70711L14.8284 5.53553C15.0237 5.7308 15.3403 5.7308 15.5355 5.53553C15.7308 5.34027 15.7308 5.02369 15.5355 4.82843L12.3536 1.64645ZM12.5 9L12.5 2L11.5 2L11.5 9L12.5 9Z"
                fill="#2B506B"
            />
            <path
                d="M12.5 8C12.5 7.72386 12.2761 7.5 12 7.5C11.7239 7.5 11.5 7.72386 11.5 8L12.5 8ZM11.6464 22.3536C11.8417 22.5488 12.1583 22.5488 12.3536 22.3536L15.5355 19.1716C15.7308 18.9763 15.7308 18.6597 15.5355 18.4645C15.3403 18.2692 15.0237 18.2692 14.8284 18.4645L12 21.2929L9.17157 18.4645C8.97631 18.2692 8.65973 18.2692 8.46447 18.4645C8.2692 18.6597 8.2692 18.9763 8.46447 19.1716L11.6464 22.3536ZM11.5 8L11.5 22L12.5 22L12.5 8L11.5 8Z"
                fill="#2B506B"
            />
            <path
                d="M9 12.5C9.27614 12.5 9.5 12.2761 9.5 12C9.5 11.7239 9.27614 11.5 9 11.5V12.5ZM1.64645 11.6464C1.45118 11.8417 1.45118 12.1583 1.64645 12.3536L4.82843 15.5355C5.02369 15.7308 5.34027 15.7308 5.53553 15.5355C5.7308 15.3403 5.7308 15.0237 5.53553 14.8284L2.70711 12L5.53553 9.17157C5.7308 8.97631 5.7308 8.65973 5.53553 8.46447C5.34027 8.2692 5.02369 8.2692 4.82843 8.46447L1.64645 11.6464ZM9 11.5L2 11.5L2 12.5L9 12.5V11.5Z"
                fill="#2B506B"
            />
            <path
                d="M8 11.5C7.72386 11.5 7.5 11.7239 7.5 12C7.5 12.2761 7.72386 12.5 8 12.5L8 11.5ZM22.3536 12.3536C22.5488 12.1583 22.5488 11.8417 22.3536 11.6464L19.1716 8.46447C18.9763 8.2692 18.6597 8.2692 18.4645 8.46447C18.2692 8.65973 18.2692 8.97631 18.4645 9.17157L21.2929 12L18.4645 14.8284C18.2692 15.0237 18.2692 15.3403 18.4645 15.5355C18.6597 15.7308 18.9763 15.7308 19.1716 15.5355L22.3536 12.3536ZM8 12.5L22 12.5L22 11.5L8 11.5L8 12.5Z"
                fill="#2B506B"
            />
        </svg>
    )
}
export default ReplaceIcon;
