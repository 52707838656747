import React from "react"
import { IIconProps } from "../../app/types/i-props"
import { getValidText } from "../../utils/helpers/getValidText"

const PanelRoadmapIcon = ({className}:IIconProps) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            className={getValidText(className)}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18 13H4C3.44772 13 3 13.4477 3 14V20.5C3 21.0523 3.44772 21.5 4 21.5H17.9648C18.2992 21.5 18.6114 21.3329 18.7969 21.0547L21.1075 17.5887C21.3427 17.2359 21.3299 16.7732 21.0755 16.434L18.8 13.4C18.6111 13.1482 18.3148 13 18 13Z"
                stroke="#2B506B"
            />
            <path
                d="M10.5 13L13.4566 16.379C13.7721 16.7395 13.7875 17.2732 13.4934 17.6514L10.5 21.5"
                stroke="#2B506B"
                strokeLinecap="round"
            />
            <circle cx="6.5" cy="7.5" r="2" stroke="#2B506B" />
            <line x1="6.5" y1="9" x2="6.5" y2="13" stroke="#2B506B" />
            <path
                d="M14.5 13V3.2C14.5 3.08954 14.5895 3 14.7 3H20.5172C20.6953 3 20.7846 3.21543 20.6586 3.34142L19.1266 4.87342C19.0545 4.94548 19.0482 5.0602 19.1118 5.13978L20.74 7.17506C20.8448 7.30601 20.7516 7.5 20.5839 7.5H14.5"
                stroke="#2B506B"
            />
        </svg>
    )
}

export default PanelRoadmapIcon;
