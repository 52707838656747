import { Skeleton } from 'antd'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CalendarDetalizationRow } from '../../../../shared/calendarDetalizationRow/CalendarDetalizationRow'
import { CreatePanel } from '../../../../shared/CreatePanel/CreatePanel'
import UiButton from '../../../../shared/ui/button/UiButton'
import { selectTaskData } from '../../../../store/tasks/selectors'
import { RoadmapContext } from '../../providers'
import RoadMapCalendarItem from '../roadMapCalendarItem/RoadMapCalendarItem'
import RoadMapCalendarTop from '../roadMapCalendarTop/RoadMapCalendarTop'
import RoadMapItems from '../roadMapItems/RoadMapItems'
import './RoadMapTasks.css'

const RoadMapTasks = ({
    isLoading,
    isCollapsed,
}: {
    isCollapsed: boolean
    isLoading: boolean
}) => {
    const { t } = useTranslation()
    const block1Ref = useRef(null)
    const block2Ref = useRef(null)
    const block3Ref = useRef(null)
    const [isSyncing, setIsSyncing] = useState(false)
    const [ishasVerticalScroll, setIsHasVerticalScroll] = useState(false)
    const { results: tasks, count } = useSelector(selectTaskData)
    const [tasksHeightList, setTasksHeightList] = useState<
        { id: number; height: number }[]
    >([])
    const { filterParams, detalizationData, createTask, setFilterParams } =
        useContext(RoadmapContext)

    const handleScroll1 = () => {
        if (!isSyncing) {
            setIsSyncing(true)
            block2Ref.current.scrollLeft = block1Ref.current.scrollLeft
            setIsSyncing(false)
        }
    }

    const handleScroll2 = () => {
        if (!isSyncing) {
            setIsSyncing(true)

            block1Ref.current.scrollLeft = block2Ref.current.scrollLeft
            setIsSyncing(false)
        }
    }

    const showCalendarItems = () => {
        return tasks?.map((el, i) => (
            <RoadMapCalendarItem
                item={el}
                position={i}
                blockHeight={
                    tasksHeightList.find((val) => val?.id === el?.id)?.height
                }
                detalizationData={detalizationData}
            />
        ))
    }


    const checkScrollbars = () =>{
        if(block3Ref?.current){
            setIsHasVerticalScroll(block3Ref?.current.scrollHeight > block3Ref?.current.clientHeight)
        }
    }

    useEffect(() => {
        checkScrollbars(); // Проверка сразу после рендеринга
        window.addEventListener('resize', checkScrollbars); // Повторная проверка при изменении размера окна
    
        return () => {
          window.removeEventListener('resize', checkScrollbars); // Очистка обработчика
        };
      }, []);

    useEffect(() => {
        if (tasks) {
            setTasksHeightList(
                tasks.map((el) => ({ id: el?.id, height: undefined })),
            )
        }
    }, [])

    return (
        <>
            <div className="roadMap-workSpace" >
                {isLoading ? (
                    <div className="roadMap-items-skeleton">
                        <Skeleton.Input active />
                        <Skeleton.Input active />
                        <Skeleton.Input active />
                        <Skeleton.Input active />
                        <Skeleton.Input active />
                        <Skeleton.Input active />
                        <Skeleton.Input active />
                        <Skeleton.Input active />
                        <Skeleton.Input active />
                        <Skeleton.Input active />
                        <Skeleton.Input active />
                    </div>
                ) : count > 0 ? (
                    <>
                        <div className="roadMap-workSpace-top">
                            <div className="roadMap-workSpace-column">
                                <RoadMapCalendarTop
                                    setFilterParams={setFilterParams}
                                />
                            </div>
                            <div
                                className="roadMap-workSpace-column"
                                ref={block1Ref}
                                onScroll={handleScroll1}
                                style={{
                                    marginRight:ishasVerticalScroll ? '8px' : 0
                                }}
                            >
                                <div className="roadMap-workSpace-column-top">
                                    <CalendarDetalizationRow
                                        type="header"
                                        detalizationData={detalizationData}
                                        className="roadMap-calendar-groups"
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                        
                            className={`roadMap-workSpace-bottom ${
                                !isCollapsed
                                    ? 'roadMap-workSpace-bottom-collapsed'
                                    : ''
                            }`}
                        >
                            <div className="roadMap-workSpace-bottom-wrapper"  ref={block3Ref}>
                                <div className="roadMap-workSpace-column roadMap-column-tasks">
                                    <div className="roadMap-tasks">
                                        <RoadMapItems
                                            items={tasks}
                                            saveHeightList={setTasksHeightList}
                                        />
                                    </div>
                                </div>
                                <div className="roadMap-workSpace-column roadMap-column-calendar-items">
                                    <ul
                                        className="calendar-items"
                                        ref={block2Ref}
                                        onScroll={handleScroll2}
                                    >
                                        {showCalendarItems()}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="font-14-normal pt-10">
                            {Object.keys(filterParams).length === 1
                                ? t('tasks-absent-message')
                                : t('filtered-tasks-absent-message')}
                        </div>
                        <div className="font-14-normal pt-5">
                            <CreatePanel
                                placeholder={t('input-task-name')}
                                prioritySelect={true}
                                panelIcon={
                                    <div className="">
                                        <UiButton type="primary">
                                            + {t('task')}
                                        </UiButton>
                                    </div>
                                }
                                addTask={createTask}
                            />
                        </div>
                    </>
                )}
            </div>
        </>
    )
}

export default RoadMapTasks
