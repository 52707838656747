import { asyncActions, slice } from './panels-slice'
import * as panelsSelectors from './selectors'
import * as actions from './actions'

const panelsActions = {
    ...asyncActions,
    ...slice.actions,
    ...actions,
}

const panelsReducer = slice.reducer

export { panelsActions, panelsReducer, panelsSelectors }
