import React, { FC, useEffect, useLayoutEffect, useState } from 'react'
import { ArrowLeftIcon } from '../../image_files/icons/ArrowLeftIcon'
// @ts-ignore
import s from './pagination.module.css'

const defaultPaginationData = {
    page: 1,
    pageCount: 20,
    portionSize: 5,
}

interface IProps {
    count: number
    limit: number
    isLoading?:boolean
    goToFirstPageTrigger?: number

    onChange: (data: { limit: number; offset: number }) => void
}

export const Pagination: FC<IProps> = ({
    count,
    limit,
    isLoading,
    goToFirstPageTrigger,
    onChange,
}) => {
    const { page, portionSize } = defaultPaginationData

    const [pagesCount, setPagesCount] = useState(0)
    const [pages, setPages] = useState([1])
    const [currentPage, setCurrentPage] = useState(page)
    let portionCount = Math.ceil(pagesCount / portionSize)
    let [portionNumber, setPortionNumber] = useState(1)
    let leftPortionPageNumber = (portionNumber - 1) * portionSize + 1
    let rightPortionPageNumber = portionNumber * portionSize

    const changePageHandler = (pageNumber: number) => {
        const params = {
            limit,
            offset: limit * (pageNumber - 1),
        }

        onChange(params)
        setCurrentPage(pageNumber)
    }

    const handleLeftArrow = () => {
        if (portionNumber > 1) {
            setPortionNumber(portionNumber - 1)
            changePageHandler(currentPage - 1)
        }
        changePageHandler(currentPage - 1)
    }

    const showPageNumbers = () => {
        return pages
            .filter(
                (p) =>
                    p >= leftPortionPageNumber && p <= rightPortionPageNumber,
            )
            .map((p, i) => {
                return (
                    <div
                        className={`${s.pageNumber} ${
                            currentPage === p ? s.selectedPage : ''
                        } ${pagesCount === 1 ? 'cursor-not-allowed' : ''}`}
                        key={i}
                        onClick={() => {
                            pagesCount > 1 &&
                                currentPage !== p &&
                                changePageHandler(p)
                        }}
                    >
                        {' '}
                        {p}
                    </div>
                )
            })
    }

    const handleRightArrow = () => {
        if (portionNumber < portionCount) {
            setPortionNumber(portionNumber + 1)
        }
        changePageHandler(currentPage + 1)
    }

    useEffect(() => {
        if (goToFirstPageTrigger && goToFirstPageTrigger > 0) {
            setCurrentPage(1)
        }
    }, [goToFirstPageTrigger])

    useEffect(() => {
        if (pagesCount !== undefined) {
            setPages(
                Array(pagesCount)
                    .fill('')
                    .map((_, i) => i + 1),
            )
        }
    }, [pagesCount])

    useLayoutEffect(() => {
        setPagesCount(Math.ceil(count / limit))
    }, [count,limit])

    useEffect(() => {
        if (count <= limit && currentPage > 1) {
            handleLeftArrow()
        }
    }, [count,limit])

    if(!count || count===0){
        return <div/>
    }

    return (
        <div className={s.paginator}>
            <div className={s.paginatorContainer}>
                <div className={s.pageContainer}>
                    <button
                        className={s.paginatorBtn}
                        disabled={currentPage === 1}
                        onClick={handleLeftArrow}
                    >
                        <ArrowLeftIcon />
                    </button>
                    <div className={s.pageNumbersBlock}>
                        {isLoading ? '---' : showPageNumbers()}
                    </div>
                    <button
                        className={s.paginatorBtn}
                        disabled={currentPage === pagesCount}
                        onClick={handleRightArrow}
                    >
                        <ArrowLeftIcon style={{ transform: 'rotate(180deg' }} />
                    </button>
                </div>
            </div>
        </div>
    )
}
