import React, { FC } from 'react'
import { IUserLogoProps } from './types'
import './userLogo.css'
import UiPopover from '../ui/popover/UiPopover'
import UiPopoverContent from '../ui/popover/UiPopoverContent'

const setBlockStyles = (
    color: string,
    shift: string | number,
    zIndex: number,
) => {
    return {
        backgroundColor: color,
        zIndex,
        transform: String(shift),
    }
}

export const UserLogo: FC<IUserLogoProps> = (props) => {
    const { value, color, zIndex, leftShift, title, member } = props
    return (
        <UiPopover placement="top" content={<UiPopoverContent title={title} />}>
            {member && member?.user?.avatar ? (
                <div
                    className=" scheduler__menu-userLogo"
                    style={setBlockStyles(color, leftShift, zIndex)}
                >
                    <img
                        className={'scheduler__menu-userImg'}
                        src={member?.user?.avatar?.small}
                        alt="user image"
                    />
                </div>
            ) : (
                <div
                    className="scheduler__menu-userLogo"
                    style={setBlockStyles(color, leftShift, zIndex)}
                >
                    <span>{value}</span>
                </div>
            )}
        </UiPopover>
    )
}
