import React, { FC, ReactNode, SetStateAction, useEffect, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { IPopoverMenuItem } from '../../../../app/types/common'
import { ITask, ITaskFieldsForUpdates } from '../../../../app/types/models/tasks'
import EditTaskModal from '../../../../entities/editTaskModal/EditTaskModal'
import { useAppDispatch } from '../../../../hooks/redux-hooks'
import { CopyDataIcon } from '../../../../image_files/icons/CopyDataIcon'
import { DeleteIcon } from '../../../../image_files/icons/DeleteIcon'
import { EditIcon } from '../../../../image_files/icons/EditIcon'
import { OpenInNewTabIcon } from '../../../../image_files/icons/OpenInNewTabIcon'
import ModalInlog from '../../../../shared/modalInlog/ModalInlog'
import PopoverMenu from '../../../../shared/popoverMenu/PopoverMenu'
import UiInput from '../../../../shared/ui/input/UiInput'
import { panelsActions } from '../../../../store/panels'
import { tasksActions } from '../../../../store/tasks'
import { findColorByParam } from '../../../../utils/findColorByParam'
import { errorsHandler } from '../../../../utils/helpers/errors/errors-hendler'
import { getValidText } from '../../../../utils/helpers/getValidText'
import { showNotify } from '../../../../utils/helpers/showNotice'
import './panelTask.css'
import { goToNewTab } from '../../../../utils/urlHelpers'
import { useSelector } from 'react-redux'
import { selectCurrentOrganization } from '../../../../store/projects/selectors'

const getTaskStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    background: isDragging ? 'lightgreen' : '#fff',
    ...draggableStyle,
})

enum ModalDataTypes {
    TASK_DELITING = 'task-deleting',
    TASK_EDITING = 'task-editing',
}

const initialModalData = {
    type: ModalDataTypes.TASK_EDITING as ModalDataTypes,
    open: false,
    title: '',
    iconTitle: undefined as ReactNode | undefined,
    value: '' as any,
}

interface IProps {
    task: ITask
    taskIndex: number
    currentProjectId: number
    panelPosition: number

    setCurrentSlug: (data: SetStateAction<string>) => void
}

const PanelTask: FC<IProps> = ({
    task,
    taskIndex,
    panelPosition,
    setCurrentSlug,
    currentProjectId,
}) => {
    const targetUrl = `../${currentProjectId}/tasks/${task?.is_template ? 'templates' : 'items'}/${task?.slug}`
    const [modalData, setModalData] = useState(initialModalData)
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const copyUrl = window.location.href
    const currentOrganization = useSelector(selectCurrentOrganization)
    const domain = copyUrl.replace(/^(https?:\/\/)(?:www\.)?([^\/:]+(:\d+)?).*$/, '$1$2')
    const [closeMenuTrigger, setCloseMenuTrigger] = useState(0)

    const closeModal = () => {
        setModalData(initialModalData)
        setCloseMenuTrigger((prev) => prev + 1)
    }

    const handleDeleteTask = () => {
        setModalData({
            open: true,
            iconTitle: <DeleteIcon />,
            type: ModalDataTypes.TASK_DELITING,
            title: t(ModalDataTypes.TASK_DELITING),
            value: '',
        })
    }

    const editTaskData = (data: ITaskFieldsForUpdates) =>{
        const resultTask = { ...task, ...data,project:currentProjectId } as ITask
        if (
            data.doers !== undefined ||
            data.supervisor !== undefined ||
            data.comment !== undefined ||
            data.file !== undefined ||
            data.deletedFile !== undefined
        ) {
            if (data.doers) {
                dispatch(
                    tasksActions.changeTaskDoers({
                        idList: data.doers,
                        projectId: currentProjectId,
                        task: task,
                        panelPosition,
                        onSuccess: () => {
                            // showNotify(t('task-updated-successfully'))
                        },
                        onError: (e) => {
                            errorsHandler(e, t)
                        },
                    }),
                )
            }
            if (data.supervisor) {
                dispatch(
                    tasksActions.changeTaskSupervisor({
                        projectId: currentProjectId,
                        id: data.supervisor,
                        task: task,
                        panelPosition,
                        onSuccess: () => {
                            // showNotify(t('task-updated-successfully'))
                        },
                        onError: (e) => {
                            errorsHandler(e, t)
                        },
                    }),
                )
            }
            if (data.comment) {
                dispatch(
                    tasksActions.addNewComment({
                        projectId: currentProjectId,
                        slug: resultTask?.slug,
                        task:resultTask,
                        value: data.comment,
                        panelPosition,
                    }),
                )
            }
            if (data.file) {
                dispatch(
                    tasksActions.addTaskFiles({
                        projectId: currentProjectId,
                        slug: resultTask?.slug,
                        task:resultTask,
                        file: data?.file,
                        panelPosition,
                        onSuccess:()=>{
                            // showNotify(t('file-added-successfully'))
                        }
                    }),
                )
            }
            if (data.deletedFile) {
                dispatch(
                    tasksActions.deleteTaskFile({
                        projectId: currentProjectId,
                        slug: resultTask?.slug,
                        fileId: data.deletedFile,
                        task:resultTask,
                        panelPosition,
                        onSuccess:()=>{
                            // showNotify(t('file-deleted-successfully'))
                        }
                    }),
                )
            }
        } else {
            if (data && Object.keys(data).length > 0) {
                dispatch(
                    tasksActions.changeTaskFields({
                        data,
                        task: resultTask,
                        panelPosition,
                        onSuccess: () => {
                            // showNotify(t('task-updated-successfully'))
                        },
                        onError: (e) => {
                            errorsHandler(e, t)
                        },
                    }),
                )
            }
        }
    }

    const handleEditTask = () => {
        setModalData({
            open: true,
            iconTitle: <EditIcon />,
            type: ModalDataTypes.TASK_EDITING,
            title: t(ModalDataTypes.TASK_EDITING),
            value: task,
        })
    }

    const deleteTask = () => {
        dispatch(
            panelsActions.removePanelTask({
                position: panelPosition,
                projectId: currentProjectId,
                task: task,
                onSuccess: () => {
                    closeModal()
                    showNotify(t('task-deleted-successfully'))
                },
                onError: closeModal,
            }),
        )
    }

    const editTask = () => {
        dispatch(
            panelsActions.renamePanelTask({
                panelPosition,
                task: {
                    name: modalData.value,
                    slug: task?.slug,
                    status: task?.status,
                    status_position: task?.status_position,
                } as ITask,
                projectId: currentProjectId,
                onSuccess: () => {
                    closeModal()
                    showNotify(t('task-updated-successfully'))
                },
                onError: closeModal,
            }),
        )
    }

    const menuItems: IPopoverMenuItem[] = [
        {
            id: 1,
            title: t('open-in-new-tab'),
            icon: <OpenInNewTabIcon />,
            onClick: () => {
                goToNewTab(targetUrl)
            },
        },
        {
            id: 2,
            title: t('copy-link'),
            icon: <CopyDataIcon />,
            onClick: () => {
                navigator.clipboard
                    .writeText(`${domain}/scheduler/${currentOrganization}/${currentProjectId}/tasks/${task?.is_template ? 'templates' : 'items'}/${task.slug}`)
                    .then(() => {
                        showNotify(t('link-copied'))
                        setCloseMenuTrigger((prev) => prev + 1)
                    })
            },
        },
        {
            id: 3,
            title: t('edit-task'),
            icon: <EditIcon />,
            onClick: handleEditTask,
        },
        {
            id: 4,
            title: t('delete-task'),
            icon: <DeleteIcon />,
            onClick: handleDeleteTask,
        },
    ]

    const getModalContent = (type: ModalDataTypes) => {
        switch (type) {
            case ModalDataTypes.TASK_EDITING:
                return (
                    <div>
                        <UiInput
                            value={modalData.value}
                            onChange={(e) => {
                                setModalData((prev) => ({
                                    ...prev,
                                    value: task,
                                }))
                            }}
                        />
                    </div>
                )
            case ModalDataTypes.TASK_DELITING:
                return (
                    <div className="font-14-normal">
                        {t('you-realy-want-do-delete-this-task')}
                    </div>
                )
            default:
                return <></>
        }
    }

    const getModalButtons = (type: ModalDataTypes) => {
        const isEditing = type === ModalDataTypes.TASK_EDITING
        return [
            {
                id: 1,
                titleBtn: t('cancel'),
                className: 'font-14-normal-imp',
                handleBtn: closeModal,
            },
            {
                id: 2,
                type: 'primary',
                titleBtn: isEditing ? t('save') : t('delete'),
                handleBtn: isEditing ? editTask : deleteTask,
            },
        ]
    }

    const getDoersIds = () => {
        if (task && task.doers) {
            return task.doers.map((el) => el?.user?.id)
        } else return []
    }

    useEffect(()=>{
        if(modalData.type===ModalDataTypes.TASK_EDITING && modalData.value?.id!==undefined){
            setModalData(prev=>({...prev,value:task}))
        }
    },[task])

    useEffect(()=>{
        if(modalData.open){
            setCloseMenuTrigger(prev=>prev+1)
        }
    },[modalData.open])

    return (
        <div>
            <Draggable
                draggableId={task?.status_position?.toString()}
                index={taskIndex}
            >
                {(provided, snapshot) => (
                    <div
                        className="statuses__task"
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        onMouseDown={() => setCurrentSlug(task?.slug)}
                        ref={provided.innerRef}
                        style={getTaskStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                        )}
                    >
                        <div className="statuses__task-top">
                            <div className="statuses__task-column">
                                <div
                                    className="priorityStatus"
                                    style={findColorByParam(task?.priority)}
                                ></div>
                                <div className="statuses__task-slag title">
                                    {task?.slug}
                                </div>
                            </div>
                            <PopoverMenu
                                items={menuItems}
                                closeTrigger={closeMenuTrigger}
                            />
                        </div>
                        <div className="font-14-normal mt-8 dotted-text-190 dotted-text-clamp-2">
                            {task?.name}
                        </div>
                    </div>
                )}
            </Draggable>
            {modalData.type !== ModalDataTypes.TASK_EDITING &&
                createPortal(
                    <ModalInlog
                        width={400}
                        footer={null}
                        {...modalData}
                        title={t(getValidText(modalData?.title))}
                        listButton={getModalButtons(modalData.type)}
                        onCancel={closeModal}
                    >
                        {getModalContent(modalData.type)}
                    </ModalInlog>,
                    document.body,
                )}
            {modalData.type === ModalDataTypes.TASK_EDITING && (
                <EditTaskModal
                    isOpen={modalData.open}
                    type='panel-task'
                    projectId={currentProjectId}
                    data={{
                        ...task,
                        id:task?.id,
                        status: task?.status?.id,
                        doers: getDoersIds(),
                        supervisor:task?.supervisor?.user?.id,
                    }}
                    panelPosition={panelPosition}
                    onChange={editTaskData}
                    closeModal={() => setModalData(initialModalData)}
                />
            )}
        </div>
    )
}

export { PanelTask }

