import { createSlice } from '@reduxjs/toolkit'
import { UserResponse } from '../../app/types/response'
import { setLanguage, setUser } from './actions'
import { LanguageTypes } from '../../app/types/enums'

export interface InitialState {
    user: UserResponse | null
    language?: LanguageTypes
}

const initialState: InitialState = {
    user: null,
    language: LanguageTypes.ru,
}

export const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setUser, (state, action) => {
            state.user = action.payload.user
        })
        builder.addCase(setLanguage, (state, action) => {
            state.language = action.payload.language
        })
    },
})
