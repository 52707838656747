import React, { FC } from 'react'
import { Popover, PopoverProps } from 'antd'
import './popoverInLog.scss'
import { getValidText } from '../../utils/helpers/getValidText'

interface IProps extends PopoverProps {}

const PopoverInLog: FC<IProps> = (props) => {
    const {
        content,
        placement = 'top',
        children,
        style,
        trigger,
        open,
        className,
    } = props
    return (
        <Popover
            trigger={trigger}
            color={'black'}
            style={style}
            content={content}
            placement={placement}
            className={`custom-popover ${getValidText(className)}`}
            open={open}
        >
            <div className={'cursor-pointer'}>{children}</div>
        </Popover>
    )
}

export default PopoverInLog
