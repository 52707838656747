import React, { FC, ReactNode, useEffect, useState } from 'react'
import { findColorByParam } from '../../utils/findColorByParam'
import { getCorrectDateFormat } from '../../utils/getCorrectDateFormat'
import { useLocation, useNavigate } from 'react-router-dom'
import './task.css'
import { ITask, ITaskFieldsForUpdates } from '../../app/types/models/tasks'
import react from 'react'
import PopoverMenu from '../../shared/popoverMenu/PopoverMenu'
import { IPopoverMenuItem } from '../../app/types/common'
import { DeleteIcon } from '../../image_files/icons/DeleteIcon'
import { useTranslation } from 'react-i18next'
import { tasksService } from '../../services/tasks-services'
import { useSelector } from 'react-redux'
import { selectCurrentProject } from '../../store/currentProject/selectors'
import { useAppDispatch } from '../../hooks/redux-hooks'
import { errorsHandler } from '../../utils/helpers/errors/errors-hendler'
import { deleteTask } from '../../store/tasks/actions'
import SpinInLog from '../../shared/spin/spin'
import { createPortal } from 'react-dom'
import ModalInlog from '../../shared/modalInlog/ModalInlog'
import { ButtonItem } from '../../app/types/i-button'
import { stopEvent } from '../../shared/stopEvent'
import { showNotify } from '../../utils/helpers/showNotice'
import { getTaskSlugFromUrl } from '../../services/get-task-slug-from-url'
import { TasksTabTypes } from '../../features/tasks/types'
import { TempateIcon } from '../../image_files/icons/TemplateIcon'
import EditTaskModal from '../editTaskModal/EditTaskModal'
import { tasksActions } from '../../store/tasks'
import { templatesActions } from '../../store/templates'

interface IProps {
    task: ITask
    type: TasksTabTypes
    onClick: (value: string) => void
}

const initialModalData: IModalData = {
    title: '',
    isOpen: false,
    text: '',
    type: 'delete',
    data: {},
}

interface IModalData {
    title: string
    titleIcon?: ReactNode
    isOpen: boolean
    text: string
    type: 'delete' | 'edit'
    saveBtnTitle?: string
    data?: any
    onSave?: () => void
}

export const Task: FC<IProps> = react.memo(({ task, type, onClick }) => {
    const { t } = useTranslation()
    const location = useLocation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const currentProject = useSelector(selectCurrentProject)
    const [closeMenuTrigger, setCloseMenuTrigger] = useState(0)
    const [modalsData, setModalsData] = useState(initialModalData)

    const deleteITem = () => {
        dispatch(
            tasksActions.removeTask({
                projectId: currentProject?.id,
                taskSlug: task?.slug,
                onSuccess: () => {
                    setCloseMenuTrigger((prev) => prev + 1)
                    setModalsData(initialModalData)
                    const urlChunks = location.pathname
                        .split('/')
                        .filter((el) => el !== '')
                    if (urlChunks.length > 5) {
                        const path = urlChunks
                            .slice(0, urlChunks.length - 1)
                            .join('/')
                        navigate(path)
                    }
                    navigate(location.pathname, { replace: true })
                },
            }),
        )
    }

    const createTemplate = () => {
        dispatch(
            tasksActions.createTask({
                projectId: currentProject?.id,
                task: {
                    name: task?.name,
                    priority: task.priority,
                    status: task.status?.id,
                    is_template: true,
                },
                onSuccess: (res) => {
                    showNotify(t('template-added-successfully'))
                    dispatch(
                        tasksActions.getCurrentTask({
                            slug: res?.slug,
                            onSuccess: (taskDetail) => {
                                if (taskDetail) {
                                    setModalsData({
                                        isOpen: true,
                                        // text: t('you-realy-want-do-delete-this-task'),
                                        // titleIcon: <DeleteIcon width={18} height={18} />,
                                        text: '',
                                        title: '',
                                        onSave: () => undefined,
                                        data: taskDetail,
                                        type: 'edit',
                                    })
                                    setCloseMenuTrigger((prev) => prev + 1)
                                }
                            },
                        }),
                    )
                },
            }),
        )
    }

    const changeTemplate = (template: ITask, data: ITaskFieldsForUpdates) => {
        const resultTask = {
            ...template,
            ...data,
            project: currentProject?.id,
        } as any
        if (
            data.doers !== undefined ||
            data.supervisor !== undefined ||
            data.comment !== undefined ||
            data.file !== undefined ||
            data.deletedFile !== undefined ||
            data.sub_task_data !== undefined
        ) {
            if (data.doers) {
                dispatch(
                    tasksActions.changeTaskDoers({
                        idList: resultTask.doers,
                        projectId: currentProject?.id,
                        task: template,
                        onSuccess: (res) => {
                            // showNotify(t('task-updated-successfully'))
                        },
                        onError: (e) => {
                            errorsHandler(e, t)
                        },
                    }),
                )
            }
            if (data.supervisor) {
                dispatch(
                    tasksActions.changeTaskSupervisor({
                        projectId: currentProject?.id,
                        id: resultTask.supervisor,
                        task: template,
                        onSuccess: () => {
                            // showNotify(t('task-updated-successfully'))
                        },
                        onError: (e) => {
                            errorsHandler(e, t)
                        },
                    }),
                )
            }
            if (data.deletedFile) {
                dispatch(
                    tasksActions.deleteTaskFile({
                        projectId: currentProject?.id,
                        slug: resultTask?.slug,
                        fileId: data.deletedFile,
                        onSuccess: () => {
                            // showNotify(t('file-deleted-successfully'))
                        },
                    }),
                )
            }
            if (data.comment) {
                dispatch(
                    tasksActions.addNewComment({
                        projectId: currentProject?.id,
                        slug: resultTask?.slug,
                        value: data.comment,
                        onSuccess: (res) => {
                            setModalsData((prev) => ({
                                ...prev,
                                data: {
                                    ...prev.data,
                                    comments: prev?.data?.comments
                                        ? [...prev.data.comments, res]
                                        : [res],
                                },
                            }))
                        },
                    }),
                )
            }
            if (data.file) {
                dispatch(
                    tasksActions.addTaskFiles({
                        projectId: currentProject?.id,
                        slug: resultTask?.slug,
                        file: data?.file,
                        onSuccess: (res) => {
                            setModalsData((prev) => ({
                                ...prev,
                                data: {
                                    ...prev.data,
                                    files: prev?.data?.files
                                        ? [...prev.data.files, res]
                                        : [res],
                                },
                            }))
                            // showNotify(t('file-added-successfully'))
                        },
                    }),
                )
            }
            if (data.deletedFile) {
                dispatch(
                    tasksActions.deleteTaskFile({
                        projectId: currentProject?.id,
                        slug: resultTask?.slug,
                        fileId: data.deletedFile,
                        onSuccess: () => {
                            // showNotify(t('file-deleted-successfully'))
                        },
                    }),
                )
            }
            if (data.sub_task_data) {
                if (data.sub_task_data.type === 'add-subtask') {
                    dispatch(
                        tasksActions.addNewSubtask({
                            projectId: currentProject?.id,
                            taskId: template?.id,
                            value: data.sub_task_data.value,
                            is_template: true,
                            onSuccess: (res) => {
                                setModalsData((prev) => ({
                                    ...prev,
                                    data: {
                                        ...prev.data,
                                        subtasks: prev?.data?.subtasks
                                            ? [...prev.data.subtasks, res]
                                            : [res],
                                    },
                                }))
                                // showNotify(t('task-added-successfully'))
                            },
                        }),
                    )
                }
                if (data.sub_task_data.type === 'change-subtask-title') {
                    dispatch(
                        tasksActions.changeSubtaskTitle({
                            projectId: currentProject?.id,
                            parentTaskId: task?.id,
                            value: data.sub_task_data.value,
                            slug: data.sub_task_data?.slug,
                            onSuccess: (res) => {
                                setModalsData((prev) => ({
                                    ...prev,
                                    data: {
                                        ...prev.data,
                                        subtasks: prev?.data?.subtasks
                                            ? prev?.data?.subtasks.map((el) =>
                                                  el?.slug ===
                                                  data.sub_task_data?.slug
                                                      ? res
                                                      : el,
                                              )
                                            : [res],
                                    },
                                }))
                                // showNotify(t('task-added-successfully'))
                            },
                        }),
                    )
                }
                if (data.sub_task_data.type === 'delete-subtask') {
                    dispatch(
                        tasksActions.removeSubtask({
                            projectId: currentProject?.id,
                            parentTaskSlug: task?.slug,
                            slug: data.sub_task_data?.slug,
                            onSuccess: () => {
                                setModalsData((prev) => ({
                                    ...prev,
                                    data: {
                                        ...prev.data,
                                        subtasks: prev?.data?.subtasks
                                            ? prev?.data?.subtasks.filter(
                                                  (el) =>
                                                      el?.slug !==
                                                      data.sub_task_data?.slug,
                                              )
                                            : [],
                                    },
                                }))
                            },
                        }),
                    )
                }
            }
        } else {
            if (data && Object.keys(data).length > 0) {
                dispatch(
                    tasksActions.changeTaskFields({
                        task: resultTask,
                        data,
                        onSuccess: () => {
                            // showNotify(t('task-updated-successfully'))
                        },
                        onError: (e) => {
                            errorsHandler(e, t)
                        },
                    }),
                )
            }
        }
    }

    const showValidItems = () => {
        if (type === TasksTabTypes.TASKS) {
            return [
                {
                    id: 0,
                    title: t('create-template'),
                    icon: <TempateIcon />,
                    onClick: createTemplate,
                },
                {
                    id: 1,
                    title: t('delete-task'),
                    icon: <DeleteIcon />,
                    onClick: () => {
                        setModalsData({
                            isOpen: true,
                            text: t('you-realy-want-do-delete-this-task'),
                            titleIcon: <DeleteIcon width={18} height={18} />,
                            title: t('task-deleting'),
                            onSave: deleteITem,
                            type: 'delete',
                        })
                        setCloseMenuTrigger((prev) => prev + 1)
                    },
                },
            ]
        } else {
            return [
                {
                    id: 0,
                    title: t('delete-template'),
                    icon: <DeleteIcon />,
                    onClick: () => {
                        setModalsData({
                            isOpen: true,
                            text: t('you-realy-want-do-delete-this-template'),
                            titleIcon: <DeleteIcon width={18} height={18} />,
                            title: t('template-editing'),
                            onSave: deleteITem,
                            type: 'delete',
                        })
                        setCloseMenuTrigger((prev) => prev + 1)
                    },
                },
            ]
        }
    }

    const modalBtnList: ButtonItem[] = [
        {
            id: 1,
            titleBtn: t('cancel'),
            className: 'font-14-normal-imp',
            handleBtn: () => setModalsData(initialModalData),
        },
        {
            id: 2,
            type: 'primary',
            titleBtn: modalsData?.saveBtnTitle || t('delete'),
            handleBtn: modalsData?.onSave,
        },
    ]

    const handleClick = (e) => {
        stopEvent(e)
        if (!modalsData?.isOpen) {
            onClick(task?.slug)
        }
    }

    const getDoersIds = (data: any) => {
        if (data && data.doers) {
            return data.doers.map((el) => el?.user?.id)
        } else return []
    }

    return (
        <div
            className={`br-default transition-2 cursor-pointer pos-relative ${
                type === TasksTabTypes.TASKS
                    ? 'scheduler-task'
                    : 'scheduler-template'
            }`}
            onClick={handleClick}
            style={
                getTaskSlugFromUrl(location.pathname) === task.slug
                    ? { backgroundColor: 'rgba(148, 215, 219, 0.3)' }
                    : {}
            }
        >
            {type === TasksTabTypes.TASKS && (
                <div
                    className="scheduler-task__priority"
                    style={findColorByParam(task?.priority)}
                ></div>
            )}

            <div className="font-14-normal">
                <div className="row-flex-sb">
                    <div>{task?.slug}</div>
                    <div>
                        {getCorrectDateFormat({ date: task?.created_at })}
                    </div>
                </div>

                <div className={'dotted-text-210'}>{task?.name}</div>

                {modalsData.type === 'delete' &&
                    createPortal(
                        <ModalInlog
                            width={400}
                            open={modalsData.isOpen}
                            title={modalsData.title}
                            iconTitle={modalsData?.titleIcon}
                            listButton={modalBtnList}
                            onCancel={() => setModalsData(initialModalData)}
                        >
                            <div className="font-14-normal roadmap-item-modal-inner">
                                {modalsData?.text}
                            </div>
                        </ModalInlog>,
                        document.body,
                    )}
                {modalsData.type === 'edit' && (
                    <EditTaskModal
                        isOpen={modalsData.isOpen}
                        title={modalsData.title}
                        type="template"
                        projectId={currentProject?.id}
                        data={{
                            ...modalsData.data,
                            id: modalsData.data?.id,
                            status: modalsData.data?.status?.id,
                            doers: getDoersIds(modalsData.data),
                            supervisor: modalsData.data?.supervisor?.user?.id,
                        }}
                        onChange={(param) =>
                            changeTemplate(modalsData.data, param)
                        }
                        closeModal={() => setModalsData(initialModalData)}
                    />
                )}
            </div>
            <div>
                {/* {type === TasksTabTypes.TEMPLATES && (
                    <div className="font-14-normal">
                        {getCorrectDateFormat({ date: task?.created_at })}
                    </div>
                )} */}
                <PopoverMenu
                    items={showValidItems()}
                    closeTrigger={closeMenuTrigger}
                    className="scheduler-task-menu"
                />
            </div>
        </div>
    )
})
