import React, { FC, useCallback, useEffect, useState } from 'react'
import './membersItem.css'
import { TaskCancelIcon } from '../../image_files/icons/TaskCancelIcon'
import { getFirstMemberCharacter } from '../../utils/helpers/getFirstMemberCharacter'
import { getValidText } from '../../utils/helpers/getValidText'
import { IMember, IUser } from '../../app/types/models/users'

interface IProps{
    member: IMember
    className?: string
    isHideText?:boolean
    textClassName?: string
    checkedMembers?: string[]
    containerClassName?: string
    
    setMembers?: () => void
    removeMembers?: () => void
}

export const MembersItem: FC<IProps> = ({
    member,
    isHideText,
    removeMembers,
}) => {
    const storageMembers = JSON.parse(sessionStorage.getItem('members'))
    const [bgColor, setBgColor] = useState('')

    const user = member?.user || ({} as IUser)

    const getValidName = (isCutText?: boolean) => {
        let targetName = ''
        if (user?.full_name !== undefined && user.full_name?.length > 0) {
            targetName = user.full_name
        } else if (
            user?.first_name !== undefined ||
            user?.last_name !== undefined
        ) {
            targetName = `${getValidText(user?.first_name)} ${getValidText(
                user?.last_name,
            )}`
        } else if (user?.email && user.email?.length > 0) {
            targetName = user?.email
        }

        // if (isCutText) {
        //     return targetName.length > 20
        //         ? `${targetName.slice(0, 19)}...`
        //         : targetName
        // } else return targetName

        return targetName
    }

    const findCurrentBgColor = useCallback(() => {
        const currentMember = storageMembers?.filter((f) => {
            if (f?.user?.full_name === getValidName()) {
                return f
            }
        })
        return currentMember && `${currentMember[0]?.color}`
    }, [storageMembers,member])

    useEffect(() => {
        setBgColor(findCurrentBgColor())
    }, [storageMembers,member])

    return (
        <div
            // style={
            //     checkedMembers &&
            //     member.name &&
            //     Object.values(checkedMembers).includes(member?.name)
            //         ? { background: 'lightgray' }
            //         : {}
            // }
            // className={
            //     containerClassName
            //         ? `membersSelect__bottom-item ${containerClassName}`
            //         : 'membersSelect__bottom-item'
            // }
            // onClick={setMembers ? setMembers : () => {}}
            className={`
                w-100p 
                cursor-pointer 
                transition-2 
                row-flex-5 
                member-item
                `}
        >
            {member && member?.user?.avatar ? (
                <img
                    src={member?.user?.avatar ? member.user.avatar?.small : ''}
                    alt="user image"
                />
            ) : (
                <div
                    className={'font-10-normal flex-c-c flex-shrink-0 member-item-phone '}
                    style={{ backgroundColor: bgColor }}
                >
                    <span>{getFirstMemberCharacter(member)}</span>
                </div>
            )}
            {!isHideText && (
                <span
                    className={`font-14-normal membersSelect__bottom-item-text`}
                >
                    {getValidName(true)}
                </span>
            )}
            {removeMembers && (
                <button
                    className="membersSelect__bottom-btn"
                    onClick={removeMembers ? removeMembers : () => {}}
                >
                    <TaskCancelIcon className={'cancelIcon'} />
                </button>
            )}
        </div>
    )
}
