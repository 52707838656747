import { createAction } from '@reduxjs/toolkit'
import { RequestStatusType } from '../../app/types/request'
import { SchedulerPagesType } from '../../widgets/scheduler/types'

const setAppStatus = createAction<{ status: RequestStatusType }>(
    'appActions/setAppStatus',
)
const setAppError = createAction<{ error: string | null }>(
    'appActions/setAppError',
)

const setAppIsInitialized = createAction<{ isInitialized: boolean }>(
    'appActions/setAppIsInitialized',
)
const setSchedulerPage = createAction<SchedulerPagesType>(
    'appActions/setSchedulerPage',
)

export { setAppStatus, setAppError, setAppIsInitialized,setSchedulerPage }
