import React, { FC } from 'react'
import { IIconProps } from '../../app/types/i-props'

export const TempateIcon: FC<IIconProps> = ({ className, onClick }) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="2.5"
                y="2.5"
                width="19"
                height="19"
                rx="4.5"
                stroke="#2B506B"
            />
            <line x1="11.5" y1="3" x2="11.5" y2="21" stroke="#2B506B" />
            <line x1="11" y1="8.5" x2="22" y2="8.5" stroke="#2B506B" />
            <line x1="2" y1="15.5" x2="11" y2="15.5" stroke="#2B506B" />
        </svg>
    )
}
