import { IProject } from '../../app/types/models/project'
import { RootState } from '../store'

export const selectProjects = (state: RootState): IProject[] =>
    state.projects.projects

export const selectCurrentOrganization = (state: RootState): string | number =>
    state.projects.currentOrganization

export const projectsLoadingStatus = (state: RootState): boolean =>
    state.projects.loading
export const projectsOpenMenuStatus = (state: RootState): boolean =>
    state.projects.isOpenProjectsMenu
