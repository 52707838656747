import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { RoleTypes } from "../../app/types/enums";
import { useAppDispatch } from "../../hooks/redux-hooks";
import { userService } from "../../services/user-service";
import { MembersItem } from "../../shared/MembersItem/MembersItem";
import SelectInLog from "../../shared/select/Select";
import { selectCurrentProject } from "../../store/currentProject/selectors";
import { selectMembers } from "../../store/members/selectors";
import { setProjects } from "../../store/projects/actions";
import { selectProjects } from "../../store/projects/selectors";
import { errorsHandler } from "../../utils/helpers/errors/errors-hendler";
import { showNotice } from "../../utils/helpers/showNotice";
import { getValidRoleOptions } from "../team/data/getValidRoleOptions";
import { IShareProjectItem } from "./types";

const ShareProjectItem: FC<IShareProjectItem> = ({member, me, fetchMembers}) => {
    const currentProject = useSelector(selectCurrentProject);
    const [currentOption, setCurrentOption] = useState<RoleTypes>('' as RoleTypes)
    const {t} = useTranslation()
    const roleOptions = getValidRoleOptions(me, member, t)
    const members = useSelector(selectMembers)
    const navigate = useNavigate()
    const projects = useSelector(selectProjects)
    const currentProjectIndex = projects?.findIndex(el => el?.id === currentProject?.id)
    const dispatch = useAppDispatch()

    const changeRole = async (value: any) => {
        try {
            if (me?.role === RoleTypes.admin) {
                if (member?.role === RoleTypes.admin) {
                    if (value === 'leave_project') {
                        toast.error(t('first-transfer-your-admin-rights'), {position: 'top-center'})
                    }
                } else {
                    if (value === 'transfer_rights') {
                        await userService.changeRole(currentProject?.id, member?.id, {role: RoleTypes.admin})
                        await fetchMembers()
                        setCurrentOption(RoleTypes.admin)
                        showNotice('admin-rights-transfered-successfully', t)
                    } else if (value === 'close_access') {
                        await userService.closeRoleAccess(currentProject?.id, member?.id)
                        await fetchMembers()
                        setCurrentOption(value)
                        showNotice('member-deleted-successfully-from-project', t)
                    } else {
                        await userService.changeRole(currentProject?.id, member?.id, {role: value})
                        setCurrentOption(value)
                        showNotice('member-role-changed-successfully', t)
                    }
                }
            } else {
                if (value === 'leave_project') {
                    await userService.closeRoleAccess(currentProject?.id, member?.id)
                    const filteredProjects = projects.filter(item => item.id !== currentProject.id)
                    if (filteredProjects.length === 0) {
                        dispatch(setProjects({projects: []}))
                        navigate(`/logging/new`)
                    } else {
                        const targetIndex = currentProjectIndex !== projects?.length - 1 ? currentProjectIndex + 1 : 0
                        const targetProject = projects[targetIndex]
                        navigate(`/scheduler/${targetProject?.id}/tasks`, {
                            state: {
                                projects: projects?.filter(item => item?.id !== currentProject?.id)
                            }
                        })
                    }
                } else {
                    if (value === RoleTypes.editor || value === RoleTypes.member) {
                        await userService.changeRoleByUser(currentProject?.id, {
                            role: value,
                            project: currentProject?.id
                        })
                    } else {
                        await userService.changeRole(currentProject?.id, member?.id, {role: value})
                    }
                    setCurrentOption(value)
                    showNotice('member-role-changed-successfully', t)
                }
            }
        } catch (e) {
            errorsHandler(e, t)
        }
    }

    const showValidRoleField = () => {
        if (roleOptions?.length === 0) {
            return <span className={'font-16-normal pl-14'}>{t('creator').toString()}</span>
        } else {
            if (roleOptions?.length > 0 && members?.length > 1) {
                return <SelectInLog
                    className={'share-project-item-select pl-14 '}
                    options={roleOptions}
                    value={currentOption}
                    onChange={changeRole}
                />
            } else {
                return <span
                    className={'font-14-normal pl-8'}>{t('creator').toString()}</span>
            }
        }
    }

    useEffect(() => {
        if (member) {
            setCurrentOption(member?.role)
        }
    }, [member])
    return (
        <li className={'share-project-users-item'}>
            <>
                <MembersItem member={member}/>
                {showValidRoleField()}
            </>
        </li>
    );
};

export default ShareProjectItem;