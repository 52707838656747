import { ICalendarItem } from '../../../../../app/types/i-roadmap-calendar'

// export const getDayPosition = (params: {
//     startDate: Date
//     endDate: Date
//     info: ICalendarItem
// }) => {
//     const { info } = params

//     let startPosition = 0,
//         endPosition = 0

//     const daysArray = Object.values(info?.value?.items).sort((a,b)=>a.position-b.position)
//     daysArray.forEach((item, i, arr) => {
//         const currentDate = new Date(item.year, item.month, item.day).getTime()
//         const startDate = params.startDate.getTime()
//         const endDate = params.endDate.getTime()
//         if (currentDate === startDate) { 
//             startPosition = item.position
//         }
  
//         if (
//             currentDate > startDate &&
//             currentDate < endDate
//         ) {
//             endPosition = item.position
//         }

//         if (currentDate === endDate) {
//             endPosition = item.position
//         }

//     })

//     return {
//         startPosition,
//         endPosition,
//     }
// }

export const getDayPosition = (params: {
    startDate: Date
    endDate: Date
    info: ICalendarItem
}) => {
    const { info, startDate, endDate } = params;

    let startPosition = -1,
        endPosition = -1;

    const daysArray = Object.values(info?.value?.items).sort(
        (a, b) => a.position - b.position
    );

    // Преобразуем startDate и endDate в миллисекунды для точного сравнения
    const startTimestamp = startDate.getTime();
    const endTimestamp = endDate.getTime();

    daysArray.forEach((item,i,arr) => {
        const currentTimestamp = new Date(item.year, item.month, item.day).getTime();

        // Устанавливаем startPosition, если текущая дата совпадает или больше startDate
        if (currentTimestamp >= startTimestamp && startPosition === -1) {
            startPosition = item.position;
        }

        // Устанавливаем endPosition, если текущая дата меньше или равна endDate
        if (currentTimestamp <= endTimestamp) {
            endPosition = item.position+1;
        }
    });

    // Если startPosition или endPosition не установлены, возвращаем дефолтные значения
    if (startPosition === -1 || endPosition === -1) {
        return { startPosition: 1, endPosition: 1 };
    }

    return { startPosition, endPosition };
};


