import { ConfigProvider, DatePicker } from 'antd';

import enUS from 'antd/es/locale/en_US';
import ruRU from 'antd/es/locale/ru_RU';
import { DefaultOptionType } from 'antd/lib/select';
import moment from 'moment';
import 'moment/locale/ru';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DATE_FORMAT, DATE_FORMAT_TO_SERVER } from '../../../../app/constants';
import { RoadMapDetalizationTypes } from '../../../../app/types/enums';
import UISelect from '../../../../shared/ui/select/UlSelect';
import { selectTaskData } from '../../../../store/tasks/selectors';
import { RoadmapContext } from '../../providers';
import { getMinPeriodFromTasksList } from '../../services';
import { IDetalizationData } from '../../types';

const RoadmapDetalizationFilter = () => {
    const { t, i18n } = useTranslation()
    const locale = i18n.language === 'ru' ? ruRU : enUS;
    const { results: tasks } = useSelector(selectTaskData)
    const { detalizationData, setDetalizationData } = useContext(RoadmapContext)
    const [fieldsData, setFieldsData] = useState<IDetalizationData>({
        detalization: RoadMapDetalizationTypes.MONTH,
        startPeriod: new Date() as string | Date,
        endPeriod: new Date() as string | Date,
    })

    const detailsSelectOptions: DefaultOptionType[] = [
        {
            label: t('week'),
            value: RoadMapDetalizationTypes.MONTH,
            title: '',
        },
        {
            label: t('month'),
            value: RoadMapDetalizationTypes.QUARTER,
            title: '',
        },
        {
            label: t('year'),
            value: RoadMapDetalizationTypes.YEAR,
            title: '',
        },
    ]

    const handleChangePeriod = (value: any[]) => {
        if (Array.isArray(value)) {
            setFieldsData((prev) => ({
                ...prev,
                startPeriod: moment(value[0]).format(DATE_FORMAT_TO_SERVER),
                endPeriod: moment(value[1]).format(DATE_FORMAT_TO_SERVER),
            }))

            setDetalizationData((prev) => ({
                ...prev,
                startPeriod: moment(value[0]).format(DATE_FORMAT_TO_SERVER),
                endPeriod: moment(value[1]).format(DATE_FORMAT_TO_SERVER),
            }))
        }
    }

    useEffect(() => {
        if (detalizationData.detalization) {
            setFieldsData((prev) => ({
                ...prev,
                detalization: detalizationData.detalization,
            }))
        }
    }, [detalizationData])

    useEffect(() => {
        const periodData = getMinPeriodFromTasksList(tasks)
        setFieldsData((prev) => ({
            ...prev,
            startPeriod: periodData.min,
            endPeriod: periodData.max,
        }))
        setDetalizationData({
            detalization: fieldsData.detalization,
            startPeriod: periodData.min,
            endPeriod: periodData.max,
        })
    }, [tasks])

    return (
        <div className="roadMap-top-item roadMap-top-item-detalization">
            <div className="roadmap-filter-field-wrap">
                <UISelect
                    label={t('detalization')}
                    options={detailsSelectOptions}
                    value={fieldsData.detalization}
                    onChange={(e) => {
                        setFieldsData((prev) => ({ ...prev, detalization: e }))
                        setDetalizationData({ ...fieldsData, detalization: e })
                    }}
                />
            </div>
            <div className="roadmap-filter-field-wrap">
                <div className="roadmap-filter-field-label">{t('period')}</div>
                <ConfigProvider locale={locale}>
                    <DatePicker.RangePicker
                        allowClear
                        format={DATE_FORMAT}
                        value={[
                            moment(fieldsData.startPeriod),
                            moment(fieldsData.endPeriod),
                        ]}
                        className="datePicker-item"
                        onChange={handleChangePeriod}
                    />
                </ConfigProvider>
            </div>
        </div>
    )
}

export default RoadmapDetalizationFilter
