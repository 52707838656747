import { createAction } from "@reduxjs/toolkit"
import { RequestStatusType } from "../../app/types/request"
import { ITask } from "../../app/types/models/tasks"

const setTemplates = createAction<{
    count: number
    next: string
    previous: string
    results: any[]
}>('templatesActions/setTemplates')

const setTemplatesStatus = createAction<RequestStatusType>('templatesActions/setTemplatesStatus')
const addTemplate = createAction<ITask>('templatesActions/addTemplate')
const changeTemplate = createAction<ITask>('templatesActions/changeTemplate')
const deleteTemplate = createAction<number>('templatesActions/deleteTemplate')

export {
    setTemplates,addTemplate,setTemplatesStatus,changeTemplate,deleteTemplate
}