import { createAction } from '@reduxjs/toolkit'
import { IProject } from '../../app/types/models/project'
import { ICore, IPad, IWell, IWellBore } from '../../app/types/models/logging'

const setCurrentOrganization = createAction<number | string>(
    'projectsActions/setCurrentOrganization',
)
const setProjectsLoading = createAction<{ loading: boolean }>(
    'projectsActions/setProjectsLoading',
)
const setProjects = createAction<{ projects: IProject[] }>(
    'projectsActions/setProjects',
)

const addProject = createAction<{ project: IProject }>(
    'projectsActions/addProject',
)

const deleteProject = createAction<{ id: number }>(
    'projectsActions/deleteProject',
)

const updateProjectName = createAction<{ id: number; name: string }>(
    'projectsActions/updateProjectName',
)

const setPads = createAction<{ id: number; pads: IPad[] }>(
    'projectsActions/setPads',
)

const addPad = createAction<{ id: number; pad: IPad }>('projectsActions/addPad')

const updatePad = createAction<{ id: number; padId: number; name: string }>(
    'projectsActions/updatePad',
)

const deletePad = createAction<{ id: number; padId: number }>(
    'projectsActions/deletePad',
)

const setWells = createAction<{ id: number; padId: number; wells: IWell[] }>(
    'projectsActions/setWells',
)

const addWell = createAction<{ id: number; padId: number; well: IWell }>(
    'projectsActions/addWell',
)

const setIsOpenProjectsMenuStatus = createAction<boolean>(
    'projectsActions/setIsOpenProjectsMenuStatus',
)

const updateWell = createAction<{
    id: number
    padId: number
    wellId: number
    name: string
}>('projectsActions/updateWell')

const deleteWell = createAction<{
    id: number
    padId: number
    wellId: number
}>('projectsActions/deleteWell')

const setWellCores = createAction<{
    id: number
    padId: number
    wellId: number
    cores: ICore[]
}>('projectsActions/setWellCores')

const addWellCore = createAction<{
    id: number
    padId: number
    wellId: number
    core: ICore
}>('projectsActions/addWellCore')

const updateWellCore = createAction<{
    id: number
    padId: number
    wellId: number
    coreId: number
    name: string
}>('projectsActions/updateWellCore')

const deleteWellCore = createAction<{
    id: number
    padId: number
    wellId: number
    coreId: number
}>('projectsActions/deleteWellCore')

const setWellBores = createAction<{
    id: number
    padId: number
    wellId: number
    wellBores: IWellBore[]
}>('projectsActions/setWellBores')

const addWellBore = createAction<{
    id: number
    padId: number
    wellId: number
    wellBore: IWellBore
}>('projectsActions/addWellBore')

const updateWellBore = createAction<{
    id: number
    padId: number
    wellId: number
    wellBoreId: number
    name: string
}>('projectsActions/updateWellBore')

const deleteWellBore = createAction<{
    id: number
    padId: number
    wellId: number
    wellBoreId: number
}>('projectsActions/deleteWellBore')

const setWellBoreCores = createAction<{
    id: number
    padId: number
    wellId: number
    wellBoreId: number
    cores: ICore[]
}>('projectsActions/setWellBoreCores')

const addWellBoreCore = createAction<{
    id: number
    padId: number
    wellId: number
    wellBoreId: number
    core: ICore
}>('projectsActions/addWellBoreCore')

const updateWellBoreCore = createAction<{
    id: number
    padId: number
    wellId: number
    wellBoreId: number
    coreId: number
    name: string
}>('projectsActions/updateWellBoreCore')

const deleteWellBoreCore = createAction<{
    id: number
    padId: number
    wellId: number
    wellBoreId: number
    coreId: number
}>('projectsActions/deleteWellBoreCore')

const setIsCollpsedProjectEntityStatus = createAction<{
    type: 'project' | 'pad' | 'well' | 'wellbore' | 'core'
    data: {
        coreId?:number
        padId?: number
        wellId?: number
        projectId: number
        wellBoreId?: number
    }
    status: boolean
}>('projectsActions/setIsCollpsedProjectEntityStatus')

const updateProject = createAction<IProject>('projectsActions/updateProject')

export {
    setCurrentOrganization,
    setProjects,
    setProjectsLoading,
    addProject,
    deleteProject,
    updateProjectName,
    setPads,
    addPad,
    updatePad,
    deletePad,
    setWells,
    addWell,
    updateWell,
    deleteWell,
    setWellCores,
    addWellCore,
    updateProject,
    updateWellCore,
    deleteWellCore,
    setWellBores,
    addWellBore,
    updateWellBore,
    deleteWellBore,
    setWellBoreCores,
    addWellBoreCore,
    updateWellBoreCore,
    deleteWellBoreCore,
    setIsOpenProjectsMenuStatus,
    setIsCollpsedProjectEntityStatus,
}
