import React from 'react'
import { IIconProps } from '../../app/types/i-props'

export const EditIcon = ({
    className,
    onClick,
    width,
    height,
    viewBox,
    fill = '#2B506B',
}: IIconProps) => {
    return (
        <svg
            className={className}
            width={width ? width :"24"}
            height={height ? height : "24"}
            viewBox={viewBox ? viewBox :"0 0 24 24"}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"

            onClick={onClick}
        >
            <path
                d="M15 6L18 9M19.586 7.414L8.09793 18.9021C8.03385 18.9662 7.95356 19.0116 7.86564 19.0336L4.80845 19.7979C4.44226 19.8894 4.11057 19.5577 4.20211 19.1916L4.96641 16.1344C4.98839 16.0464 5.03385 15.9662 5.09793 15.9021L16.586 4.414C16.9611 4.03906 17.4697 3.82843 18 3.82843C18.5303 3.82843 19.0389 4.03906 19.414 4.414L19.586 4.586C19.9609 4.96106 20.1716 5.46967 20.1716 6C20.1716 6.53033 19.9609 7.03895 19.586 7.414Z"
                stroke={fill}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
