import React from 'react'

export const ClockIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.73684 16.7368L12.1144 12.8258C12.3732 12.6377 12.5262 12.337 12.5262 12.0171L12.5262 8.31579L12.5262 5.1579"
                stroke="#2B506B"
                strokeLinecap="round"
            />
            <circle cx="12" cy="12" r="9.5" stroke="#2B506B" />
        </svg>
    )
}
