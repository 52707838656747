import { Popover, PopoverProps } from 'antd'
import React, { ReactNode, useEffect, useState } from 'react'
import { getValidText } from '../../../utils/helpers/getValidText'
import './UiPopover.css'
import { PopoverContext } from './provider'

interface IProps extends PopoverProps {
    children?: ReactNode
    defaultValue?:string | number
    options?:IPopoverOption[]
    transformSelectedOption?: (label: string) => string
}

export interface IPopoverOption {
    label: string
    value: string | number
}

const UiPopover = (props: IProps) => {
    const [collapsed, setCollapsed] = useState(false)
    const [selectedOption, setSelectedOption] = useState<
        IPopoverOption | undefined
    >(undefined)

    useEffect(()=>{
        if(props.defaultValue!==undefined && props.options){
            setSelectedOption(props.options.find(el=>el.value===props.defaultValue))
        }
    },[props.defaultValue])

    return (
        <PopoverContext.Provider
            value={{
                collapsed,
                options:props.options || [],
                selectedOption,
                setCollapsed,
                setSelectedOption,
            }}
        >
            <Popover {...props}
            color={props.color ?? 'black'}
            >
                {
                    props.children ? props.children : (
                        <div className="mt-10">
                        <div
                            className="font-14-normal cursor-pointer flex-c-c"
                        >
                            <span className="scheduler__menu-span">
                                {props.transformSelectedOption
                                    ? props.transformSelectedOption(
                                          getValidText(selectedOption?.label),
                                      )
                                    : getValidText(selectedOption?.label)}
                            </span>
                        </div>
                    </div>

                    )
                }

            </Popover>
        </PopoverContext.Provider>
    )
}

export default UiPopover
