import { createAction } from '@reduxjs/toolkit'
import { ITask } from '../../app/types/models/tasks'
import { IStatus } from '../../app/types/models/tasks'
import { IPanelsObject } from '../../features/panels/Panels'

const setPanels = createAction<{ panels: IPanelsObject; panelsOrder: any[] }>(
    'panelsActions/setPanels',
)

const setPanelsWithoutStatus = createAction<{ panel: any }>(
    'panelsActions/setPanelsWithoutStatus',
)

const addPanel = createAction<{
    panel: any
    position: number | string
    panelsOrder: any
}>('panelsActions/addPanel')

const updatePanelsOrder = createAction<{
    panelsOrder: any
}>('panelsActions/updatePanelsOrder')

const deletePanel = createAction<{ position: number; panelsOrder: any[] }>(
    'panelsActions/deletePanel',
)

const addTasksToPanel = createAction<{
    tasks: ITask[]
    position: string | number
    tasksOrder: any
}>('panelsActions/addTasksToPanel')

const updateTasksOrder = createAction<{
    tasks: ITask[]
    position: number | string
    tasksOrder: any
}>('panelsActions/updateTasksOrder')

const editPanelName = createAction<{ position: number; name: string }>(
    'panelsActions/editPanelName',
)

const moveAllTasks = createAction<{
    currentPosition: number | string
    targetPosition: number | string
    tasksOrder: any
    tasks: ITask[]
}>('panelsActions/moveAllTasks')

const deletePanelTask = createAction<{
    position: number | string
    taskId: number
    status_position: number | string
}>('panelsActions/deletePanelTask')

const changePanelTask = createAction<{
    position: number | string
    task: ITask
}>('panelsActions/changePanelTask')

const moveTasksBetweenPanels = createAction<{
    startPosition: number | string
    finishPosition: number | string
    startPanel: IStatus
    finishPanel: IStatus
}>('panelsActions/moveTasksBetweenPanels')

export {
    setPanels,
    setPanelsWithoutStatus,
    addPanel,
    deletePanel,
    addTasksToPanel,
    updateTasksOrder,
    editPanelName,
    moveAllTasks,
    deletePanelTask,
    changePanelTask,
    moveTasksBetweenPanels,
    updatePanelsOrder,
}
