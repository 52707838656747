import { AxiosResponse } from 'axios'
import { instance } from '../app/api'
import { IOrganization } from '../app/types/models/i-organization'

export const organizationService = {
    getOrganizations: async () => {
        return await instance
            .get<any, AxiosResponse<any>>('organizations/organization/')
            .then((res) => {
                const organiations =
                    res.data && res.data?.length > 0
                        ? res.data.map((el) => ({
                              fullName: el?.full_name,
                              shortName: el?.short_name,
                              address: el?.address,
                              id: el?.id,
                          }))
                        : []
                return organiations as IOrganization[]
            })
    },
}
