import React from "react";

const StatusSortIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.5 6C17.5 5.72386 17.2761 5.5 17 5.5C16.7239 5.5 16.5 5.72386 16.5 6L17.5 6ZM16.6464 20.3536C16.8417 20.5488 17.1583 20.5488 17.3536 20.3536L20.5355 17.1716C20.7308 16.9763 20.7308 16.6597 20.5355 16.4645C20.3403 16.2692 20.0237 16.2692 19.8284 16.4645L17 19.2929L14.1716 16.4645C13.9763 16.2692 13.6597 16.2692 13.4645 16.4645C13.2692 16.6597 13.2692 16.9763 13.4645 17.1716L16.6464 20.3536ZM16.5 6L16.5 20L17.5 20L17.5 6L16.5 6Z"
                fill="#2B506B"
            />
            <path
                d="M6.5 18C6.5 18.2761 6.72386 18.5 7 18.5C7.27614 18.5 7.5 18.2761 7.5 18L6.5 18ZM7.35355 3.64645C7.15829 3.45118 6.84171 3.45118 6.64645 3.64645L3.46447 6.82843C3.2692 7.02369 3.2692 7.34027 3.46447 7.53553C3.65973 7.7308 3.97631 7.7308 4.17157 7.53553L7 4.70711L9.82843 7.53553C10.0237 7.7308 10.3403 7.7308 10.5355 7.53553C10.7308 7.34027 10.7308 7.02369 10.5355 6.82843L7.35355 3.64645ZM7.5 18L7.5 4L6.5 4L6.5 18L7.5 18Z"
                fill="#2B506B"
            />
        </svg>
    )
}

export default StatusSortIcon;
