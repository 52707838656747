import React, { ReactNode, useContext, useState } from 'react'
import UiInput from '../input/UiInput'
import { SearchIcon } from '../../../image_files/icons/SearchIcon'
import './UiPopoverList.css'
import { IPopoverOption } from './UiPopover'
import { PopoverContext } from './provider'

interface IProps {
    title?:string | ReactNode
    handleClickItem?: (param: IPopoverOption) => void
}

const UiPopoverContent = ({title,handleClickItem }: IProps) => {
    const {selectedOption,options,setSelectedOption} = useContext(PopoverContext)
    const [filteredOptions, setFilteredOptions] = useState<IPopoverOption[]>(
        options || [],
    )


    const searchHandler = (e) => {
        const value = e.target.value
        const searchedItems = options?.filter((f) => {
            if (e.target.value.length > 0) {
                return f['label']
                    .toLowerCase()
                    .includes(value.toLowerCase().trim())
            }
        })
        setFilteredOptions(value.length > 0 ? searchedItems : options)
    }

    const handleClickListItem = (param: IPopoverOption) => () => {
        setSelectedOption(param)
        if (handleClickItem) {
            handleClickItem(param)
        }
    }

    console.log('fiteredOptions', filteredOptions)

    if(title){
        return (
            <div
            className={
                'font-12-normal font-white popover-tooltip'
            }
        >
            {title}
        </div>
        )
    }

    return (
        <div className="popover-list">
            {options.length > 2 && (
                <div className='popover-list__input-wrap'>
                    <UiInput onChange={searchHandler} prefix={<SearchIcon />} />
                </div>
            )}
            <ul className="popover-list__options">
                {filteredOptions.map((el) => (
                    <li
                        key={el?.value}
                        onClick={handleClickListItem(el)}
                        className={`font-14-normal cursor-pointer ${
                            selectedOption?.value === el?.value
                                ? 'popover-list__item-selected'
                                : ''
                        }`}
                    >
                        <span>{el.label}</span>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default UiPopoverContent
