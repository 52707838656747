import { Popover } from 'antd'
import { TooltipPlacement } from 'antd/es/tooltip'
import React, { ReactElement, memo, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PriorityTypes } from '../../app/types/enums'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { PlusIcon } from '../../image_files/icons/PlusIcon'
import { priorityOptions } from '../../utils/priority-options'
import './createPanel.css'
import UiPopover from '../ui/popover/UiPopover'
import UiPopoverContent from '../ui/popover/UiPopoverContent'
import { getValidText } from '../../utils/helpers/getValidText'

interface IProps {
    prioritySelect?: any
    title?: string
    panelTitle?: string
    placeholder?: string
    popoverPlacement?: TooltipPlacement
    className?: string
    topPanelClassName?: string
    popoverClassName?: string
    panelId?: number
    btnClassName?: string
    statusId?: number | string
    panelIcon?: ReactElement
    lengthLimit?: number
    addTask: (value: string, status: string) => void
}

export const CreatePanel = memo<IProps>(
    ({
        addTask,
        prioritySelect,
        title,
        panelTitle,
        placeholder,
        className,
        topPanelClassName,
        panelId,
        btnClassName,
        statusId,
        panelIcon,
        lengthLimit,
    }) => {
        const panelBottomRef = useRef()
        const [priorityStatus, setPriorityStatus] = useState(
            PriorityTypes.medium,
        )
        const [inputValue, setInputValue] = useState('')
        const [openMenu, setOpenMenu] = useState(false)
        const [error, setError] = useState('')
        const { t } = useTranslation()

        const handleClickInside = () => {
            setInputValue('')
            setOpenMenu(false)
        }

        useOnClickOutside([panelBottomRef], handleClickInside)

        const onChangeHandler = (e) => {
            setInputValue(e.currentTarget.value)
            if (inputValue.length >= 3 && inputValue.length <= lengthLimit) {
                setError('')
            }
        }
        const onKeyPressHandler = (e) => {
            if (e.charCode === 13) {
                if (inputValue.trim().length >= 3) {
                    if (lengthLimit && inputValue.length <= lengthLimit) {
                        setError('')
                        addTask(inputValue, priorityStatus)
                        setOpenMenu(false)
                        setInputValue('')
                    } else {
                        setError(t('max-symblos-length-message', { value: 15 }))
                        setInputValue(inputValue.trim())
                    }
                    if (!lengthLimit) {
                        setError('')
                        addTask(inputValue, priorityStatus)
                        setOpenMenu(false)
                        setInputValue('')
                    }
                } else {
                    setError(t('min-symblos-length-message', { value: 3 }))
                    setInputValue(inputValue.trim())
                }
            }
        }

        const onClickHandler = () => {
            setOpenMenu(!openMenu)
        }

        useEffect(() => {
            if (open) {
                setError('')
            }
        }, [open])

        const showPriorityOptions = () => {
            const list = priorityOptions.map((el, i) => (
                <UiPopover content={<UiPopoverContent title={t(el)}  />}>
                    <li
                        className={`create-panel-priority create-panel-priority-${el} ${
                            priorityStatus === el
                                ? 'create-panel-priority-current'
                                : ''
                        }`}
                        onClick={() => setPriorityStatus(el)}
                        key={i}
                    />
                </UiPopover>
            ))

            return <ul className="create-panel-priority-list">{list}</ul>
        }

        return (
            <div
                className={
                    className ? `createPanel ${className}` : 'createPanel'
                }
            >
                <UiPopover
                    placement={'bottomLeft'}
                    open={openMenu}
                    color='white'
                    content={
                        <div
                            className="createPanel__bottom"
                            style={panelTitle ? { width: '180px' } : {}}
                            ref={panelBottomRef}
                        >
                            {panelTitle && (
                                <h4 className="createPanel__bottom-title">
                                    {panelTitle.length > 1 ? panelTitle : ''}
                                </h4>
                            )}
                            <div
                                className={
                                    !prioritySelect
                                        ? `createPanel__bottom-inner createPanel__bottom-withMargin`
                                        : 'createPanel__bottom-inner'
                                }
                                style={panelTitle ? { marginLeft: 0 } : {}}
                            >
                                {prioritySelect && showPriorityOptions()}
                                <textarea
                                    value={inputValue}
                                    placeholder={placeholder}
                                    onChange={onChangeHandler}
                                    onKeyPress={onKeyPressHandler}
                                    className="createPanel__input"
                                />
                            </div>
                            <div className="createPanel__bottom-error">
                                {error}
                            </div>
                        </div>
                    }
                >
                    <div
                        className={
                            topPanelClassName
                                ? `createPanel__top ${topPanelClassName}`
                                : 'createPanel__top'
                        }
                    >
                        <button
                            className={`flex-c-c createPanel__top-btn ${getValidText(
                                btnClassName,
                            )} `}
                            onClick={(e) => {
                                e.stopPropagation()
                                onClickHandler()
                            }}
                            id={String(panelId)}
                        >
                            {panelIcon ? (
                                panelIcon
                            ) : (
                                <PlusIcon className="createPanel__addIcon" />
                            )}
                        </button>
                        <h3>{title}</h3>
                    </div>
                </UiPopover>
            </div>
        )
    },
)
