import { createContext, SetStateAction } from "react"
import { IPopoverOption } from "./UiPopover"

export const PopoverContext = createContext<{
    collapsed:boolean,
    options:IPopoverOption[],
    selectedOption:IPopoverOption | undefined,

    setCollapsed: (data: SetStateAction<boolean>) => void
    setSelectedOption: (data: SetStateAction<IPopoverOption | undefined>) => void
}>({
    collapsed:false,
    options:[],
    selectedOption:undefined,
    setCollapsed: () => undefined,
    setSelectedOption: () => undefined,
})
