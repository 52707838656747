import React from "react";
import { IIconProps } from "../../app/types/i-props";

const DescriptionIcon = ({stroke='#828282'}:IIconProps) => {
    return (
        <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.58334 2.4375H11.375"
                stroke={stroke}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.58334 5.14583H11.375"
                stroke={stroke}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1.625 7.85417H11.375"
                stroke={stroke}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1.625 10.5625H11.375"
                stroke={stroke}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.14583 4.56626V3.01709C5.14583 2.41042 4.90208 2.16667 4.29 2.16667H2.74625C2.13958 2.16667 1.89583 2.41042 1.89583 3.01709V4.56084C1.89583 5.17292 2.13958 5.41667 2.74625 5.41667H4.29C4.90208 5.41667 5.14583 5.17292 5.14583 4.56626Z"
                stroke={stroke}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default DescriptionIcon;