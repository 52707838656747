import React, { FC, useEffect, useState } from 'react'
import { RoadMapDetalizationTypes } from '../../../../app/types/enums'
import {
    CalendarPriorityColors,
    ICalendarGroup,
} from '../../../../app/types/i-roadmap-calendar'
import { ITask } from '../../../../app/types/models/tasks'
import { CalendarDetalizationRow } from '../../../../shared/calendarDetalizationRow/CalendarDetalizationRow'
import {
    getMonthDetalization,
    getQuarterDetalization,
    getYearDetalization,
} from '../../../../shared/calendarDetalizationRow/services/services'
import { getValidText } from '../../../../utils/helpers/getValidText'
import './RoadMapCalendarItem.css'
import { getGroupsListByMonthDetalization } from './services/get-groups-by-month-detalization'
import { getGroupsByQuarterDetalization } from './services/get-groups-by-quarter-detlization'
import { getGroupsByYearDetalization } from './services/get-groups-by-year-detalization'

interface IProps {
    item: ITask
    blockHeight?: number
    position?:number
    detalizationData: {
        detalization: RoadMapDetalizationTypes
        startPeriod: Date | string
        endPeriod: Date | string
    }
}

const RoadMapCalendarItem: FC<IProps> = ({
    item,
    position,
    blockHeight,
    detalizationData,
}) => {
    const [calendarGroups, setCalendarGroups] = useState<ICalendarGroup[]>([])
    const [initalGroups, setInitialGroups] = useState<ICalendarGroup[]>([])

    const changeData = () => {
        let result = [] as ICalendarGroup[]

        if (detalizationData.detalization === RoadMapDetalizationTypes.MONTH) {
            result = getGroupsListByMonthDetalization(initalGroups, {
                startDate: getValidText(item?.due_date_start),
                endDate: getValidText(item?.due_date_end),
            })
        } else if (
            detalizationData.detalization === RoadMapDetalizationTypes.QUARTER
        ) {
            result = getGroupsByQuarterDetalization(initalGroups, {
                startDate: getValidText(item?.due_date_start),
                endDate: getValidText(item?.due_date_end),
            })
        } else if (
            detalizationData.detalization === RoadMapDetalizationTypes.YEAR
        ) {
            result = getGroupsByYearDetalization(initalGroups, {
                startDate: getValidText(item?.due_date_start),
                endDate: getValidText(item?.due_date_end),
            })
        } else {
            result = initalGroups
        }
        setCalendarGroups(result)
    }

    useEffect(() => {
        let list: ICalendarGroup[] = []
        switch (detalizationData.detalization) {
            case RoadMapDetalizationTypes.MONTH:
                list = getMonthDetalization({
                    startPeriod: detalizationData.startPeriod,
                    endPeriod: detalizationData.endPeriod,
                    priorityColor:
                        CalendarPriorityColors[
                            getValidText(item?.priority).toLocaleUpperCase()
                        ],
                })
                break
            case RoadMapDetalizationTypes.QUARTER:
                list = getQuarterDetalization({
                    startPeriod: detalizationData.startPeriod,
                    endPeriod: detalizationData.endPeriod,
                    priorityColor:
                        CalendarPriorityColors[
                            getValidText(item?.priority).toLocaleUpperCase()
                        ],
                })
                break
            case RoadMapDetalizationTypes.YEAR:
                list = getYearDetalization({
                    startPeriod: detalizationData.startPeriod,
                    endPeriod: detalizationData.endPeriod,
                    priorityColor:
                        CalendarPriorityColors[
                            getValidText(item?.priority).toLocaleUpperCase()
                        ],
                })
                break
            default:
                break
        }
        if (list.length > 0) {
            setInitialGroups(list)
        }
    }, [detalizationData])

    useEffect(() => {
        if (initalGroups.length > 0) {
            changeData()
        }
    }, [item, initalGroups])

    return (
        <CalendarDetalizationRow
            type="item"
            height={blockHeight}
            position={position}
            data={calendarGroups}
            detalizationData={detalizationData}
        />
    )
}

export default RoadMapCalendarItem
