import React, { FC } from 'react'

interface IProps {
    style?: object
    className?: string
}

export const LightIcon: FC<IProps> = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.86957 16L8.3657 12.5588C8.15234 12.0705 7.86018 11.6207 7.50091 11.2272L7.41934 11.1378C6.24083 9.8471 5.88279 8.00583 6.49172 6.36751V6.36751C6.82267 5.4771 7.41758 4.70896 8.19689 4.1658L8.43172 4.00214C9.36774 3.34976 10.4813 3 11.6222 3H12.3043H13.137C14.1784 3 15.1932 3.32896 16.0366 3.93992L16.1335 4.0101C17.0159 4.64934 17.6438 5.58018 17.9058 6.63784V6.63784C18.277 8.13588 17.8749 9.72032 16.8343 10.8601L16.7263 10.9783C16.2199 11.533 15.8473 12.1964 15.6372 12.9175L14.7391 16"
                stroke="#2B506B"
                strokeLinecap="round"
            />
            <path
                d="M10.5 20H13.5M10 18H14"
                stroke="#2B506B"
                strokeLinecap="round"
            />
        </svg>
    )
}
