import { Select, SelectProps } from 'antd'
import React, { ReactNode, useEffect, useState } from 'react'
import { getValidText } from '../../../utils/helpers/getValidText'
import TagsArrowIcon from '../../../image_files/icons/TagsArrowIcon'
import './UISelect.css';
type IProps = SelectProps & {
    label?: string | ReactNode
}

const UISelect = (props: IProps) => {
    const [isOpenStatus,setIsOpenStatus] = useState(false)

    const handleClick = (e) =>{
        setIsOpenStatus(prev=>!prev)
        props.onClick && props.onClick(e)
        
    }
    const handleBlur = (e) =>{
        setIsOpenStatus(false)
        props.onBlur && props.onBlur(e)
    }

    useEffect(()=>{
        if(props.open!==undefined){
            setIsOpenStatus(props.open)
        }
    },[open])

    return (
        <div className="field-wrap">
            {props?.label && (
                <div className="font-14-normal field-wrap-label">
                    {props?.label}
                </div>
            )}

            <div className={'select-wrapper'}>
                <TagsArrowIcon
                    className={`select-wrapper-moved-icon select-wrapper-moved-icon-${
                        isOpenStatus ? 'opened' : 'closed'
                    }`}
                />
                <Select
                    {...props}
                    optionLabelProp={''}
                    className={`select ${getValidText(props?.className)}`}
                    suffixIcon={<></>}
                    onBlur={handleBlur}
                    onClick={handleClick}
                />
            </div>
        </div>
    )
}

export default UISelect;
