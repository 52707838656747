export const textEditorICons = {
    bold: `
        <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.70841 5.83329C8.11644 5.58483 8.46077 5.24447 8.71397 4.83937C8.96716 4.43426 9.12221 3.9756 9.16675 3.49996C9.17448 3.06986 9.0974 2.64246 8.93992 2.24215C8.78243 1.84185 8.54763 1.47649 8.24893 1.16695C7.95022 0.857407 7.59345 0.609744 7.19901 0.438106C6.80457 0.266468 6.38018 0.174219 5.95008 0.166626H0.541748V11.8333H6.37508C6.78437 11.8289 7.1888 11.744 7.56527 11.5833C7.94173 11.4227 8.28287 11.1894 8.56919 10.8969C8.8555 10.6044 9.0814 10.2584 9.23398 9.87855C9.38655 9.49874 9.46282 9.09259 9.45841 8.68329V8.58329C9.4587 8.00589 9.29418 7.44039 8.98419 6.95325C8.67419 6.46612 8.2316 6.07758 7.70841 5.83329ZM2.20841 1.83329H5.70841C6.06356 1.8223 6.41357 1.92019 6.71148 2.11384C7.00939 2.30748 7.24095 2.5876 7.37508 2.91663C7.51081 3.35645 7.46691 3.83212 7.25298 4.23967C7.03906 4.64722 6.6725 4.95352 6.23341 5.09163C6.0629 5.1416 5.8861 5.16685 5.70841 5.16663H2.20841V1.83329ZM6.04175 10.1666H2.20841V6.83329H6.04175C6.39689 6.8223 6.7469 6.92019 7.04481 7.11384C7.34272 7.30748 7.57428 7.5876 7.70841 7.91663C7.84414 8.35645 7.80025 8.83212 7.58632 9.23967C7.37239 9.64722 7.00583 9.95352 6.56675 10.0916C6.39623 10.1416 6.21943 10.1669 6.04175 10.1666Z" fill="#364F6B"/>
        </svg>
    `,
    italic: `
        <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.79989 3.49996H4.46655L2.63322 11.8333H0.966553L2.79989 3.49996ZM4.19989 0.166626C4.03507 0.166626 3.87395 0.2155 3.73691 0.307068C3.59987 0.398636 3.49306 0.528785 3.42999 0.681057C3.36691 0.833328 3.35041 1.00088 3.38256 1.16253C3.41472 1.32419 3.49409 1.47267 3.61063 1.58921C3.72717 1.70576 3.87566 1.78513 4.03731 1.81728C4.19896 1.84943 4.36652 1.83293 4.51879 1.76986C4.67106 1.70679 4.80121 1.59998 4.89278 1.46293C4.98434 1.32589 5.03322 1.16478 5.03322 0.999959C5.03322 0.778946 4.94542 0.566984 4.78914 0.410704C4.63286 0.254424 4.4209 0.166626 4.19989 0.166626Z" fill="#364F6B"/>
        </svg>
    `,
    underline: `
        <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.8334 12.6666V14.3333H0.166748V12.6666H11.8334ZM9.33341 7.01246C9.30597 7.56245 9.14271 8.09707 8.8582 8.56855C8.57369 9.04003 8.17681 9.43368 7.70302 9.71433C7.22922 9.99497 6.69328 10.1539 6.14309 10.1768C5.5929 10.1997 5.04559 10.086 4.55008 9.84579C3.97895 9.59871 3.49459 9.18674 3.15905 8.66265C2.82352 8.13857 2.6521 7.52624 2.66675 6.90413V0.170793H1.00008V7.01246C1.02827 7.79697 1.24079 8.56381 1.62043 9.25092C2.00007 9.93804 2.53616 10.5261 3.18531 10.9675C3.83445 11.4089 4.57842 11.6913 5.35698 11.7918C6.13553 11.8922 6.92681 11.808 7.66675 11.5458C8.65101 11.2177 9.50496 10.5843 10.1044 9.73749C10.7039 8.89072 11.0177 7.8748 11.0001 6.83746V0.170793H9.33341V7.01246ZM9.33341 0.166626H11.0001H9.33341ZM2.66675 0.166626H1.00008H2.66675Z" fill="#364F6B"/>
        </svg>
        `,
    strike:`
        <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.5 6.16913H15.5V7.41913H11.8618C12.0592 7.83348 12.1589 8.28762 12.153 8.74654C12.1647 9.18195 12.0713 9.61376 11.8806 10.0054C11.69 10.397 11.4077 10.7369 11.0578 10.9963C10.2149 11.5894 9.19813 11.884 8.16876 11.8333C7.41663 11.8384 6.67183 11.6854 5.98263 11.3842C5.35323 11.1231 4.80764 10.6941 4.40543 10.144C4.0351 9.61889 3.83969 8.99039 3.84697 8.34783V8.25334H4.08632V8.25243H4.3709V8.25334H5.51667V8.34779C5.50361 8.64062 5.56151 8.93226 5.68544 9.19788C5.80937 9.4635 5.99566 9.69524 6.22844 9.87337C6.79295 10.2753 7.47667 10.4748 8.16874 10.4394C8.77969 10.4794 9.38706 10.3196 9.89929 9.98425C10.0892 9.84384 10.2418 9.65901 10.3436 9.44594C10.4455 9.23288 10.4936 8.99809 10.4837 8.76213C10.497 8.53416 10.4549 8.30637 10.361 8.09822C10.2671 7.89006 10.1242 7.70773 9.94454 7.5668C9.87092 7.51344 9.79444 7.46414 9.71545 7.41909H0.5V6.16913ZM11.6206 1.88319C11.2693 1.33736 10.7726 0.900504 10.1863 0.621844C9.5328 0.310518 8.81606 0.154728 8.09227 0.166677C7.083 0.123362 6.09069 0.436712 5.28933 1.05179C4.94529 1.31749 4.66778 1.65958 4.47873 2.05101C4.28968 2.44244 4.19427 2.87248 4.20005 3.30714C4.19714 3.71905 4.28882 4.12613 4.46805 4.49702H6.63195C6.56247 4.45231 6.47747 4.40888 6.42137 4.36315C6.25182 4.23808 6.11418 4.07475 6.01965 3.88646C5.92512 3.69816 5.87636 3.49021 5.87735 3.27952C5.86478 3.03901 5.91035 2.79902 6.01022 2.57986C6.11008 2.3607 6.26129 2.16884 6.45103 2.02052C6.93111 1.68757 7.50907 1.52546 8.09227 1.56016C8.72638 1.51985 9.35256 1.71868 9.84727 2.11741C10.0542 2.31224 10.2162 2.54974 10.3221 2.81345C10.428 3.07716 10.4753 3.36076 10.4606 3.64456V3.73907H12.1299V3.64456C12.1327 3.02072 11.9559 2.40926 11.6206 1.88319Z" fill="#364F6B"/>
        </svg>
    `,
    ordered:`
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.5 16H4.5V16.5H3.5V17.5H4.5V18H2.5V19H5.5V15H2.5V16ZM3.5 9H4.5V5H2.5V6H3.5V9ZM2.5 11H4.3L2.5 13.1V14H5.5V13H3.7L5.5 10.9V10H2.5V11ZM7.5 6V8H21.5V6H7.5ZM7.5 18H21.5V16H7.5V18ZM7.5 13H21.5V11H7.5V13Z" fill="#364F6B"/>
        </svg>
    `,
    bullet:`
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 10.5C3.2 10.5 2.5 11.2 2.5 12C2.5 12.8 3.2 13.5 4 13.5C4.8 13.5 5.5 12.8 5.5 12C5.5 11.2 4.8 10.5 4 10.5ZM4 5.5C3.2 5.5 2.5 6.2 2.5 7C2.5 7.8 3.2 8.5 4 8.5C4.8 8.5 5.5 7.8 5.5 7C5.5 6.2 4.8 5.5 4 5.5ZM4 15.5C3.2 15.5 2.5 16.2 2.5 17C2.5 17.8 3.2 18.5 4 18.5C4.8 18.5 5.5 17.8 5.5 17C5.5 16.2 4.8 15.5 4 15.5ZM7.5 6V8H21.5V6H7.5ZM7.5 18H21.5V16H7.5V18ZM7.5 13H21.5V11H7.5V13Z" fill="#364F6B"/>
        </svg>
    `
}
