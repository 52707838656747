import React, { FC, useEffect, useState } from 'react'
import './commentItem.css'
import { useSelector } from 'react-redux'
import { userSelectors } from '../../../../store/user'

interface IProps {
    text: string
    name: string
    date: string
    userId: number
}

const CommentItem: FC<IProps> = ({ text, name, date, userId }) => {
    const storageMembers = JSON.parse(sessionStorage.getItem('members'))
    const [currentColor, setCurrentColor] = useState('#AD4FC5')

    const user = useSelector(userSelectors.selectUser)

    useEffect(() => {
        if(storageMembers && storageMembers?.length>0){
            storageMembers.forEach((s) => {
                if (s?.user?.id === userId) {
                    setCurrentColor(s?.color)
                }
            })
        }
    }, [storageMembers])

    return (
        <div className="scheduler__commentItem">
            <div className="flex-align-center">
                {user && user?.avatar ? (
                    <img
                        className={'commentItem__img'}
                        src={user?.avatar.small}
                        alt="user image"
                    />
                ) : (
                    <div
                        className="scheduler__commentItem-phone"
                        style={{ backgroundColor: currentColor }}
                    ></div>
                )}
                <div className="font-14-normal scheduler__commentItem-name">{name}</div>
                <div className="font-14-normal scheduler__commentItem-date">{date}</div>
            </div>
            <div className="font-14-normal scheduler__commentItem-bottom">{text}</div>
        </div>
    )
}

export { CommentItem }
