import React, {FC, useState} from 'react';
import SelectInLog from "../../shared/select/Select";
import {useTranslation} from "react-i18next";
import {ICopyLinkProps} from "./types";
import {RoleTypes} from "../../app/types/enums";
import {Skeleton} from "antd";
import UISelect from '../../shared/ui/select/UlSelect';

const CopyLink: FC<ICopyLinkProps> = (
    {roleOptions, copyLink, me, isLoading}
) => {
    const {t} = useTranslation()
    const [copyLinkRole, setCopyLinkRole] = useState(RoleTypes.editor)

    const copyLinkHandler = () => {
        if (copyLink) {
            copyLink(me?.role === RoleTypes.member ? RoleTypes.member : copyLinkRole)
        }
    }

    const getValidRoleOptions = () => {
        if (me?.role === RoleTypes.admin || me?.role === RoleTypes.editor) {
            return roleOptions.slice(0, 2)
        } else {
            return roleOptions.slice(0, 1)
        }
    }
    return (
        <>
            <div className={'font-14-normal'}>
                {t('access-everyone-who-has-link')}
            </div>
            {
                isLoading
                    ? <Skeleton.Input className={'copyLink-skeleton'} active/>
                    : <div className={'mt-10 share-project-copy font-14-normal cursor-pointer'} onClick={copyLinkHandler}>
                        <div></div>
                        <span>{t('copy-link')}</span>

                        {
                            getValidRoleOptions()?.length > 1 ?
                                <button className={'share-project-copy-btn'} onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                }}>
                                    <UISelect defaultValue={'editor'}
                                                 options={getValidRoleOptions()}
                                                 onChange={e => setCopyLinkRole(e)}/>
                                </button>
                                : <div className={'font-18-normal shareProject-copylink-text'}>
                                    {getValidRoleOptions()[0]?.label}
                                </div>
                        }
                    </div>
            }

        </>
    );
};

export default CopyLink;