export const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'rgba(219, 218, 255, 0.5)' : 'transparent',
})

export const getItemStyle = (isDragging, draggableStyle) => {
    const validBackground = isDragging ? {
        background: 'var(--drag-item-phone)',
    } : {}
    return {
        userSelect: 'none',
        ...draggableStyle, 
        ...validBackground,
    }
}

