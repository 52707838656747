import React, { useEffect, useLayoutEffect, useState } from 'react'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import useDebounce from '../../hooks/useDebounce'
import './TextEditor.css'
import { textEditorICons } from './icon-codes'
import './index.css'

var Font = Quill.import('formats/font')
Font.whitelist = ['Montserrat', 'Roboto', 'Raleway', 'Lato', 'Rubik']
ReactQuill.Quill.register(Font, true)

const modules = {
    toolbar: [
        [{ font: Font.whitelist }],
        [{ size: ['small', 'large', 'huge'] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ align: [] }],
        [{ color: [] }, { background: [] }],
        ['link'],
        [{ color: [] }, { background: [] }]['clean'],
    ],
}

const formats = [
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'background',
    'list',
    'link',
    'color',
    'background',
]

// Форматы редактора
export const UITextEditor = ({
    initialText,
    handleChange,
}: {
    initialText: string
    handleChange?: (data: any) => void
}) => {
    const [value, setValue] = useState(initialText)
    const debouncedValue = useDebounce(value, 500)

    const icons = Quill.import('ui/icons')
    if (icons) {
        for (let key in textEditorICons) {
            icons[key] = textEditorICons[key]
        }
    }

    useLayoutEffect(() => {
        setValue(initialText)
    }, [initialText])

    useEffect(() => {
        if (value !== initialText) {
            handleChange && handleChange(value)
        }
    }, [debouncedValue])

    return (
        <div>
            <ReactQuill
                value={value}
                onChange={setValue}
                modules={modules}
                formats={formats}
                theme="snow"
            />
        </div>
    )
}
