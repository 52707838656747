import {
    ICalendarGroup,
    ICalendarItem,
} from '../../../../../app/types/i-roadmap-calendar'
import { generateQuarterInterval, getIsHasMonthInQuarter, getQuarterDays } from './get-quarter-percentage'
import { getStartEndDate } from './get-start-end-date'

// const month = {
//     1: [0, 1, 2], // Январь, Февраль, Март
//     2: [3, 4, 5], // Апрель, Май, Июнь
//     3: [6, 7, 8], // Июль, Август, Сентябрь
//     4: [9, 10, 11], // Октябрь, Ноябрь, Декабрь
// }

const monthList = [
    [0,3,6,9],
    [1,4,7,10],
    [2,5,8,11],
] 

const getNowDate = (data: ICalendarItem,year:number) => {
    const nowYear = new Date().getFullYear()
    const nowMonth = new Date().getMonth()
    const nowDay = new Date().getDate()
    const quarter = data.value?.orderIndex || 0
     
    let days = data?.days || []
 
    if (year === nowYear && getIsHasMonthInQuarter(quarter, nowMonth)) {
        let targetIndex = 0;
        monthList.forEach((el,i)=>{
            if(el.includes(nowMonth)){
                targetIndex = i
            }
        })
        const monthDays = getQuarterDays(year, quarter + 1)[targetIndex]
        days = Array(monthDays).fill('').map((_,i)=>i+1)     
        const targetPosition = days.indexOf(nowDay)
        return ((targetPosition / days.length)/2) * 100   
    } else return null
}

export function getDaysInQuarter(year, quarter) {
    // Определяем начальный месяц квартала (месяцы в JS начинаются с 0)
    const startMonth = (quarter - 1) * 3

    let daysInQuarter = 0

    // Проходим по каждому месяцу квартала и суммируем дни
    for (let i = 0; i < 3; i++) {
        const daysInMonth = new Date(year, startMonth + i + 1, 0).getDate()
        daysInQuarter += daysInMonth
    }

    return new Array(daysInQuarter).fill('').map((d, i) => i + 1)
}

export const getGroupsByYearDetalization = (
    calendarGroups: ICalendarGroup[],
    data: {
        startDate: string | Date
        endDate: string | Date
    },
) => {
    const { startDay, startMonth, startYear, endDay, endMonth, endYear } =
        getStartEndDate(data)

    const getQuarter = (quarter: ICalendarItem, interval: [number, number],year:number) => {
        return {
            ...quarter,
            value: {
                ...quarter.value,
                interval: interval,
                nowDate:getNowDate(quarter,year)
            },
        }
    }

    return calendarGroups.map((year) => {
        const currentYear = year?.year

        if (currentYear > startYear && currentYear < endYear) {
            return {
                ...year,
                list: year.list.map((quarter) => {
                    return getQuarter(quarter, [0, 100],currentYear)
                }),
            }
        } else if (currentYear === startYear && currentYear < endYear) {
            return {
                ...year,
                list: year.list.map((quarter) => {
                    const quarterIndex = quarter.value.orderIndex
                    const { startPercent, endPercent } =
                        generateQuarterInterval({
                            startDay,
                            endDay: 31,
                            startMonth,
                            endMonth: 11,
                            quarter: quarterIndex,
                            year: year?.year,
                        })
                    return getQuarter(quarter, [startPercent, endPercent],currentYear)
                }),
            }
        } else if (currentYear > startYear && currentYear === endYear) {
            return {
                ...year,
                list: year.list.map((quarter) => {
                    const quarterIndex = quarter.value.orderIndex
                    const { startPercent, endPercent } =
                        generateQuarterInterval({
                            startDay: 1,
                            endDay,
                            startMonth: 0,
                            endMonth,
                            quarter: quarterIndex,
                            year: year?.year,
                        })
                    return getQuarter(quarter, [startPercent, endPercent],currentYear)
                }),
            }
        } else if (currentYear > endYear || currentYear < startYear) {
            return year
        } else {
            return {
                ...year,
                list: year.list.map((quarter) => {
                    const quarterIndex = quarter.value.orderIndex
                    const { startPercent, endPercent } =
                        generateQuarterInterval({
                            startDay,
                            endDay,
                            startMonth,
                            endMonth,
                            quarter: quarterIndex,
                            year: year?.year,
                        })
                    return getQuarter(quarter, [startPercent, endPercent],currentYear)
                }),
            }
        }
    }) as ICalendarGroup[]
}
