import React from 'react'
import { IIconProps } from '../../app/types/i-props'
import { getValidText } from '../../utils/helpers/getValidText'

const TeamIcon = ({ className }: IIconProps) => {
    return (
        <svg
            fill="none"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            className={getValidText(className)}
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="12.5" cy="7.5" r="5" stroke="#2B506B" />
            <circle cx="6.5" cy="18.5" r="3" stroke="#2B506B" />
            <circle cx="18" cy="18" r="3.5" stroke="#2B506B" />
            <path
                d="M7.5 15.5L7.55413 15.3917C7.84631 14.8074 8.28594 14.3094 8.82954 13.947V13.947C9.27148 13.6523 9.77091 13.4548 10.2948 13.3675L11.596 13.1507C12.1937 13.051 12.8045 13.0609 13.3986 13.1797L14.6749 13.435C14.8912 13.4782 15.103 13.5412 15.3079 13.6231L15.5441 13.7176C16.1624 13.965 16.6766 14.4178 17 15V15"
                stroke="#2B506B"
                strokeLinecap="round"
            />
        </svg>
    )
}

export default TeamIcon
