import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IProject } from '../../app/types/models/project'
import { AddProjectRequest } from '../../app/types/request'
import { projectsService } from '../../services/projects-service.'
import { wellsService } from '../../services/wells-service'
import { handleAsyncServerNetworkError } from '../../utils/helpers/errors/handleAsyncError'
import { setAppStatus } from '../app/actions'
import {
    selectCurrentPad,
    setCurrentWells,
    setProject,
} from '../currentProject/actions'
import {
    addProject,
    deleteProject,
    setCurrentOrganization,
    setProjects,
    setProjectsLoading,
    updateProject,
    updateProjectName
} from './actions'
import { getDataLoggingActions } from './data-logging-actions'

export interface InitialState {
    projects: IProject[]
    currentOrganization:null | number | string
    loading: boolean
    isOpenProjectsMenu: boolean
}

const initialState: InitialState = {
    projects: [],
    loading: false,
    currentOrganization:null,
    isOpenProjectsMenu: true,
}

const createProject = createAsyncThunk<
    void,
    { values: AddProjectRequest; onSuccess?: Function },
    { rejectValue: string }
>('projects/createProjectAsync', async ({ values, onSuccess }, thunkAPI:any) => {
    try {
        thunkAPI.dispatch(setAppStatus({ status: 'loading' }))
        const projectRes = await projectsService.addNewProject(values)

        if(values?.organization === thunkAPI.getState()?.projects?.currentOrganization){

            const padRes = await projectsService.addPad(projectRes.id, {
                name: 'pad 1',
            })
    
            const wellRes = await wellsService.addWell(projectRes.id, {
                name: 'well 1',
                well_pad: padRes?.id,
            })
    
            padRes.wells = [wellRes]
    
            thunkAPI.dispatch(
                addProject({ project: { ...projectRes, pads: [padRes] } }),
            )
            thunkAPI.dispatch(
                setProject({ project: { ...projectRes, pads: [padRes] } }),
            )
    
            thunkAPI.dispatch(selectCurrentPad(padRes))
            thunkAPI.dispatch(setCurrentWells({ wells: [wellRes] }))
            onSuccess && onSuccess(projectRes?.id)
        }

        thunkAPI.dispatch(setAppStatus({ status: 'succeeded' }))

    } catch (e: any) {
        return handleAsyncServerNetworkError(e, thunkAPI)
    }
})

const getProjects = createAsyncThunk<
    IProject[],
    {
        onSuccess?:(res?:IProject[])=>void
        isSaveProjects?:boolean,
        params?:{
            organization?:number | string
        }
    },
    { rejectValue: string }
>('projects/getProjectsAsync', async ({isSaveProjects=true,params, onSuccess}, thunkAPI) => {
    try {
        thunkAPI.dispatch(setAppStatus({ status: 'loading' }))
        const res = await projectsService.getProjects(params)
        thunkAPI.dispatch(setAppStatus({ status: 'succeeded' }))
        if(isSaveProjects){
            thunkAPI.dispatch(setProjects({projects:res}))
        }
        onSuccess && onSuccess(res)
        if(params?.organization!==undefined){
            thunkAPI.dispatch(setCurrentOrganization(params.organization))
        }
    } catch (e) {
        return handleAsyncServerNetworkError(e, thunkAPI)
    }
})

export const asyncActions = { createProject, getProjects }

export const slice = createSlice({
    name: 'projects',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setCurrentOrganization, (state, action) => {
            state.currentOrganization = action.payload
        })
        builder.addCase(setProjectsLoading, (state, action) => {
            state.loading = action.payload.loading
        })
        builder.addCase(setProjects, (state, action) => {
            state.projects = action.payload.projects
        })
        builder.addCase(addProject, (state, action) => {
            state.projects = [action.payload.project, ...state.projects]
        })
        builder.addCase(updateProject, (state, action) => {
            state.projects = state.projects.map((el) =>
                el.id === action.payload?.id ? action.payload : el,
            )
        })
        builder.addCase(deleteProject, (state, action) => {
            state.projects = state.projects.filter(
                (item) => item?.id !== action.payload.id,
            )
        })
        builder.addCase(updateProjectName, (state, action) => {
            state.projects = state.projects.map((item) =>
                item.id === action.payload.id
                    ? { ...item, name: action.payload.name }
                    : item,
            )
        })
        {
            getDataLoggingActions(builder)
        }
    },
})
