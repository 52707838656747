import React from 'react'
import { useState } from 'react'
import { getValidText } from '../../utils/helpers/getValidText'
import './Tabs.css'
import { Tabs } from 'antd'

const UiTabs = ({
    items,
    className,
    activeKey,
    handleChange,
}: {
    items: { label: string; key: string; children: React.ReactNode }[]
    className?: string,
    activeKey?:string
    handleChange?:(param:string)=>void
}) => {
    return (
        <Tabs items={items} activeKey={activeKey} className={`${getValidText(className)}`} onChange={handleChange}/>
    )
}
export default UiTabs
