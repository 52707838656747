import React, {FC, ReactElement, useState} from 'react'
import './projectsInfoItem.css'
import {useTranslation} from 'react-i18next'
import {EditableSpan} from '../EditableSpan/EditableSpan'
import CountrySelect from "../countrySelect/CountrySelect";
import {Countries} from "../../app/types/countries-enum";
import {Skeleton} from "antd";

interface IProps {
    name: string
    value?: string
    className?: string
    children?: ReactElement
    type?: string
    country?: Countries
    onChange: (value: string) => void
    isLoading?: boolean
}

const InfoItem: FC<IProps> = ({
                                  name,
                                  value,
                                  className,
                                  children,
                                  type,
                                  country,
                                  isLoading,
                                  onChange,
                              }) => {
    const {t} = useTranslation()
    const [inputValue, setInputValue] = useState('')

    const onSelect = (code) => {
        onChange(code)
    }

    const inputHandler = (e) => {
        setInputValue(e.currentTarget.value)
    }

    const keyPressHandler = (e) => {
        if (e.keyCode === 13 && inputValue.length > 0) {
            console.log('click')
            onChange(inputValue)
        }
    }
    const onBlurHandler = () => {
        if (inputValue.length > 0) {
            onChange(inputValue)
        }
    }
    const editHandler = (value) => {
        onChange(value)
    }

    const showFields = () => {
        if (type !== 'country' && value && value?.length > 0) {
            return (
                <EditableSpan
                    spanClassName={'projectsInfoItem-value'}
                    value={value}
                    handler={editHandler}
                />
            )
        } else if (type === 'country') {
            return (
                <CountrySelect onChange={onSelect} defaultValue={country}/>
            )
        } else if (children) {
            return children
        } else return (
            <input
                type="text"
                onBlur={onBlurHandler}
                onKeyDown={keyPressHandler}
                onChange={inputHandler}
                className={'projectsInfoItem-input'}
                placeholder={t(
                    'enter-value',
                )}
            />
        )
    }
    return (
        <div
            className={
                className
                    ? `projectsInfoItem ${className} `
                    : 'projectsInfoItem'
            }
        >
            <div className="projectsInfoItem-name">{name}</div>
            {
                isLoading
                    ? <Skeleton.Input active={true} className={'inlog-skeleton projectsInfoItem--skeleton'}/>
                    : showFields()
            }
        </div>
    )
}

export {InfoItem}
