import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { ITask } from '../../../../app/types/models/tasks'
import { selectCurrentOrganization } from '../../../../store/projects/selectors'
import { selectTaskData } from '../../../../store/tasks/selectors'
import { getValidText } from '../../../../utils/helpers/getValidText'
import { useAppDispatch } from '../../../../hooks/redux-hooks'
import { setSchedulerPage } from '../../../../store/app/actions'
import { SchedulerPagesType } from '../../../../widgets/scheduler/types'

const TasksBoard = ({
    slug,
    projectId,
}: {
    slug: string
    projectId: number
}) => {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const currentOrganization = useSelector(selectCurrentOrganization)
    const { results } = useSelector(selectTaskData)

    const memoizedTaskName = useMemo(() => {
        if (slug && slug?.length > 0) {
            return decodeURIComponent(slug?.split('_').slice(0, 2).join('_'))
        } else return ''
    }, [])

    const onClickChangeCurrentPath = async (task?: ITask) => {
        if (currentOrganization && projectId && task) {
            navigate(
                `/scheduler/${currentOrganization}/${projectId}/tasks/${
                    task?.is_template ? 'templates' : 'items'
                }/${task?.slug}`,
                {
                    state: {
                        slug: task?.slug,
                    },
                },
            )
            // dispatch(setSchedulerPage(SchedulerPagesType.tasks))
        }
    }

    const showLinkData = () => {
        const targetSlug = memoizedTaskName

        const targetTask = results.find((el) => el?.slug === targetSlug)

        if (targetTask) {
            return (
                <div>
                    <a onClick={() => onClickChangeCurrentPath(targetTask)}>
                        {getValidText(targetTask?.slug)}
                    </a>
                    {' > '}
                    <span className="taskEditSpace__slug">
                        {getValidText(decodeURIComponent(slug))}
                    </span>
                </div>
            )
        } else {
           return <div> {slug}</div>
        }
    }

    return (
        <>
            {slug?.split('_')?.length < 3 ? <div> {slug}</div> : showLinkData()}
        </>
    )
}

export default TasksBoard
