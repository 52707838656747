import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IMnemonic } from '../../app/types/models/IMnemonic'
import {
    ICore,
    IPad,
    IResearchEntity,
    IWell,
    IWellBore,
} from '../../app/types/models/logging'
import { IExtendedProject, IProject } from '../../app/types/models/project'
import { IWellLogFile } from '../../app/types/models/well-log-file'
import {
    addCurrentCore,
    addCurrentCorePhoto,
    addCurrentPad,
    addCurrentWell,
    addCurrentWellBore,
    addWellLogFile,
    deleteCurrentCore,
    deleteCurrentCorePhoto,
    deleteCurrentPad,
    deleteCurrentWell,
    deleteCurrentWellBore,
    deleteWellLogFile,
    selectCurrentPad,
    selectCurrentWell,
    selectCurrentWellBore,
    setCurrentCore,
    setCurrentCores,
    setCurrentPads,
    setCurrentProjectLoading,
    setCurrentWellBores,
    setCurrentWellLogFile,
    setCurrentWells,
    setLoadingCurrentItemStatus,
    setProject,
    setWellLogFiles,
    setWellLogMnemonics,
    setWellLogStrangeNans,
    updateCurrentCore,
    updateCurrentCoreInfo,
    updateCurrentPad,
    updateCurrentWell,
    updateCurrentWellBore,
    updateProject,
    updateWellLogFile
} from './actions'
import { setAppStatus } from '../app/actions'
import { projectsService } from '../../services/projects-service.'
import { handleAsyncServerNetworkError } from '../../utils/helpers/errors/handleAsyncError'

export interface InitialState {
    project: IExtendedProject
    pads: IResearchEntity[]
    wells: IWell[]
    wellBores: IWellBore[]
    wellLogFiles: IWellLogFile[] | null
    currentWellLogFile: IWellLogFile | null
    cores: ICore[]
    mnemonics: IMnemonic[] | null
    strange_nans: string[] | null
    currentPad: IPad | null
    currentWell: IWell | null
    currentWellBore: IWellBore | null
    currentCore: ICore | null
    isLoadingCurrentProject: boolean
    isLoadingCurrentPads: boolean
    isLoadingCurrentWells: boolean
    isLoadingCurrentWellbores: boolean
    isLoadingCurrentCores: boolean
}

const initialState: InitialState = {
    project: {} as IProject,
    pads: [],
    wells: [],
    wellBores: [],
    cores: [],
    wellLogFiles: [],
    mnemonics: [],
    strange_nans: [],
    currentCore: null,
    currentPad: null,
    currentWell: null,
    currentWellBore: null,
    currentWellLogFile: null,
    isLoadingCurrentPads: false,
    isLoadingCurrentWells: false,
    isLoadingCurrentCores: false,
    isLoadingCurrentProject: false,
    isLoadingCurrentWellbores: false,
}

const getDetailProject = createAsyncThunk<
    void,
    { id: number; onSuccess?: (res?:IProject) => void },
    { rejectValue: string }
>('currentProject/getDetail', async ({ id, onSuccess }, thunkAPI) => {
    try {
        thunkAPI.dispatch(setAppStatus({ status: 'loading' }))
        const projectRes = await projectsService.getCurrentProject(id)
        thunkAPI.dispatch(setProject({ project: projectRes }))
        thunkAPI.dispatch(setAppStatus({ status: 'succeeded' }))
        onSuccess && onSuccess(projectRes)
    } catch (e: any) {
        return handleAsyncServerNetworkError(e, thunkAPI)
    }
})

export const asyncActions = { getDetailProject }

export const slice = createSlice({
    name: 'currentProject',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setCurrentProjectLoading, (state, action) => {
            state.isLoadingCurrentProject = action.payload.loading
        })
        builder.addCase(setProject, (state, action) => {
            state.project = action.payload.project
        })
        builder.addCase(updateProject, (state, action) => {
            state.project = { ...state.project, ...action.payload }
        })
        builder.addCase(setCurrentPads, (state, action) => {
            state.pads = action.payload.pads
        })
        builder.addCase(addCurrentPad, (state, action) => {
            state.pads = [action.payload.pad, ...state.pads]
        })
        builder.addCase(updateCurrentPad, (state, action) => {
            state.pads = state.pads.map((item) =>
                item.id === action.payload.id
                    ? {
                        ...item,
                        name: action.payload.name,
                    }
                    : item,
            )
        })
        builder.addCase(deleteCurrentPad, (state, action) => {
            state.pads = state.pads.filter(
                (item) => item.id !== action.payload.id,
            )
        })
        builder.addCase(setCurrentWells, (state, action) => {
            state.wells = action.payload.wells
        })
        builder.addCase(addCurrentWell, (state, action) => {
            state.wells = [action.payload.well, ...state.wells]
        })
        builder.addCase(updateCurrentWell, (state, action) => {
            state.wells = state.wells.map((item) =>
                item.id === action.payload.id
                    ? {
                        ...item,
                        ...action.payload,
                    }
                    : item,
            )
        })
        builder.addCase(deleteCurrentWell, (state, action) => {
            state.wells = state.wells.filter(
                (item) => item.id !== action.payload.id,
            )
        })
        builder.addCase(setCurrentWellBores, (state, action) => {
            state.wellBores = action.payload.wellBores
        })
        builder.addCase(addCurrentWellBore, (state, action) => {
            state.wellBores = [action.payload.wellBore, ...state.wellBores]
        })
        builder.addCase(updateCurrentWellBore, (state, action) => {
            state.wellBores = state.wellBores.map((item) =>
                item.id === action.payload.id
                    ? {
                        ...item,
                        ...action.payload,
                    }
                    : item,
            )
        })
        builder.addCase(deleteCurrentWellBore, (state, action) => {
            state.wellBores = state.wellBores.filter(
                (item) => item.id !== action.payload.id,
            )
        })
        builder.addCase(setCurrentCores, (state, action) => {
            state.cores = action.payload.cores
        })
        builder.addCase(addCurrentCore, (state, action) => {
            state.cores = [action.payload.core, ...state.cores]
        })
        builder.addCase(updateCurrentCore, (state, action) => {
            state.cores = state.cores.map((item) =>
                item.id === action.payload.id
                    ? {
                        ...item,
                        name: action.payload.name,
                    }
                    : item,
            )
        })
        builder.addCase(updateCurrentCoreInfo, (state, action) => {
            state.cores = state.cores.map((item) =>
                item.id === action.payload.id
                    ? { ...item, ...action.payload.core }
                    : item,
            )
        })
        builder.addCase(deleteCurrentCore, (state, action) => {
            state.cores = state.cores.filter(
                (item) => item.id !== action.payload.id,
            )
        })
        builder.addCase(addCurrentCorePhoto, (state, action) => {
            state.cores = state.cores.map((core) =>
                core.id === action.payload.id
                    ? {
                          ...core,
                          photos:
                              core.photos.length > 0
                                  ? [...core.photos, action.payload.photo]
                                  : [action.payload.photo],
                      }
                    : core,
            )
        })
        builder.addCase(deleteCurrentCorePhoto, (state, action) => {
            state.cores = state.cores.map((core) =>
                core.id === action.payload.id
                    ? {
                          ...core,
                          photos: core.photos.filter(
                              (item) => item.id !== action.payload.photoId,
                          ),
                      }
                    : core,
            )
        })
        builder.addCase(setWellLogFiles, (state, action) => {
            state.wellLogFiles = action.payload
        })
        builder.addCase(setCurrentWellLogFile, (state, action) => {
            state.currentWellLogFile = action.payload
        })

        builder.addCase(deleteWellLogFile, (state, action) => {
            state.wellLogFiles =
                state.wellLogFiles &&
                state.wellLogFiles.filter(
                    (item) => item.id !== action.payload.id,
                )
            if (action.payload.id === state.currentWellLogFile?.id) {
                state.currentWellLogFile = null
            }
            if (state.wellLogFiles?.length > 1) {
                state.currentWellLogFile = state.wellLogFiles.at(-1)
            } else if (state.wellLogFiles?.length === 1) {
                state.currentWellLogFile = state.wellLogFiles[0]
            }
        })
        builder.addCase(addWellLogFile, (state, action) => {
            if (state.wellLogFiles.length === 0) {
                state.wellLogFiles = [action.payload]
            } else {
                state.wellLogFiles = [...state.wellLogFiles, action.payload]
            }
        })
        builder.addCase(updateWellLogFile, (state, action) => {
            state.wellLogFiles = state.wellLogFiles.map((item) =>
                item.id === action.payload.id
                    ? { ...item, ...action.payload }
                    : item,
            )
        })

        builder.addCase(setWellLogMnemonics, (state, action) => {
            state.mnemonics = action.payload
        })

        builder.addCase(setWellLogStrangeNans, (state, action) => {
            state.strange_nans = action.payload
        })
        builder.addCase(selectCurrentPad, (state, action) => {
            state.currentPad = action.payload
        })
        builder.addCase(selectCurrentWell, (state, action) => {
            state.currentWell = action.payload
        })
        builder.addCase(selectCurrentWellBore, (state, action) => {
            state.currentWellBore = action.payload
        })
        builder.addCase(setCurrentCore, (state, action) => {
            state.currentCore = action.payload
        })
        builder.addCase(setLoadingCurrentItemStatus, (state, action) => {
            switch (action.payload.type) {
                case 'currentPads':
                    state.isLoadingCurrentPads = action.payload.status
                    break
                case 'currentWells':
                    state.isLoadingCurrentWells = action.payload.status
                    break
                case 'currentWellbores':
                    state.isLoadingCurrentWellbores = action.payload.status
                    break
                case 'currentCores':
                    state.isLoadingCurrentCores = action.payload.status
                    break
                default:
                    break
            }
        })
    },
})
