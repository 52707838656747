import {
    ICalendarGroup,
    ICalendarItem,
} from '../../../../../app/types/i-roadmap-calendar'
import { getDayPosition } from './get-day-position'
import { getStartEndDate } from './get-start-end-date'

const getNowDate = (data: ICalendarItem, index: number) => {
    const nowYear = new Date().getFullYear()
    const nowMonth = new Date().getMonth()
    const nowDay = new Date().getDate()

    const month = data?.value?.month?.index || 0
    const year = data?.value?.year
    let days = data?.value?.days || []

    if (index === 0) {
        days = days.map((d) => (d >= 25 ? 0 : d))
    }

    if (year === nowYear && month === nowMonth) {
        const targetPosition = days.indexOf(nowDay) + 1
        if (days.includes(nowDay)) {
            return (targetPosition / days.length) * 100
        } else return null
    } else return null
}

const getWeek = (
    week: ICalendarItem,
    interval: [number, number],
    weekI: number,
) => {
    return {
        ...week,
        value: {
            ...week?.value,
            interval,
            nowDate: getNowDate(week, weekI),
        },
    }
}

const getWeekList = (
    list: ICalendarItem[],
    targetDay: number,
    type: 'start' | 'end',
) => {
    let status = false

    return list.map((week, weekI) => {
        let days = week?.value?.days || []

        const targetPosition = days.indexOf(targetDay) + 1
        const targetPercent = (targetPosition / days.length) * 100

        if (days.includes(targetDay)) {
            status = true
            if (type === 'start') {
                return getWeek(week, [targetPercent, 100], weekI)
            } else {
                return getWeek(week, [0, targetPercent], weekI)
            }
        } else {
            if (type === 'start') {
                return getWeek(week, [0, status ? 100 : 0], weekI)
            } else {
                return getWeek(week, [0, status ? 0 : 100], weekI)
            }
        }
    })
}

export const getGroupsListByMonthDetalization = (
    calendarGroups: ICalendarGroup[],
    data: {
        startDate: string | Date
        endDate: string | Date
    },
) => {
    const { startDay, startMonth, startYear, endDay, endMonth, endYear } =
        getStartEndDate(data,'week')

    let targetStartMonth = startMonth
    let targetEndMonth = endMonth
    let targetStartGroup = {} as ICalendarGroup
    let targetEndGroup = {} as ICalendarGroup

    if (calendarGroups.length === 1) {
        targetStartGroup = calendarGroups[0]
        targetEndGroup = calendarGroups[0]
        targetEndMonth = targetStartMonth
    } else {
        targetStartGroup = calendarGroups?.find(
            (el) => el?.index === targetStartMonth,
        )
        targetEndGroup = calendarGroups?.find(
            (el) => el?.index === targetEndMonth,
        )
    }

    if (startYear === endYear && endMonth - startMonth === 1) {
        const targetGroup = calendarGroups?.find(
            (el) => el?.index === targetStartMonth,
        )
        if (targetGroup) {
            let isIncludeStartDay,
                isIncludeEndDay = false

            targetGroup?.list.forEach((week, weekI) => {
                const days = week?.value?.days
                if (days.includes(startDay)) {
                    isIncludeStartDay = true
                }
                if (endDay <= 6 && days.includes(endDay)) {
                    isIncludeEndDay = true
                }
            })

            if (isIncludeStartDay && isIncludeEndDay) {
                targetEndMonth = targetStartMonth
            }
        }
    }

    return calendarGroups.map((group) => {  
        const getGroup = (interval: [number, number]) => {
            return {
                ...group,
                list: group.list.map((week, weekI) => {
                    return {
                        ...week,
                        value: {
                            ...week?.value,
                            interval,
                            nowDate: getNowDate(week, weekI),
                        },
                    }
                }),
            }
        }   
        if (group.year === startYear && group.year === endYear) {
            return {   
                ...group,
                list: group.list.map((week, weekI) => {
                    const days = week?.value?.days 
                    const {
                        startPosition,
                        endPosition,
                    } = getDayPosition({
                        startDate: new Date(data.startDate),
                        endDate: new Date(data.endDate),
                        info: week,
                    })

                    const firstPercent =
                        (startPosition / days?.length) * 100
                    let endPercent = (endPosition / days?.length) * 100
                    return getWeek(week, [firstPercent, endPercent], weekI)
                }),
            }
        } else if (group.year > startYear && group.year < endYear) {
            return getGroup([0, 100])
        } else if (group.year === startYear && group.year !== endYear) {
            if (group.index === targetStartMonth) {
                return {
                    ...group,
                    list: getWeekList(group.list, startDay, 'start'),
                }
            } else if (group.index < startMonth) {
                return getGroup([0, 0])
            } else {
                return getGroup([0, 100])
            }
        } else if (group.year === endYear && group.year !== startYear) {
            if (group.index === targetEndMonth) {
                return {
                    ...group,
                    list: getWeekList(group.list, endDay, 'end'),
                }
            } else {
                return getGroup([0, group.index < targetEndMonth ? 100 : 0])
            }
        } else if (group.year < startYear || group.year > endYear) {
            return getGroup([0, 0])
        }
        return group
    }) as ICalendarGroup[]
}
