import { FC, MouseEvent, useEffect, useState } from 'react';

import { TooltipPlacement } from 'antd/es/tooltip';
import React from 'react';
import { IPopoverMenuItem } from '../../app/types/common';
import { DottesMenuIcon } from '../../image_files/icons/DotesMenuIcon';
import { stopEvent } from '../stopEvent';
import PopoverList from './PopoverList';
import './PopoverMenu.css';

interface IProps{
    items: IPopoverMenuItem[];
    placement?: TooltipPlacement;
    title?: string | React.ReactNode;
    closeTrigger?: number;
    className?: string;
    children?:React.ReactNode
  
    onClick?: (event?: MouseEvent<HTMLElement>) => void;
}

const PopoverMenu: FC<IProps> = (props) => {
  const { items, title, className,children, closeTrigger, onClick } = props;
  const [openMenu, setOpenMenu] = useState(false);

  const [mouseCoordinates, setMouseCoordinates] = useState({ x: 0, y: 0,scale:1 });

  const clickHandler = (
    event: React.MouseEvent<HTMLElement>,
    item: IPopoverMenuItem
  ) => {
    stopEvent(event);
    if (item?.onClick) {
      item?.onClick();
    }
    setOpenMenu(false);
  };

  const toggleMenuHandler = (event: React.MouseEvent<HTMLElement>) => {
    onClick && onClick();
    stopEvent(event);
    setOpenMenu(!openMenu);
  };

  const showContent = () => {
    return (
      <>
        {items.map((item, index) => (
          <div
            className="popover-menu-item font-14-normal row-flex-15 cursor-pointer"
            key={item.id + '' + index}
            onClick={(e) => {
              clickHandler(e, item);
              setOpenMenu(true);
            }}
          >
            {item?.icon && item?.icon}
            <div>{item.title}</div>
          </div>
        ))}
      </>
    );
  };

  const handleGetMouseCoordinates = (e: MouseEvent<HTMLDivElement>) => {
    const x = (e.clientX);
    const y = (e.clientY);
    setMouseCoordinates({ x, y,scale:window.devicePixelRatio });
  };

  useEffect(() => {
    if (closeTrigger !== undefined && closeTrigger > 0) {
      setOpenMenu(false);
    }
  }, [closeTrigger]);

  return (
    <div
      className={`popover-menu-wrap ${className}`}
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        stopEvent(event);
      }}
    >
          <PopoverList
            isOpenDetailInfo={openMenu}
            handleClose={() => setOpenMenu(false)}
            title=''
            mouseCoordinates={mouseCoordinates}
          >
            {showContent()}
          </PopoverList>

          <div
            className="popover-menu-icon-wrapper"
            onClick={toggleMenuHandler}
            onMouseDown={handleGetMouseCoordinates}
          >
            {children ? children : <DottesMenuIcon />}
            
          </div>
    </div>
  );
};

export default PopoverMenu;
