import React from 'react'
import { IIconProps } from '../../app/types/i-props'
import { getValidText } from '../../utils/helpers/getValidText'

const PanelTasksIcon = ({ className }: IIconProps) => {
    return (
        <svg
            fill="none"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            className={getValidText(className)}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M12 9L17 9" stroke="#2B506B" strokeLinecap="round" />
            <path
                d="M7 8.5L8 9.5L10 7"
                stroke="#2B506B"
                strokeLinecap="round"
            />
            <path
                d="M7 15L8 16L10 13.5"
                stroke="#2B506B"
                strokeLinecap="round"
            />
            <path d="M12 15L17 15" stroke="#2B506B" strokeLinecap="round" />
            <rect
                x="2.5"
                y="2.5"
                width="19"
                height="19"
                rx="4.5"
                stroke="#2B506B"
            />
        </svg>
    )
}

export default PanelTasksIcon
