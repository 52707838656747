import { Skeleton } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { TASKS_LIMIT } from '../../app/constants'
import { ITask, ITasksFilterParams } from '../../app/types/models/tasks'
import TasksFilter from '../../features/tasks/components/TasksFilter/TasksFilter'
import { TasksTabTypes } from '../../features/tasks/types'
import { useAppDispatch } from '../../hooks/redux-hooks'
import { Pagination } from '../../shared/Pagination/Pagination'
import { selectCurrentProject } from '../../store/currentProject/selectors'
import { selectCurrentOrganization } from '../../store/projects/selectors'
import { tasksActions } from '../../store/tasks'
import { selectTaskData, selectTaskStatus } from '../../store/tasks/selectors'
import { Task } from '../Task/Task'
import { useLocation } from 'react-router'

const TasksList = ({ type }: { type: TasksTabTypes }) => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const tasksStatus = useSelector(selectTaskStatus)
    const dispatch = useAppDispatch()
    const location = useLocation()
    const currentProject = useSelector(selectCurrentProject)
    const currentOrganization = useSelector(selectCurrentOrganization)
    const { results: tasks, count: tasksCount } = useSelector(selectTaskData)

    const [filterParams, setFilterParams] = useState<ITasksFilterParams>({
        ordering: 'created_at',
        is_template: false,
    })

    useEffect(() => {
        setFilterParams({
            ordering: 'created_at',
            is_template: type === TasksTabTypes.TEMPLATES,
        })
    }, [type])

    const handleGetTask = (task: ITask) => {
        if (location.state?.slug !== task?.slug) {
            dispatch(
                tasksActions.getCurrentTask({
                    slug: task?.slug,
                    onSuccess: () => {
                        navigate(
                            `/scheduler/${currentOrganization}/${
                                currentProject?.id
                            }/tasks/${
                                type === TasksTabTypes.TASKS
                                    ? 'items'
                                    : 'templates'
                            }/${task?.slug}`,
                            {
                                state: {
                                    slug: task?.slug,
                                },
                            },
                        )
                    },
                }),
            )
        }
    }

    const handleChangePagination = async (params: {
        limit: number
        offset: number
    }) => {
        setFilterParams((prev) => ({ ...prev, ...params }))
    }

    const fetchTasks = () => {
        dispatch(
            tasksActions.getTasks({
                projectId: currentProject?.id,
                params: filterParams,
            }),
        )
    }

    useEffect(() => {
        if (currentProject?.id && currentOrganization !== undefined) {
            fetchTasks()
        }
    }, [filterParams, currentProject?.id, currentOrganization])

    const showSkeleton = () =>
        Array(4)
            .fill('')
            .map((_, i) => <TaskSkeletonItem key={i} />)

    const getItems = () => {
        return tasks
            .filter((el) =>
                type === TasksTabTypes.TEMPLATES
                    ? el.is_template
                    : !el?.is_template,
            )
            .map((el) => (
                <Task
                    task={el}
                    key={el?.id}
                    onClick={() => handleGetTask(el)}
                    type={type}
                />
            ))
    }

    const showContainer = () => {
        let isShowItems = true
        if (tasksStatus === 'loading') {
            isShowItems = false
        }

        if (isShowItems) {
            return (
                <div className="scheduler__tasks-container">{getItems()}</div>
            )
        } else {
            return (
                <div className="scheduler__tasks-skeleton-wrapper">
                    {showSkeleton()}
                </div>
            )
        }
    }

    if (
        tasksStatus === 'succeeded' &&
        tasks.length === 0 &&
        Object.keys(filterParams).length < 2
    ) {
        return (
            <div className="font-14-normal scheduler__absent-task-message">
                {type === TasksTabTypes.TASKS
                    ? t('tasks-absent-message')
                    : t('templates-absent-message')}
            </div>
        )
    }

    return (
        <>
            <TasksFilter
                filterParams={filterParams}
                setFilterParams={setFilterParams}
            />
            <div className="mt-10 br-default bg-white">{showContainer()}</div>

            <Pagination
                count={tasksCount}
                limit={TASKS_LIMIT}
                onChange={handleChangePagination}
            />
        </>
    )
}

const TaskSkeletonItem = () => {
    return (
        <Skeleton.Input
            active={true}
            className="scheduler__tasks-skeleton-item"
        />
    )
}

export default TasksList
