import React from 'react'

const MemberIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="12.5" cy="8.5" r="6" stroke="#2B506B" />
            <path
                d="M4 20.5L5.81007 18.1728C6.90934 16.7594 8.42151 15.7235 10.1365 15.209V15.209C11.6811 14.7457 13.3259 14.7253 14.8818 15.1496V15.1496C16.8915 15.6977 18.6365 16.9548 19.792 18.688L21 20.5"
                stroke="#2B506B"
                stroke-linecap="round"
            />
        </svg>
    )
}

export default MemberIcon
