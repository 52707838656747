import { createAction } from '@reduxjs/toolkit'
import {
    IComment,
    IStatus,
    ISubTask,
    ITag,
    ITask,
} from '../../app/types/models/tasks'
import { PriorityTypes } from '../../app/types/enums'
import { LoadingStatusesType } from '../../app/types/common'
import { IUser } from '../../app/types/models/users'
import { RequestStatusType } from '../../app/types/request'

const setTasks = createAction<{
    count: number
    next: string
    previous: string
    results: ITask[]
}>('tasksActions/setTasks')


const addTask = createAction<{ task: ITask }>('tasksActions/addTask')

const deleteTask = createAction<{ slug: string }>('tasksActions/deleteTask')

const updateTask = createAction<any>('tasksActions/updateTask')

const setSubTasks = createAction<{ subTasks: ISubTask[] }>(
    'tasksActions/setSubTasks',
)

const addSubtask = createAction<{ subtask: ISubTask }>(
    'tasksActions/addSubtask',
)

const deleteSubtask = createAction<{ slug: string }>(
    'tasksActions/deleteSubtask',
)

const setComments = createAction<{ comments: IComment[] }>(
    'tasksActions/setComments',
)

const addComment = createAction<{ comment: IComment }>(
    'tasksActions/addComment',
)

const setStatuses = createAction<{ statuses: IStatus[] }>(
    'tasksActions/setStatuses',
)

const setTaskStatus = createAction<RequestStatusType>(
    'tasksActions/setTaskStatus',
)

export {
    addTask,
    setTasks,
    deleteTask,
    updateTask,
    setSubTasks,
    addSubtask,
    deleteSubtask,
    setComments,
    addComment,
    setStatuses,
    setTaskStatus,
}
