import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { EDITABLE_NAME_SYMBOLS_MAX_LENGTH } from '../../app/constants'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { EditIcon } from '../../image_files/icons/EditIcon'
import { getValidText } from '../../utils/helpers/getValidText'
import UiInput from '../ui/input/UiInput'
import './editableSpan.css'
import { IEditableSpanProps } from './types-ALS-101'

export const EditableSpan: FC<IEditableSpanProps> = (props) => {
    const { value, className, children, handleChange, lengthLimit } = props
    const wrapperRef = useRef(null)

    const [editMode, setEditMode] = useState(false)
    const [errorValue, setErrorValue] = useState('')
    const [inputValue, setInputValue] = useState(value ? value : '')

    const closeEdit = () => {
        setEditMode(false)
        setErrorValue('')
    }

    useOnClickOutside([wrapperRef], closeEdit)

    const handleKeyDown = (e) => {
        if (e.code === 'Enter' && inputValue.length > 0) {
            closeEdit()
        }
    }
    const handleBlur = () => {
        if (inputValue.length > 0) {
            closeEdit()
        }
        if (inputValue.length === 0) {
            setInputValue(value)
        }
    }

    useEffect(() => {
        if (
            editMode === false &&
            inputValue !== undefined &&
            value !== undefined &&
            inputValue !== value
        ) {
            handleChange && handleChange(inputValue)
            setErrorValue('')
        }
    }, [editMode, inputValue])

    const handleInputChange = (e) => {
        setErrorValue('')
        setInputValue(e.currentTarget.value)
    }

    useLayoutEffect(() => {
        setInputValue(value)
        setErrorValue('')
    }, [value])

    return (
        <div
            className={`row-flex-5 editableSpan ${getValidText(className)}`}
            ref={wrapperRef}
        >
            {editMode ? (
                <div className="w-100p">
                    <UiInput
                        maxLength={
                            lengthLimit
                                ? lengthLimit
                                : EDITABLE_NAME_SYMBOLS_MAX_LENGTH
                        }
                        type="text"
                        value={inputValue}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        onKeyDown={handleKeyDown}
                    />
                    {errorValue.length > 0 && (
                        <p className={'font-error editableSpan__error'}>
                            {errorValue}
                        </p>
                    )}
                </div>
            ) : (
                <div
                    className={
                        'cursor-pointer transition-2 br-default editableSpan__container'
                    }
                    onDoubleClick={() => setEditMode(true)}
                >
                    <span
                        className="font-14-normal editableSpan__text"
                        onDoubleClick={() => setEditMode(true)}
                    >
                        {inputValue}
                    </span>
                    {children ? children : ''}
                    <EditIcon className={`editableSpan__icon`} />
                </div>
            )}
        </div>
    )
}
