import React from 'react'

export const TagsIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.7187 7.09502C7.00793 7.09502 4 10.1034 4 13.8146M13.2809 16.926C16.9917 16.926 20 13.9177 20 10.2068M7.09066 13.2808C7.09066 16.992 10.0986 20 13.8097 20M16.9211 10.7192C16.9211 7.008 13.9131 4 10.2024 4"
                stroke="#2B506B"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
