import { getWeeksList } from "../../../../../shared/calendarDetalizationRow/services/services"

export const getStartEndDate = (data: {
    startDate: string | Date
    endDate: string | Date
},type?:'week' | 'quarter' | 'year') => {
    let { startDate, endDate } = data
    startDate = new Date(startDate)
    endDate = new Date(endDate)

    const startYear = startDate.getFullYear()
    let startMonth = startDate.getMonth()
    const startDay = startDate.getDate()

    const endYear = endDate.getFullYear()
    const endMonth = endDate.getMonth()
    const endDay = endDate.getDate()

    const startMonthWeeks = getWeeksList(startYear, startMonth)
    if(type==='week' && startMonthWeeks[startMonthWeeks.length-1]?.end<7){
        startMonth =startMonth>0 ? startMonth-1 : 0
    }

    return {
        startYear,
        startMonth,
        startDay,
        endYear,
        endMonth,
        endDay,
    }
}
