import {
    CalendarGroupEntityTypes,
    CalendarItemEntityTypes,
    CalendarPriorityColors,
    ICalendarGroup,
    ICalendarItem,
} from '../../../app/types/i-roadmap-calendar'
import { getFullMonths } from './get-full-months'
import { getQuarterData } from './get-quarters-by-date'

export const getWeeksList = (
    year: number,
    month: number,
): { [key: string]: any } => {
    const list = getWeeksInYear(year)
    return list[monthList[month]]
}

export const monthList = [
    'jan',
    'feb',
    'march',
    'apr',
    'may',
    'june',
    'jule',
    'aug',
    'sept',
    'oct',
    'nov',
    'dec',
]

export const quartersList = ['I', 'II', 'III', 'IV']

export function getDaysInMonth(year: number, month: number) {
    // Определяем количество дней в месяце
    const daysInMonth = new Date(year, month + 1, 0).getDate()

    // Создаем массив от 1 до последнего дня месяца
    const daysArray = Array.from({ length: daysInMonth }, (_, i) => i + 1)

    return daysArray
}

const chunkArray = (array, size) =>
    Array.from({ length: Math.ceil(array.length / size) }, (_, index) =>
        array.slice(index * size, index * size + size),
    )

function getWeeksInYear(year) {
    let startDate = new Date(year, 0, 1)
    let endDate = new Date(year, 11, 31)
    let weeksByMonth = {}
    let currentDate = new Date(startDate)
    let weekStart = startDate

    while (currentDate <= endDate) {
        if (
            currentDate.getDay() === 0 ||
            currentDate.getTime() === endDate.getTime()
        ) {
            let startMonth = weekStart.getMonth()
            let monthKey = monthList[startMonth]

            if (!weeksByMonth[monthKey]) {
                weeksByMonth[monthKey] = []
            }

            const generateDaysItems = (
                days: number[],
                lastWeekDayDate: Date,
            ) => {
                let month = lastWeekDayDate.getMonth() // Месяц последнего дня (от 0 до 11)
                let year = lastWeekDayDate.getFullYear() // Год последнего дня

                let result: Record<
                    number,
                    {
                        day: number
                        month: number
                        year: number
                        position: number
                    }
                > = {}

                let isNextMonth = false // Флаг для отслеживания перехода на следующий месяц

                days.forEach((el, position, arr) => {
                    let currentMonth = month

                    if (position !== 0) {
                        const prev = arr[position - 1]
                        if (el < prev) {
                            isNextMonth = true
                        }
                    }

                    if (isNextMonth) {
                        currentMonth += 1
                        if (currentMonth > 12) {
                            currentMonth = 0 
                            year += 1
                        }
                    }

                    result[el] = {
                        day: el,
                        month: currentMonth,
                        year: year,
                        position: position,
                    }
                })

                if (isNextMonth) {
                    for (let key in result) {
                        const obj = result[key]
                        if (obj.month > 0) {
                            obj.month -= 1
                        }
                    }
                }
                return result // Возвращаем объект с информацией о днях
            }

            const days = getWeekdaysArray(
                new Date(weekStart),
                new Date(currentDate),
            )
            weeksByMonth[monthKey].push({
                start: new Date(weekStart).getDate(),
                end: new Date(currentDate).getDate(),
                days,
                items: generateDaysItems(days, new Date(currentDate)),
            })

            weekStart = new Date(currentDate)
            weekStart.setDate(weekStart.getDate() + 1)
        }
        currentDate.setDate(currentDate.getDate() + 1)
    }

    return weeksByMonth
}

function getWeekdaysArray(start, end) {
    let days = []
    let current = new Date(start)
    while (current <= end) {
        days.push(current.getDate())
        current.setDate(current.getDate() + 1)
    }
    return days
}

const getValidMonthIndex = (quater: number, quraterMonthIndex: number) => {
    const list = [
        [0, 1, 2],
        [3, 4, 5],
        [6, 7, 8],
        [9, 10, 11],
    ]
    return list[quater][quraterMonthIndex]
}

export const getQuarterDetalization = (data?: {
    startPeriod: Date | string
    endPeriod: Date | string
    priorityColor?: CalendarPriorityColors
}): ICalendarGroup[] => {
    const monthChunks = chunkArray(monthList, 3)

    let { startPeriod, endPeriod, priorityColor } = data

    startPeriod = new Date(startPeriod)
    endPeriod = new Date(endPeriod)

    const quarters = getQuarterData(startPeriod, endPeriod)

    return quarters.map((quarter) => {
        const quarterIndex = quarter.index - 1
        return {
            ...quarter,
            list: monthChunks[quarterIndex].map((month, monthI) => {
                const validMonthIndex = getValidMonthIndex(quarterIndex, monthI)
                return {
                    label: month,
                    type: CalendarItemEntityTypes.MONTH,
                    index: validMonthIndex,
                    days: getDaysInMonth(quarter.year, validMonthIndex),
                    value: {
                        interval: [0, 0],
                        priorityColor:
                            priorityColor || CalendarPriorityColors.IMPORTANT,
                    },
                } as ICalendarItem
            }),
        }
    })
}

export const getMonthDetalization = (data?: {
    startPeriod: Date | string
    endPeriod: Date | string
    priorityColor?: CalendarPriorityColors
}): ICalendarGroup[] => {
    const { startPeriod, endPeriod, priorityColor } = data

    const list = getFullMonths(
        new Date(startPeriod || new Date()),
        new Date(endPeriod || new Date()),
    )

    return list.map(
        (month: { month: number; year: number; label: string }) => ({
            type: CalendarGroupEntityTypes.MONTH,
            year: month.year,
            index: month.month,
            label: month.label,
            list: getWeeksList(month.year, month.month)?.map(
                (week) =>
                    ({
                        label: `${week?.start}-${week?.end}`,
                        value: {
                            days: week?.days,
                            items: week?.items,
                            interval: [0, 0],
                            year: month.year,
                            month: {
                                label: month.label,
                                index: month.month,
                            },
                            priorityColor:
                                priorityColor ||
                                CalendarPriorityColors.IMPORTANT,
                        },
                        type: CalendarItemEntityTypes.WEEK,
                    } as ICalendarItem),
            ),
        }),
    )
}

export const getYearDetalization = (data?: {
    startPeriod: Date | string
    endPeriod: Date | string
    priorityColor?: CalendarPriorityColors
}): ICalendarGroup[] => {
    const { startPeriod, endPeriod, priorityColor } = data

    const getYears = () => {
        const startYear = new Date(startPeriod).getFullYear()
        const endYear = new Date(endPeriod).getFullYear()
        const res: number[] = []

        if (startYear === endYear) {
            res.push(startYear)
        } else {
            let start = startYear
            while (start <= endYear) {
                res.push(start)
                start += 1
            }
        }

        return res
    }

    return getYears().map((year) => ({
        type: CalendarGroupEntityTypes.YEAR,
        label: year.toString(),
        year,
        list: quartersList.map(
            (quarter, quarterI) =>
                ({
                    label: quarter,
                    type: CalendarItemEntityTypes.QUARTER,
                    value: {
                        orderIndex: quarterI,
                        interval: [0, 0],
                        priorityColor:
                            priorityColor || CalendarPriorityColors.IMPORTANT,
                    },
                } as ICalendarItem),
        ),
    }))
}
