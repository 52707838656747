import React from 'react';
import { Route, Routes } from "react-router-dom";
import { Activity } from "../../../features/activity/Activity";
import { Equipment } from "../../../features/equipment/Equipment";
import { Panels } from "../../../features/panels/Panels";
import { RoadMap } from "../../../features/roadMap/RoadMap";
import Tasks from '../../../features/tasks/Tasks';
import { Team } from "../../../features/team/Team";

const SchedulerRoutes = () => {
    return (
        <Routes>
            <Route
                path=":organizationId/:projectId/tasks/*"
                element={
                    <Tasks/>
                }
            />
            <Route
                path="/:organizationId/:projectId/equipment"
                element={<Equipment/>}
            />
            <Route
                path="/:organizationId/:projectId/activity"
                element={<Activity/>}
            />
            <Route
                path="/:organizationId/:projectId/roadmap"
                element={<RoadMap/>}
            />
            <Route path="/:organizationId/:projectId/team" element={<Team/>}/>
            <Route
                path="/:organizationId/:projectId/statuses/*"
                element={
                    <Panels/>
                }
            />
        </Routes>
    );
};

export default SchedulerRoutes;