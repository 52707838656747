import { Button, Form } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../../../hooks/redux-hooks'
import { XIcon } from '../../../../image_files/icons/XIcon'
import CountrySelect from '../../../../shared/countrySelect/CountrySelect'
import UiInput from '../../../../shared/ui/input/UiInput'
import UISelect from '../../../../shared/ui/select/UlSelect'
import { getOrganizations } from '../../../../store/organization/selectors'
import { projectsActions, projectsSelectors } from '../../../../store/projects'
import { errorsHandler } from '../../../../utils/helpers/errors/errors-hendler'
import { showNotice } from '../../../../utils/helpers/showNotice'
import './newProject.css'

const NewProject = () => {
    const { t, i18n } = useTranslation()
    const [isChangedField, setIsChangedField] = useState(false)
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const projects = useSelector(projectsSelectors.selectProjects)
    const currentOrganization = useSelector(projectsSelectors.selectCurrentOrganization)
    const [selectData, setSelectData] = useState('RU')
    const organizations = useSelector(getOrganizations) ?? []

    const cancelHandler = (e) => {
        e.preventDefault()
        navigate(-1)
    }
    const onSelect = (value: string) => setSelectData(value)

    //Form
    const onFormFinish = useCallback(
        async (values) => {
            try {
                await dispatch(
                    projectsActions.createProject({
                        values: {
                            ...values,
                            name: values?.name.trim(),
                            country: selectData,
                        },
                        onSuccess: (id: number) => {
                            navigate(`/logging/${id}`)
                            form.resetFields()
                            showNotice('new-project-added-successfully', t)
                        },
                    }),
                )
            } catch (error) {
                errorsHandler(error,t)
            }
        },
        [form, selectData, i18n],
    )

    useEffect(() => {
        if (
            form.getFieldsError(['name', 'reservoir']) &&
            form
                .getFieldsError(['name', 'reservoir'])
                .filter((item) => item.errors.length > 0).length > 0
        ) {
            form.submit()
        }
    }, [i18n.language])

    return (
        <div className="new-project">
            <div className="new-project-container br-5">
                <Form
                    form={form}
                    onFinish={onFormFinish}
                    initialValues={{
                        organization:currentOrganization
                    }}
                    className={'new-project__form'}
                    layout="vertical"
                >
                    <Form.Item
                        name="name"
                        label={`${t('project-name')}`}
                        rules={[
                            {
                                required: true,
                                message: t('project-name-required'),
                            },
                        ]}
                        className={'new-project__formItem'}
                    >
                        <UiInput
                            onChange={() => setIsChangedField(true)}
                        />
                    </Form.Item>
                    <Form.Item
                        name="reservoir"
                        label={`${t('resorvior')}`}
                        rules={[
                            {
                                required: true,
                                message: t('resorvoir-required'),
                            },
                        ]}
                        className={'new-project__formItem'}
                    >
                        <UiInput
                            onChange={() => setIsChangedField(true)}
                        />
                    </Form.Item>
                    <Form.Item
                        name="organization"
                        label={`${t('organization')}`}
                        rules={[
                            {
                                required: true,
                                message: t('organization-required'),
                            },
                        ]}
                        className={'new-project__formItem'}
                    >
                        <UISelect
                            options={organizations.map(el=>({label:el?.fullName,value:el?.id}))}
                            onChange={() => setIsChangedField(true)}
                        />
                    </Form.Item>
                    <Form.Item
                        name="company_customer"
                        label={`${t('company-customer')}`}
                        className={'new-project__formItem'}
                    >
                        <UiInput
                            onChange={() => setIsChangedField(true)}
                        />
                    </Form.Item>
                    <Form.Item
                        name="contractor"
                        label={`${t('assignee')}`}
                        className={'new-project__formItem'}
                    >
                        <UiInput
                            onChange={() => setIsChangedField(true)}
                        />
                    </Form.Item>
                    <Form.Item
                        name="#"
                        label={`${t('object-country')}`}
                        className={'new-project__formItem'}
                    >
                        <CountrySelect
                            onChange={onSelect}
                            selectedData={selectData}
                            setSelectedData={setSelectData}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            className={'new-project__formBtn'}
                            disabled={!isChangedField}
                            htmlType="submit"
                        >
                            {`${t('continue')}`}
                        </Button>
                    </Form.Item>
                </Form>
                {projects.length > 0 && (
                    <button
                        className={'new-project__cancelBtn'}
                        onClick={cancelHandler}
                    >
                        <XIcon />
                    </button>
                )}
            </div>
        </div>
    )
}

export { NewProject }

