import { RootState } from '../store'
import {
    IComment,
    IStatus,
    ISubTask,
    ITask,
} from '../../app/types/models/tasks'
import { LoadingStatusesType } from '../../app/types/common'
import { RequestStatusType } from '../../app/types/request'

type TaskData = {
    count: number
    next: null | number | string
    previous: null | number | string
    results: ITask[]
    currentTask: null | ITask
    subtasks: ISubTask[]
    comments: IComment[]
}

export const selectTasks = (state: RootState): ITask[] => state.tasks.results

export const selectCurrentTask = (state: RootState): ITask =>
    state.tasks.currentTask

export const selectSubTasks = (state: RootState): ISubTask[] =>
    state.tasks.subtasks

export const selectComments = (state: RootState): IComment[] =>
    state.tasks.comments

export const selectTaskData = (state: RootState): TaskData => state.tasks

export const selectStatuses = (state: RootState): IStatus[] =>
    state.tasks.statuses

export const selectTaskStatus = (state: RootState): RequestStatusType =>
    state.tasks.status
