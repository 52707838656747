import React from "react"
import { IIconProps } from "../../app/types/i-props"
import { getValidText } from "../../utils/helpers/getValidText";

const PanelStatusesIcon = ({className}:IIconProps) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={getValidText(className)}
        >
            <rect
                x="2.5"
                y="2.5"
                width="19"
                height="19"
                rx="4.5"
                stroke="#2B506B"
            />
            <path
                d="M8 13L10.6891 16.4574C11.0961 16.9807 11.8903 16.9703 12.2835 16.4367L18.5 8"
                stroke="#2B506B"
                strokeLinecap="round"
            />
        </svg>
    )
}

export default PanelStatusesIcon;
