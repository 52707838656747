import { createContext, SetStateAction } from 'react'
import { IDetalizationData, IUpdateTaskModalData } from './types'
import { ITask, ITasksFilterParams, SortTaskTypes } from '../../app/types/models/tasks'
import { PriorityTypes } from '../../app/types/enums'
import { ISelectOption } from '../../app/types/common'

export const RoadmapContext = createContext<{
    tagsOptions:ISelectOption[],
    sortParams:SortTaskTypes[],
    filterParams:ITasksFilterParams,
    detalizationData: IDetalizationData,
    updateTaskModalData:IUpdateTaskModalData,

    removeTask:(data:ITask)=>void,
    createTask:(name: string, priority: PriorityTypes)=>void,
    setSortParams:(data:SetStateAction<SortTaskTypes[]>)=>void,
    setTagsOptions:(data:SetStateAction<ISelectOption[]>)=>void,
    setFilterParams: (data: SetStateAction<ITasksFilterParams>) => void
    setDetalizationData: (data: SetStateAction<IDetalizationData>) => void
    setUpdateTaskModalData: (data: SetStateAction<IUpdateTaskModalData>) => void
}>({
    tagsOptions:[],
    sortParams:[],
    filterParams:{} as ITasksFilterParams,
    detalizationData: {} as IDetalizationData,
    updateTaskModalData: { } as IUpdateTaskModalData,

    removeTask:()=>undefined,
    createTask:()=>undefined,
    setSortParams:()=>undefined,
    setTagsOptions:()=>undefined,
    setFilterParams: () => undefined,
    setDetalizationData: () => undefined,
    setUpdateTaskModalData: () => undefined,
})
