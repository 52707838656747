import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { setAppStatus } from '../app/actions'
import { projectsService } from '../../services/projects-service.'
import { organizationService } from '../../services/organization-service'
import { handleAsyncServerNetworkError } from '../../utils/helpers/errors/handleAsyncError'
import { IOrganization } from '../../app/types/models/i-organization'

export interface IOrganizationState {
    organizations: IOrganization[]
}

const initialState: IOrganizationState = {
    organizations: [],
}

const fetchOrganizations = createAsyncThunk<
    IOrganization[],
    {
        onSuccess?:(res:IOrganization[])=>void
    },
    { rejectValue: string }
>('organization/fetchOrganizations', async ({onSuccess}, thunkAPI) => {
    thunkAPI.dispatch(setAppStatus({ status: 'loading' }))
    try {
        const res = await organizationService.getOrganizations()
        console.log('res', res)
        thunkAPI.dispatch(setAppStatus({ status: 'succeeded' }))
        onSuccess && onSuccess(res)
        return res
    } catch (e: any) {
        return handleAsyncServerNetworkError(e, thunkAPI)
    }
})

export const asyncActions = { fetchOrganizations }

export const slice = createSlice({
    name: 'organization',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchOrganizations.fulfilled, (state, action) => {
            state.organizations = action.payload // Сохраняем полученные организации
        })
    },
})
