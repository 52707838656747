import React from "react";
import { IIconProps } from "../../app/types/i-props";
import { getValidText } from "../../utils/helpers/getValidText";

const PanelMembersIcon = ({className}:IIconProps) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            className={getValidText(className)}
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="12" cy="12" r="8.5" stroke="#364F6B" />
            <circle cx="12" cy="10" r="3.5" stroke="#364F6B" />
            <path
                d="M7 17L8.50488 15.307C8.83289 14.938 9.21727 14.6233 9.64374 14.3745L9.85195 14.253C11.1793 13.4787 12.8207 13.4787 14.148 14.253V14.253C14.7086 14.58 15.1871 15.0307 15.5471 15.5707L16.5 17"
                stroke="#364F6B"
            />
        </svg>
    )
}

export default PanelMembersIcon;
