import React from "react";
import { IIconProps } from "../../app/types/i-props";

const PinIcon = ({stroke='#828282'}:IIconProps) => {
    return (
        <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.48375 6.5V8.39583C6.48375 9.44125 7.33417 10.2917 8.37958 10.2917C9.425 10.2917 10.2754 9.44125 10.2754 8.39583V5.41667C10.2754 3.32042 8.58 1.625 6.48375 1.625C4.3875 1.625 2.69209 3.32042 2.69209 5.41667V8.66667C2.69209 10.4596 4.14917 11.9167 5.94209 11.9167"
                stroke={stroke}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default PinIcon;