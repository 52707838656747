export const getUrlChunks = () => {
    const documentPathname = document.location.pathname

    const urlChunks = documentPathname.split('/').filter((el) => el !== '') //scheduler/organization/project/tasks/items/templates/slug

    return urlChunks
}

export const goToNewTab = (url:string)=>{
    const link = document.createElement('a') as any;
    link.href = url;
    link.target = '_blank'
    document.body.appendChild(link);

    link.click();
    document.body.removeChild(link);
}
