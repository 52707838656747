import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    IComment,
    ISubTask,
    ITask,
    ITaskFieldsForUpdates,
} from '../../app/types/models/tasks'
import { Comment } from '../../features/tasks/components/Comment/Comment'
import FileList from '../../features/tasks/components/TaskFiles/TasksFiles'
import { useAppDispatch } from '../../hooks/redux-hooks'
import UiTabs from '../../shared/tabs/Tabs'
import { UITextEditor } from '../../shared/text-editor/TextEditor'
import { SubTasks } from '../../features/tasks/components/SubTasks/SubTasks'

const RoadMapUpdateTaskTabs = ({
    type,
    fields,
    onChange,
}: {
    fields: {
        id?: number
        description?: string
        comments?: IComment[]
        files?: any
        slug: string
        project: number
        subtasks?: ISubTask[]
    }
    type?: 'task' | 'panel-task' | 'template'
    projectId: number
    panelPosition?: number
    onChange: (data: ITaskFieldsForUpdates) => void
}) => {
    const { t } = useTranslation()

    const updateDescription = async (description: string) => {
        onChange({ description })
    }

    const addTaskFiles = async (file: any) => {
        onChange({ file: file })
    }
    const deleteTaskFile = async (id: number) => {
        onChange({ deletedFile: id })
    }

    const saveComment = async (comment: string) => {
        onChange({ comment })
    }

    const handleChangeSubtaskData = (data:{
        value: string,
        slug?:string
        type: 'add-subtask' | 'change-subtask-title'
    }) => {
        onChange({ sub_task_data: data })
    }

    const tabsItems = [
        {
            label: t('description'),
            key: '0',
            children: (
                <>
                    <UITextEditor
                        initialText={fields?.description ?? ''}
                        handleChange={updateDescription}
                    />
                </>
            ),
        },
        {
            label: t('comments'),
            key: '1',
            children: (
                <Comment
                    onSave={saveComment}
                    comments={fields.comments || []}
                />
            ),
        },
        {
            label: t('files'),
            key: '2',
            children: (
                <FileList
                    files={fields?.files}
                    handleSave={addTaskFiles}
                    handleDelete={deleteTaskFile}
                />
            ),
        },
        {
            label: type === 'template' ? t('subtemplates') : t('subtasks'),
            key: '3',
            children: (
                <SubTasks
                    task={fields as ITask}
                    is_template={type === 'template'}
                    onChange={handleChangeSubtaskData}
                />
            ),
        },
    ]

    const showValidTabITems = () => {
        if (type === 'task' || type === 'template') {
            return fields?.slug?.split('_').length < 3
                ? tabsItems
                : tabsItems.slice(0, tabsItems.length - 1)
        } else {
            return [tabsItems[0], tabsItems[1], tabsItems[2]]
        }
    }

    return (
        <UiTabs className="roadMap-add-task-tabs" items={showValidTabITems()} />
    )
}

export default RoadMapUpdateTaskTabs
