import { ITasksFilterParams } from '../../app/types/models/tasks'

export const initialFilterData: ITasksFilterParams = {
    ordering: undefined,
    doers__user: undefined,
    name__icontains: undefined,
    priority: undefined,
    status: undefined,
    slug__icontains: undefined,
    tags: undefined,
    supervisor__user: undefined,
}

