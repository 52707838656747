import React from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import AppRoutes from './AppRoutes'
import '../styles/general.css';

function App() {
    return (
        <div className="App">
            <ToastContainer />
            <AppRoutes />
            <div className='app-bottom'></div>
        </div>
    )
}

export default App
