import { Skeleton } from 'antd'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { useAppDispatch } from '../../../../hooks/redux-hooks'
import TaskNameEditBlock from '../../../../shared/taskNameEditBlock/TaskNameEditBlock'
import { UITextEditor } from '../../../../shared/text-editor/TextEditor'
import { UiAccordion } from '../../../../shared/ui/accordion/UiAccordion'
import UiButton from '../../../../shared/ui/button/UiButton'
import { selectStatus } from '../../../../store/app/selectors'
import { selectCurrentProject } from '../../../../store/currentProject/selectors'
import { tasksActions } from '../../../../store/tasks'
import {
    selectTaskData
} from '../../../../store/tasks/selectors'
import { errorsHandler } from '../../../../utils/helpers/errors/errors-hendler'
import { showNotify } from '../../../../utils/helpers/showNotice'
import { TasksTabTypes } from '../../types'
import { Comment } from '../Comment/Comment'
import { SubTasks } from '../SubTasks/SubTasks'
import TaskDetails from '../TaskDetails/TaskDetails'
import FileList from '../TaskFiles/TasksFiles'
import './taskEditSpace.css'

export const TaskEditSpace = ({
    type,
}: {
    type: TasksTabTypes
}) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const currentProject = useSelector(selectCurrentProject)
    const status = useSelector(selectStatus)
    const {results} = useSelector(selectTaskData)
    const location = useLocation()
    const slug = location?.state?.slug

    const task = useMemo(()=>{
        return results.find(el=>el?.slug===slug)
    },[results,location?.state?.slug])

    const fetchComments = async () => {
            dispatch(
                tasksActions.getTaskComments({
                    slug,
                    projectId: currentProject?.id,
                    onError: (e) => {
                        errorsHandler(e, t)
                    },
                }),
            )
    }

    const saveComment = async (text: string) => {
        dispatch(
            tasksActions.saveTaskComments({
                text,
                slug,
                projectId: currentProject?.id,
                onError: (e) => {
                    errorsHandler(e, t)
                },
            }),
        )
    }

    const addTaskFiles = async (file: any) => {
        dispatch(
            tasksActions.addFileToTask({
                file,
                projectId: currentProject?.id,
                slug,
                onSuccess: () => {
                    showNotify(t('file-added-successfully'))
                },
                onError: (e) => errorsHandler(e, t),
            }),
        )
    }

    const deleteTaskFile = async (fileId: number) => {
        dispatch(
            tasksActions.deleteTaskFile({
                fileId,
                slug,
                projectId: currentProject?.id,
                onSuccess: () => showNotify(t('file-deleted-successfully')),
                onError: (e) => errorsHandler(e, t),
            }),
        )
    }

    const updateDescription = (description: string) => {
        if (description !== task?.description) {
            dispatch(
                tasksActions.changeTaskFields({
                    data: { slug, description },
                    task: task,
                    onError: (e) => errorsHandler(e, t),
                }),
            )
        }
    }

    const updateName = (name: string) => {
        if (name !== task?.name) {
            dispatch(
                tasksActions.changeTaskFields({
                    data: { slug, id: task?.id, name },
                    task: task,
                    onError: (e) => errorsHandler(e, t),
                }),
            )
        }
    }

    const createTemplate = () => {
        dispatch(
            tasksActions.createTask({
                projectId: currentProject?.id,
                task: {
                    name: task.name,
                    priority: task.priority,
                    status: task.status?.id,
                    description: task.description,
                    due_date_start: task?.due_date_start,
                    due_date_end: task?.due_date_end,
                },

                onSuccess: () => {
                    showNotify(t('task-added-successfully'))
                    // handleChangePagination({ limit: 20, offset: 0 })
                },
            }),
        )
    }

    useEffect(() => {
        if (
            slug !== undefined &&
            currentProject?.id
        ) {
            fetchComments()
        }
    }, [task?.project, type])

    return (
        <div className={'scheduler__tasksSettings'}>
            <div className="p-10 br-default scheduler__container">
                <TaskNameEditBlock
                    value={task?.name}
                    projectId={currentProject?.id}
                    slug={slug}
                    onChange={updateName}
                />
                {type === TasksTabTypes.TEMPLATES && (
                    <div className="mt-10">
                        <UiButton type="primary" onClick={createTemplate}>
                            + {t('create-task-by-template')}
                        </UiButton>
                    </div>
                )}
                <div className="mt-10">
                    <UiAccordion title={t('details')} activeStatus>
                        <TaskDetails task={task} type={type} />
                    </UiAccordion>
                    <UiAccordion title={t('description')} activeStatus>
                        {status === 'loading' ? (
                            <Skeleton.Input
                                active
                                className="scheduler__skeleton scheduler__skeleton-description"
                            />
                        ) : (
                            <UITextEditor
                                key={task?.id}
                                initialText={task?.description}
                                handleChange={updateDescription}
                            />
                        )}
                    </UiAccordion>
                    {task?.slug && task?.slug?.split('_').length < 3 && (
                        <UiAccordion title={type === TasksTabTypes.TEMPLATES ? t('subtemplates') : t('subtasks')} activeStatus>
                            <SubTasks task={task} is_template={type === TasksTabTypes.TEMPLATES} />
                        </UiAccordion>
                    )}
                    <UiAccordion title={t('pin-files')} activeStatus>
                        <FileList
                            files={task?.files}
                            handleSave={addTaskFiles}
                            handleDelete={deleteTaskFile}
                        />
                    </UiAccordion>

                    <UiAccordion activeStatus={true} title={t('comments')}>
                        <Comment
                            onSave={saveComment}
                            comments={task?.comments || []}
                        />
                    </UiAccordion>
                    {/* {slug && slug.split('_')?.length < 3 && (
                        <SubTasks task={task} is_template={type===TasksTabTypes.TEMPLATES} />
                    )} */}
                </div>
            </div>
        </div>
    )
}
