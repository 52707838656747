import { FC, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EditableSpan } from '../../../../../../shared/EditableSpan/EditableSpan'

import React from 'react'
import { useTranslation } from 'react-i18next'
import SimpleBar from 'simplebar-react'
import 'simplebar-react/dist/simplebar.min.css'
import { ICore } from '../../../../../../app/types/models/logging'
import { projectsService } from '../../../../../../services/projects-service.'
import {
    currentProjectActions,
    currentProjectSelectors,
} from '../../../../../../store/currentProject'
import { setCurrentCore, setCurrentCores } from '../../../../../../store/currentProject/actions'
import { selectCurrentProject } from '../../../../../../store/currentProject/selectors'
import { projectsActions } from '../../../../../../store/projects'
import { errorsHandler } from '../../../../../../utils/helpers/errors/errors-hendler'
import { showNotify } from '../../../../../../utils/helpers/showNotice'
import LoggingProductSceleton from '../logging-product-sceleton/LoggingProductSceleton'
import { ICoresProps } from './types'
import './cores.css'

const Cores: FC<ICoresProps> = ({ openValue, toOpenInput }) => {
    const [coreValue, setCoreValue] = useState('')
    const [filteredCores, setFilteredCores] = useState(null)
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const {  updateCurrentCore } = currentProjectActions
    const { updateWellCore, updateWellBoreCore } = projectsActions
    const currentProject = useSelector(selectCurrentProject)
    const cores = useSelector(currentProjectSelectors.selectCurrentCores)
    const currentPad = useSelector(currentProjectSelectors.selectCurrentPad)
    const currentWell = useSelector(currentProjectSelectors.selectCurrentWell)
    const currentWellBore = useSelector(
        currentProjectSelectors.selectCurrentWellBore,
    )
    const coresLoadindStatus = useSelector(
        currentProjectSelectors.selectLoadingCurrentCoresStatus,
    )
    const currentCore = useSelector(currentProjectSelectors.selectCurrentCore)

    interface IAddCoreBody {
        name:string
        well?:number
        wellbore?:number
    }

    const handleChangeCoreValue = (e) => {
        setCoreValue(e.target.value)
    }
    const handleAddEntities = async (e): Promise<void> => {
        try {
            if (e.keyCode === 13) {
                const data = {name: coreValue} as IAddCoreBody
                if (currentWell?.id) {
                    data.well = Number(currentWell?.id)
                } else {
                    data.wellbore = Number(currentWellBore?.id)
                }

                const res = await projectsService.addCore(
                    currentProject?.id,
                    data,
                )

                dispatch(setCurrentCores({cores:[res,...cores]}))
                dispatch(setCurrentCore(res))
                toOpenInput(false)
                setCoreValue('')
                setFilteredCores([res, ...filteredCores])

                showNotify(t('data-saved-successfully'))
            }
            
        } catch (e) {
            setCoreValue('')
            errorsHandler(e, t)
        }
    }

    const handleUpdateCore = async (name: string) => {
        try {
            const { data } = await projectsService.updateCore(
                currentProject?.id,
                currentCore?.id,
                { name },
            )
            dispatch(
                updateCurrentCore({ id: currentCore?.id, name: data.name }),
            )
            if (data.well) {
                dispatch(
                    updateWellCore({
                        id: currentProject.id,
                        name: data.name,
                        coreId: currentCore?.id,
                        padId: currentPad?.id,
                        wellId: data.well,
                    }),
                )
            } else {
                dispatch(
                    updateWellBoreCore({
                        id: currentProject.id,
                        name: data.name,
                        coreId: currentCore?.id,
                        padId: currentPad?.id,
                        wellBoreId: currentWellBore?.id,
                        wellId: data.well,
                    }),
                )
            }
        } catch (e) {
            errorsHandler(e, t)
        }
    }

    const closeInputOnBlur = () => {
        toOpenInput(false)
    }

    const filterCores = useMemo(() => {
        return Array.isArray(cores)
            ? cores.filter((core) => {
                  if (currentWell?.id) {
                      return Number(core?.well) === currentWell?.id
                  } else if (currentWellBore?.id) {
                      return Number(core?.wellbore) === currentWellBore?.id
                  } else return undefined
              })
            : []
    }, [currentWell, currentWellBore, cores])

    const getListClass = () => {
        if (
            coresLoadindStatus
        ) {
            return 'projects__elements-simpleBarInner projects__elements-coreList projects__elements-coreList-loading'
        }
        return 'projects__elements-simpleBarInner projects__elements-coreList'
    }

    const handleSelectCore = (el: ICore) => {
        dispatch(setCurrentCore(el))
    }

    const showItems = () => {
        if (coresLoadindStatus) {
            return <LoggingProductSceleton />
        } else {
            if (filteredCores) {
                return (
                    <>
                        {filteredCores.map((el) => (
                            <li
                                key={el?.id}
                                className={
                                    el.id === currentCore?.id
                                        ? 'projects__elements-Item activeElement'
                                        : 'projects__elements-Item'
                                }
                                onClick={() => handleSelectCore(el)}
                            >
                                <EditableSpan
                                    value={el?.name}
                                    spanClassName={
                                        'projects__elements-editSpan'
                                    }
                                    inputClassName={
                                        'projects__elements-editInput'
                                    }
                                    handler={handleUpdateCore}
                                />
                            </li>
                        ))}
                    </>
                )
            } else return <></>
        }
    }

    useEffect(() => {
        if (filterCores?.length > 0) {
            setFilteredCores(filterCores)
        } else {
            setFilteredCores([])
            dispatch(setCurrentCore(null))
        }
    }, [currentWell, currentWellBore, currentProject, cores])

    return (
        <div>
            <div className="projects__elements-cores">
                <SimpleBar className="projects__elements-simpleBar coresSimpleBar">
                    <div>
                        <ul className={getListClass()}>
                            {openValue && (
                                <div
                                    className={'projects__elements__inputWrap'}
                                >
                                    <input
                                        type="text"
                                        className="projects__elements-addInput coresInput"
                                        autoFocus
                                        onChange={handleChangeCoreValue}
                                        onKeyDown={handleAddEntities}
                                        onBlur={closeInputOnBlur}
                                    />
                                </div>
                            )}
                            {showItems()}
                        </ul>
                    </div>
                </SimpleBar>
            </div>
        </div>
    )
}
export { Cores }

