import React, { SetStateAction, useCallback } from 'react'
import { ITask } from '../../../../app/types/models/tasks'
import RoadMapItem from '../roadMapItem/RoadMapItem'
import './RoadMapItems.css'

const RoadMapItems = ({
    items,
    saveHeightList,
}: {
    items: ITask[]
    saveHeightList: (
        data: SetStateAction<{ id: number; height: number }[]>,
    ) => void
}) => {
    const handleSaveHeight = useCallback((id: number, height: number) => {
        saveHeightList((prev) =>
            prev.map((val) => (val.id === id ? { ...val, height } : val)),
        )
    }, [saveHeightList])

    const showTasks = () => {
        return items.map((el, i) => (
            <RoadMapItem
                key={el?.id}
                item={el}
                saveBlockHeight={handleSaveHeight}
            />
        ))
    }

    return <ul className="roadmap-items">{showTasks()}</ul>
}

export default RoadMapItems
